import React from "react";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import { Link } from "react-router-dom";
import TextHr from "../../../components/molecules/textHr/TextHr";

function MentionsLegales() {
  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            { name: "Accueil", route: "/" },
            { name: "Liens légaux: Mentions légales", route: "/mentions-legales" },
          ]}
        />
        <Banner title='Mentions légales' subtitle='Ces mentions sont applicables au 15 octobre 2021' />
        <div className='big-text-container'>
          <TextHr className='my-xl'>Editeur du site</TextHr>
          <p>
            Le site Internet accessible à l’adresse{" "}
            <Link className='link' to='/'>
              www.estimermoncommerce.fr
            </Link>{" "}
            est édité par la société GALAXICO, SAS au capital social de 10 000 €, immatriculée au RCS de PARIS sous le
            numéro 878 387 885, ayant son siège social situé 41 rue de Cronstadt 75015 Paris (France), et dont les
            coordonnées sont les suivantes :
          </p>
          <ul>
            <li>
              Pour toutes questions concernant le fonctionnement du site uniquement, si nos{" "}
              <Link className='link' to='faq'>
                FAQ
              </Link>{" "}
              ne vous ont pas apporté la réponse souhaitée, contactez le :{" "}
              <Link className='link' to='tel:+33663845256'>
                06 63 84 52 56
              </Link>
              , du lundi au vendredi, hors jours fériés et congés annuels, de 10h à 12h et de 14h à 17h
            </li>
            <li>
              Ou envoyez-nous un e-mail à l’adresse suivante :{" "}
              <Link className='link' to='mailto:contact@estimermoncommerce.fr'>
                contact@estimermoncommerce.fr
              </Link>
            </li>
          </ul>
          <p>Le numéro de TVA intracommunautaire de la société GALAXICO est : FR72878387885</p>
          <p>Le directeur de la publication du site est M. Villeroy Arnaud.</p>
          <TextHr className='my-xl'>Concepteur du site</TextHr>
          <p>
            Le site Internet{" "}
            <Link className='link' to='/'>
              www.estimermoncommerce.fr
            </Link>{" "}
            a été réalisé par la société KWEERTY, SAS au capital social de 2 000 €, immatriculée au RCS du Puy en Velay
            sous le numéro 878 066 455, ayant son siège social situé 15 route de Saint Germain 43700 Saint Germain
            Laprade (France), et dont les coordonnées sont les suivantes :
          </p>
          <ul>
            <li>
              Téléphone :{" "}
              <Link className='link' to='tel:+33629085881'>
                06 29 08 58 81
              </Link>
              , du lundi au vendredi, hors jours fériés et congés annuels, de 9h à 19h
            </li>
            <li>
              E-mail :{" "}
              <Link className='link' to='mailto:contact@kweerty.com'>
                contact@kweerty.com
              </Link>
            </li>
            <li>
              Web :{" "}
              <Link className='link' to='https://www.kweerty.com' target='_blank'>
                www.kweerty.com
              </Link>
            </li>
          </ul>
          <p>Le numéro de TVA intracommunautaire de la société KWEERTY est : FR59878066455</p>
          <TextHr className='my-xl'>Hébergeur du site</TextHr>
          <p>
            Le site Internet{" "}
            <Link className='link' to='/'>
              www.estimermoncommerce.fr
            </Link>{" "}
            est hébergé par la société OVH, SAS immatriculée au RCS de LILLE METROPOLE sous le numéro 424 761 419, ayant
            son siège social situé 2 rue Kellermann 59100 ROUBAIX
          </p>
          <ul>
            <li>
              Téléphone :{" "}
              <Link className='link' to='tel:1007'>
                1007
              </Link>
            </li>
            <li>
              E-mail :{" "}
              <Link className='link' to='mailto:tech@ovh.net'>
                tech@ovh.net
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default MentionsLegales;
