import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/general/arrowSm.svg";

function ArrowSm({ width = "15px", rotation = "0", color = "var(--dark-blue)", className = "", onClick }) {
  return (
    <Icon
      style={{
        "--color": color,
        "fill": "var(--color)",
        "transform": "rotate(" + rotation + ")",
        "minWidth": width,
      }}
      width={width}
      height={width}
      className={className + (onClick ? " clickable-icon" : "")}
      onClick={onClick}
    />
  );
}

export default ArrowSm;
