import React from "react";
import { ReactComponent as Icon } from "../../../../../../assets/homepage/icons/guideline/cube.svg";

function Cube({ width = "40px", color = "#897661", className }) {
  return (
    <Icon style={{ "--color": color, "fill": "var(--color)" }} width={width} height={width} className={className} />
  );
}

export default Cube;
