import React, { useEffect, useRef, useState } from "react";
import { numberValidation } from "../../../../utils/formValidation/FormValidation.js";
import "./../input/Input.css";
import Search from "../../../atoms/icons/general/search/Search";
import Warning from "../../../atoms/icons/general/warning/Warning";
import {
  formatNumberWithSpaces,
  removeSpaces,
  removeSpacesFromEvent,
  toggleCollapseElement,
  toggleCollapseInput,
} from "../../../../utils/Utils.js";

// useForm = {
//   register: () => {},
//   unregister: () => {},
//   watch: () => {},
//   setValue: () => {},
// }
// icon : euro / percent / search
function NumberInput({
  useForm = {},
  label,
  name,
  defaultValue,
  validation = numberValidation,
  error,
  icon,
  visible = true,
  bgColor,
  onChange = () => {},
  zeroByDefault = true,
  onFocus = () => {},
  disabled,
  onKeyDown = () => {},
  value,
  tip,
  className = "",
}) {
  const { register = () => {}, unregister = () => {}, setValue = () => {}, watch = () => {} } = useForm;
  const containerRef = useRef(null);
  const listener = watch ? watch(name) : null;

  useEffect(() => {
    if (listener) setValue(name, formatNumberWithSpaces(listener));
  }, [listener]);

  useEffect(() => {
    toggleCollapseInput(containerRef.current, visible, 0);

    if (!visible) {
      unregister(name, { keepValue: true });
    }
  }, [visible]);

  function setZero(e) {
    if (e.target.value === "" && setValue && zeroByDefault) setValue(name, 0);

    if (zeroByDefault && (e.target.value === "" || e.target.value === "-")) {
      useForm.setValue(name, 0);
      setTimeout(() => {
        setValue(name, formatNumberWithSpaces(e.target.value));
        onChange(removeSpacesFromEvent(e));
      }, 100);
    }
  }

  function resetValue(e) {
    if (e.target.value === "0" && setValue) setValue(name, "");
  }

  function handleChange(e) {
    const cursorPosition = e.target.value.length - e.target.selectionStart;

    setValue(name, formatNumberWithSpaces(e.target.value));
    onChange(removeSpacesFromEvent(e));

    setTimeout(() => {
      e.target.setSelectionRange(e.target.value.length - cursorPosition, e.target.value.length - cursorPosition);
    }, 0);
  }

  function handleKeyDown(e) {
    // fix backspace when deleting white space between numbers
    const prevChar = e.target.value.at(e.target.selectionStart - 1);

    if (prevChar === " " && e.key === "Backspace") {
      e.preventDefault();
      const cursorPosition = e.target.value.length - e.target.selectionStart;

      setValue &&
        setValue(
          name,
          formatNumberWithSpaces(
            removeSpaces(
              e.target.value.slice(0, e.target.selectionStart - 2, 2) + e.target.value.slice(e.target.selectionStart),
            ),
          ),
        );

      setTimeout(() => {
        e.target.setSelectionRange(e.target.value.length - cursorPosition, e.target.value.length - cursorPosition);
      }, 0);
    }

    onKeyDown(e);
  }

  return (
    <>
      <div className={`w-100 d-flex ${className}`} ref={containerRef}>
        <div style={{ "--bg-color": bgColor }} className={`field-container ${bgColor ? "filled-input" : ""}`}>
          <label className='form-label' htmlFor={name}>
            {label}
          </label>
          <p className={`label-tip ${tip ? "" : " d-none"}`}>{tip}</p>
          <span className={`input-container ${icon ? "before-" + icon : ""} ${error ? " with-error" : ""}`}>
            {icon === "search" && <Search className='before-search-content' />}
            <input
              {...(visible && register(name, validation))}
              disabled={(visible !== undefined && !visible) || disabled}
              className={`before-${icon}`}
              id={name}
              value={value !== undefined ? value : undefined}
              onChange={handleChange}
              onFocus={e => {
                resetValue(e);
                onFocus && onFocus(e);
              }}
              defaultValue={defaultValue}
              onBlur={setZero}
              onKeyDown={handleKeyDown}
            />
            {error && (
              <div className='form-error'>
                <Warning /> <p>{error}</p>
              </div>
            )}
          </span>
        </div>
      </div>
    </>
  );
}

export default NumberInput;
