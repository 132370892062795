import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Like from "../../atoms/icons/general/like/Like";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import LikeButton from "../../molecules/LikeButton/LikeButton";
import Carousel from "../carousel/Carousel";
import Card from "../../molecules/cards/card/Card";
import Notification from "../../organisms/notification/Notification";
import {
  addLikeApi,
  removeLikeApi,
  fetchAllLikes,
  addDislikeApi,
  removeDislikeApi,
  fetchAllDislikes,
} from "../../../api/NewsApi";
import { useAuth } from "../../../context/AuthContext";
import "./NewsList.css";

function NewsList({ filteredNews, visibleCount, setVisibleCount, onOpenModal }) {
  const navigate = useNavigate();
  const [likes, setLikes] = useState({});
  const [dislikes, setDislikes] = useState({});
  const [loadingIds, setLoadingIds] = useState(new Set());
  const [errorMessage, setErrorMessage] = useState("");
  const { canAccess, getUserId } = useAuth();
  const user_id = getUserId();
  const createNotificationFunction = useRef(null);

  useEffect(() => {
    async function fetchUserReactions() {
      if (!user_id) {
        console.log("user_id is not defined");
        return;
      }

      try {
        const [likesResponse, dislikesResponse] = await Promise.all([fetchAllLikes(), fetchAllDislikes()]);

        const likesData = likesResponse.data;
        const dislikesData = dislikesResponse.data;

        const userLikes = {};
        const userDislikes = {};

        likesData.forEach(({ news_id, user_id: reactedUserId }) => {
          if (reactedUserId === user_id) {
            userLikes[news_id] = true;
          }
        });

        dislikesData.forEach(({ news_id, user_id: reactedUserId }) => {
          if (reactedUserId === user_id) {
            userDislikes[news_id] = true;
          }
        });

        setLikes(userLikes);
        setDislikes(userDislikes);
      } catch (error) {
        console.error("Erreur lors de la récupération des réactions de l'utilisateur :", error);
      }
    }

    fetchUserReactions();
  }, [user_id]);

  const toggleLike = async (newsId, isLiked) => {
    if (!user_id) {
      createNotificationFunction.current(
        <>
          Vous devez avoir un compte pour réagir.{" "}
          <a href='/connexion' className='outfit-bold text-underline cursor-pointer'>
            Me connecter
          </a>
        </>,
        "var(--blue)",
        "var(--white)",
        10000,
      );
      return;
    }

    if (loadingIds.has(newsId)) return;

    setLoadingIds(prev => new Set(prev).add(newsId));

    try {
      if (isLiked) {
        await removeLikeApi(newsId);
        setLikes(prev => ({ ...prev, [newsId]: false }));
      } else {
        if (dislikes[newsId]) {
          await removeDislikeApi(newsId);
          setDislikes(prev => ({ ...prev, [newsId]: false }));
        }
        await addLikeApi(newsId);
        setLikes(prev => ({ ...prev, [newsId]: true }));
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du like:", error);
    } finally {
      setLoadingIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(newsId);
        return newSet;
      });
    }
  };

  const toggleDislike = async (newsId, isDisliked) => {
    if (!user_id) {
      createNotificationFunction.current(
        <>
          Vous devez avoir un compte pour réagir.{" "}
          <a href='/connexion' className='outfit-bold text-underline cursor-pointer'>
            Me connecter
          </a>
        </>,
        "var(--blue)",
        "var(--white)",
        10000,
      );
      return;
    }

    if (loadingIds.has(newsId)) return;

    setLoadingIds(prev => new Set(prev).add(newsId));

    try {
      if (isDisliked) {
        await removeDislikeApi(newsId);
        setDislikes(prev => ({ ...prev, [newsId]: false }));
      } else {
        if (likes[newsId]) {
          await removeLikeApi(newsId);
          setLikes(prev => ({ ...prev, [newsId]: false }));
        }
        await addDislikeApi(newsId);
        setDislikes(prev => ({ ...prev, [newsId]: true }));
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du dislike:", error);
    } finally {
      setLoadingIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(newsId);
        return newSet;
      });
    }
  };

  async function toggleAdvice(nouveauteId) {
    if (!user_id) {
      createNotificationFunction.current(
        <>
          Vous devez avoir un compte pour donner votre avis.{" "}
          <a href='/connexion' className='outfit-bold text-underline cursor-pointer'>
            Me connecter
          </a>
        </>,
        "var(--blue)",
        "var(--white)",
        10000,
      );
      return;
    } else {
      onOpenModal(nouveauteId);
    }
  }

  const formatDate = dateString => {
    const months = [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ];

    const [year, month, day] = dateString.split("-");
    return `${parseInt(day)} ${months[parseInt(month) - 1]} ${year}`;
  };

  const handleLoadMore = () => {
    setVisibleCount(prevCount => prevCount + 5);
  };

  return (
    <>
      <Notification createNotificationFunction={createNotificationFunction} />{" "}
      {filteredNews.slice(0, visibleCount).map(nouveaute => {
        const isLiked = !!likes[nouveaute.id];
        const isDisliked = !!dislikes[nouveaute.id];
        return (
          <div key={nouveaute.id} className='nouveaute-item'>
            <div className='tag-container'>
              {nouveaute.news_tag === "add" && (
                <button className='tag-button' style={{ backgroundColor: "var(--red)" }}>
                  Nouveau
                </button>
              )}
              {nouveaute.news_tag === "fix" && (
                <button className='tag-button' style={{ backgroundColor: "var(--sky-blue)" }}>
                  Amélioration
                </button>
              )}
              {nouveaute.news_tag === "update" && (
                <button className='tag-button' style={{ backgroundColor: "var(--green)" }}>
                  Mise à jour
                </button>
              )}
            </div>
            <div className='point'>
              <span className='blue-dot'></span>
              <h2 className='nouveaute-title'>{nouveaute.news_name}</h2>
            </div>
            <div className='nouveaute-header'>
              <p className='nouveaute-date'>{formatDate(nouveaute.news_date)}</p>
              <button className='category-button'>{nouveaute.news_category.news_category}</button>
            </div>
            <Card bgColor='var(--white)' padding='16px' className='nouveaute-card'>
              <div className='like-dislike-buttons'>
                <LikeButton
                  className='like-button'
                  isLiked={isLiked}
                  onClick={() => toggleLike(nouveaute.id, isLiked)}
                  disabled={loadingIds.has(nouveaute.id)}
                  likeImage={<Like color='var(--blue)' fill={true} />}
                  unlikeImage={<Like color='var(--blue)' fill={false} />}
                />
                <LikeButton
                  isLiked={isDisliked}
                  onClick={() => toggleDislike(nouveaute.id, isDisliked)}
                  disabled={loadingIds.has(nouveaute.id)}
                  likeImage={<Like color='var(--red)' fill={true} rotation='180deg' />}
                  unlikeImage={<Like color='var(--red)' fill={false} rotation='180deg' />}
                />
              </div>
              <div className='nouveaute-content'>
                <div className={`nouveaute-description ${nouveaute.news_pictures.length > 0 ? "left" : "center"}`}>
                  <p>{nouveaute.news_description}</p>
                </div>
                {nouveaute.news_pictures.length > 1 ? (
                  <div className='carousel-image'>
                    <Carousel
                      Element={({ data }) => <img src={data.news_picture} alt='News' />}
                      data={nouveaute.news_pictures}
                      title=''
                      color='var(--blue)'
                      displayedItemsArray={{ 0: 1, 800: 1, 1200: 1 }}
                      slideNumber={1}
                      showArrows={true}
                    />
                  </div>
                ) : (
                  nouveaute.news_pictures.length === 1 && (
                    <img src={nouveaute.news_pictures[0].news_picture} className='nouveaute-image' alt='News' />
                  )
                )}
              </div>
              <div className='buttons'>
                <FilledButton
                  bgColor='var(--gold)'
                  hoverColor='var(--gold)'
                  color='var(--white)'
                  onClick={() => toggleAdvice(nouveaute.id)}>
                  Donner un avis
                </FilledButton>
                {nouveaute.news_tuto_link && (
                  <FilledButton
                    className='tuto-button'
                    color='var(--blue)'
                    onClick={() => navigate(`/${nouveaute.news_tuto_link}`)}>
                    Voir le tuto
                  </FilledButton>
                )}
                <FilledButton onClick={() => navigate(nouveaute.news_link)}>Accéder à la page</FilledButton>
                {(canAccess("admin") || canAccess("dev")) && (
                  <FilledButton onClick={() => navigate(`/news/update/${nouveaute.id}`)}>Mettre à jour</FilledButton>
                )}
              </div>
            </Card>
          </div>
        );
      })}
      {visibleCount < filteredNews.length && (
        <div className='centered'>
          <FilledButton
            onClick={() => setVisibleCount(prevCount => prevCount + 5)}
            backgroundColor='var(--blue)'
            color='var(--white)'
            width='100%'>
            Voir plus
          </FilledButton>
        </div>
      )}
    </>
  );
}

export default NewsList;
