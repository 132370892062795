import axiosInstance from "./AxiosConfig";

export const fetchNewsApi = newsId => axiosInstance.get(`/news/${newsId}`);

export const fetchAllNewsApi = () => axiosInstance.get("/news");

export const fetchNewsCommitsApi = () => axiosInstance.get("/news/commits");

export const addNewsApi = news => axiosInstance.post("/news", news);

export const updateNewsApi = (news, newsId) => axiosInstance.put(`/news/${newsId}`, news);

export const deleteNewsApi = newsId => axiosInstance.delete(`/news/${newsId}`);

export const deletePictureNewsApi = pictureId => axiosInstance.delete(`/news/delete-picture/${pictureId}`);

export const addLikeApi = newsId => axiosInstance.post(`/news/like/${newsId}`);

export const removeLikeApi = newsId => axiosInstance.delete(`/news/like/${newsId}`);

export const fetchAllLikes = () => axiosInstance.get("/news/likes");

export const addDislikeApi = newsId => axiosInstance.post(`/news/dislike/${newsId}`);

export const removeDislikeApi = newsId => axiosInstance.delete(`/news/dislike/${newsId}`);

export const fetchAllDislikes = () => axiosInstance.get("/news/dislikes");

export const addProposal = news_proposal => axiosInstance.post("/news/proposal", { news_proposal });

export const addAdvice = (newsId, news_advice) => axiosInstance.post("/news/advice", { news_id: newsId, news_advice });
