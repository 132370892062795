import { useWindowScroll, useWindowSize } from "@uidotdev/usehooks";
import Banner from "../../../components/molecules/banner/Banner";
import PageTitle from "../../../components/molecules/pageTitle/PageTitle";
import FiltersGroup from "../../../components/organisms/filtersGroup/FiltersGroup";
import "./ConseilsPros.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import ThumbnailsGroup from "../../../components/organisms/thumbnailsGroup/ThumbnailsGroup";
import Bracket from "../../../components/atoms/icons/general/bracket/Bracket";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import FilledButton from "../../../components/molecules/buttons/filledButton/FilledButton";
import ThumbnailsRow from "../../../components/organisms/thumbnailsRow/ThumbnailsRow";
import TextHr from "../../../components/molecules/textHr/TextHr";
import { Link, useNavigate } from "react-router-dom";
import StickySearchbar from "../../../components/molecules/stickySearchbar/StickySearchbar";
import { fetchAllConseilsProsApi, fetchAllConseilsProsTagsApi } from "../../../api/ToolsApi";
import AppContext from "../../../context/AppContext";
import { getPrettyText } from "../../../utils/Utils";
import Paginator from "../../../components/molecules/paginator/Paginator";
import { set } from "react-hook-form";

function ConseilsPros() {
  const [articles, setArticles] = useState([]);
  const [paginationData, setPaginationData] = useState({
    articlesPerPage: 20,
    activePage: 0,
    activeTag: "",
    searchbarValue: "",
  });
  const [totalPages, setTotalPages] = useState(0);
  const [showFeaturedArticle, setShowFeaturedArticle] = useState(true);
  const [tags, setTags] = useState([]);
  const [resetSearchValueTrigger, setSearchResetValueTrigger] = useState(0);

  const articlesContainerRef = useRef();
  const { width } = useWindowSize();
  const { setAppLoaderVisible, createNotification } = useContext(AppContext);

  useEffect(() => {
    setAppLoaderVisible(true);
    fetchArticlesCategories();
  }, []);

  useEffect(() => {
    fetchArticles(paginationData);
  }, [paginationData]);

  useEffect(() => {
    scrollToArticles();
  }, [articles]);

  async function fetchArticlesCategories() {
    try {
      const res = (await fetchAllConseilsProsTagsApi()).data;

      setTags(formatTags(res));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des articles</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function fetchArticles(paginationData) {
    try {
      const newShowFeaturedArticle =
        paginationData.activePage === 0 && !paginationData.searchbarValue && !paginationData.activeTag ? 1 : 0;
      const res = (
        await fetchAllConseilsProsApi(
          Math.max(0, paginationData.activePage * paginationData.articlesPerPage - (showFeaturedArticle ? 1 : 0)),
          paginationData.articlesPerPage - newShowFeaturedArticle,
          paginationData.activeTag,
          paginationData.searchbarValue,
        )
      ).data;

      setTotalPages(Math.ceil(res.total / paginationData.articlesPerPage));
      setShowFeaturedArticle(!!newShowFeaturedArticle);

      setArticles(formatArticles(res.conseils_pros));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des articles</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setAppLoaderVisible(false);
    }
  }

  function scrollToArticles() {
    if (window.scrollY > 300 && articlesContainerRef.current)
      setTimeout(() => {
        articlesContainerRef.current.scrollIntoView({ behavior: "smooth" });
      }, 200);
  }

  function updatePagination(activePage) {
    setPaginationData({ ...paginationData, activePage });
  }

  function updateSearch(value) {
    setPaginationData(values => ({ ...values, activePage: 0, searchbarValue: value }));
  }

  function formatTags(tags) {
    return tags.map(tag => ({
      id: tag.id,
      value: tag.name,
      label: tag.name,
    }));
  }

  function updateFilters(tag) {
    setPaginationData({ ...paginationData, activeTag: tag, searchbarValue: "", activePage: 0 });
    setSearchResetValueTrigger(prev => prev + 1);
  }

  function formatArticles(articles) {
    return articles.map(article => ({
      title: getPrettyText(article.title),
      alt: article.header_img_alt,
      tag: article.tag,
      picture: `${process.env.REACT_APP_API_URL}/assets/ConseilsPros/${article.url}/miniature/${article.header_img}`,
      article_url: `/conseils-pros/${article.url}`,
    }));
  }

  return (
    <div className='page-container'>
      <section className='container'>
        <Breadcrumbs
          routes={[
            {
              name: "Accueil",
              to: "/",
            },
            {
              name: "Conseils: Conseils de pros",
              to: "/conseils-pros",
            },
          ]}
        />
        <Banner
          title='Conseils de pros'
          subtitle='Cession, acquisition, optimisation: astuces, pièges, points essentiels'
        />
      </section>
      <StickySearchbar
        showFilterButton
        filterValues={tags}
        onFilterSelect={updateFilters}
        selectedFilter={paginationData.activeTag}
        resetValueTrigger={resetSearchValueTrigger}
        margin='0 auto'
        onChange={updateSearch}
      />
      <section className='container conseils-pros-container' ref={articlesContainerRef}>
        <ThumbnailsGroup data={articles} hasFeaturedArticle={showFeaturedArticle} />
        <Paginator setActivePage={updatePagination} activePage={paginationData.activePage} totalPages={totalPages} />
        <FilledButton to='debut-parcours' className='mid-page-button'>
          Démarrer le parcours d'estimation
        </FilledButton>
      </section>
    </div>
  );
}

export default ConseilsPros;
