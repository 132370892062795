import "./ParcoursFdc.css";
import React, { useEffect, useContext } from "react";
import ParcoursCarousel from "../../organisms/parcoursCarousel/ParcoursCarousel";
import BilanComptable from "../../forms/parcours/fondsCommerce/step4/BilanComptable";
import ImpotsTaxes from "../../forms/parcours/fondsCommerce/step5/ImpotsTaxes";
import ChargesRedevance from "../../forms/parcours/fondsCommerce/step6/ChargesRedevances";
import Retraitements from "../../forms/parcours/fondsCommerce/step7/Retraitements";
import { ParcoursContext } from "../../../context/ParcoursContext";

function SecondaryParcours() {
  const { estimationValues, setInputsNames, step, parcoursType, setStepsSummary, CAInputs } =
    useContext(ParcoursContext);

  useEffect(() => {
    initParcours();
  }, [estimationValues]);

  useEffect(() => {
    setInputsNames(array => {
      array[0] = parcoursType === 2 ? "bilan_comptable.immobilisations_financieres" : "ca." + CAInputs[0]?.input_name;
      return { ...array };
    });
  }, [estimationValues, CAInputs]);

  function initParcours() {
    setInputsNames([
      parcoursType === 2 ? "bilan_comptable.immobilisations_financieres" : "ca." + CAInputs[0]?.label,
      "impots_taxes.total_impots",
      "redevance.redevance",
      "retraitements.salaire_gerant_annuel",
    ]);

    setStepsSummary([
      {
        name: `Les chiffres de votre année ${estimationValues?.infos?.date_bilan ? estimationValues?.infos?.date_bilan.slice(-4) : ""}`,
        steps: [1, 2, 3, 4],
      },
    ]);
  }

  return (
    <>
      <ParcoursCarousel step={step}>
        <BilanComptable secondaryParcours index={1} />
        <ImpotsTaxes secondaryParcours index={2} />
        <ChargesRedevance secondaryParcours index={3} />
        <Retraitements secondaryParcours index={4} />
      </ParcoursCarousel>
    </>
  );
}

export default SecondaryParcours;
