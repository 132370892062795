import "./TooltipModal.css";
import React, { useEffect, forwardRef, useState } from "react";
import Cross from "../../atoms/icons//general/cross/Cross";
import ArrowBig from "../../atoms/icons/general/arrowBig/ArrowBig";

const TooltipModal = forwardRef(function TooltipModal({ tooltip, isVisible, setIsVisible, setTooltip }, ref) {
  const [isBehind, setIsBehind] = useState(!isVisible);
  const [arrowVisible, setArrowVisible] = useState(false);

  useEffect(() => {
    if (isVisible) setIsBehind(false);
    else
      setTimeout(() => {
        setIsBehind(true);
      }, 300);
  }, [isVisible]);

  function closeModal() {
    setTooltip();
    setIsVisible(!isVisible);
  }

  function checkArrowVisibility() {
    if (ref.current && ref.current.offsetHeight + ref.current.scrollTop >= ref.current.scrollHeight)
      setArrowVisible(false);
    else setArrowVisible(true);
  }

  function checkArrowVisibilityOnScroll(e) {
    if (!ref.current) {
      setArrowVisible(false);
      return;
    }

    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight - 10) setArrowVisible(false);
    else setArrowVisible(true);
  }

  function scrollToBottom() {
    if (!ref.current) return;
    ref.current.scrollTo({ top: ref.current.scrollHeight, behavior: "smooth" });
  }

  return (
    <section className={(isVisible ? "" : "modal-hidden ") + (isBehind ? "behind " : "") + "modal-container"}>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal tooltip-modal'>
        <div className='tooltip-container' ref={ref} onScroll={checkArrowVisibilityOnScroll}>
          <div className='tooltip-arrow-container'>
            {arrowVisible && <ArrowBig className='tooltip-arrow' onClick={scrollToBottom} />}
          </div>
          {tooltip && (
            <img
              className='tooltip'
              onLoad={checkArrowVisibility}
              src={`${process.env.REACT_APP_API_URL}/assets/ParcoursTooltips/${tooltip}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${process.env.REACT_APP_API_URL}/assets/Misc/no_image.svg`;
                currentTarget.alt = "Image non disponible";
              }}
              alt='Tooltip'
            />
          )}
        </div>
        <div className='close-tooltip centered' onClick={closeModal}>
          <p>Fermer</p>
          <Cross />
        </div>
      </div>
    </section>
  );
});

export default TooltipModal;
