import React from "react";
import { ReactComponent as Icon } from "../../../../../../assets/homepage/icons/guideline/interactiveFinger.svg";

function InteractiveFinger({ width = "40px", color = "#756568", className }) {
  return (
    <Icon style={{ "--color": color, "fill": "var(--color)" }} width={width} height={width} className={className} />
  );
}

export default InteractiveFinger;
