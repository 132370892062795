import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/general/plus.svg";

function Plus({ width = "25px", color = "var(--dark-blue)", className = "", onClick }) {
  return (
    <Icon
      style={{ "--color": color, "fill": "var(--color)", "cursor": onClick ? "pointer" : "auto" }}
      width={width}
      height={width}
      className={className + (onClick ? " clickable-icon" : "")}
      onClick={onClick}
    />
  );
}

export default Plus;
