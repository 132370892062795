import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import TextArea from "../../molecules/formComponents/textarea/Textarea";
import { addProposal } from "../../../api/NewsApi";
import { useAuth } from "../../../context/AuthContext";
import Notification from "../../organisms/notification/Notification";
import "./NewsProposal.css";

function NewsProposal() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const navigate = useNavigate();
  const { getUserId } = useAuth();
  const user_id = getUserId();
  const createNotificationFunction = useRef(null);

  const onSubmit = async data => {
    if (!user_id) {
      if (createNotificationFunction.current) {
        createNotificationFunction.current(
          <>
            Vous devez avoir un compte pour faire une proposition.{" "}
            <a href='/connexion' className='outfit-bold text-underline cursor-pointer'>
              Me connecter
            </a>
          </>,
          "var(--blue)",
          "var(--white)",
          10000,
        );
      }
      return;
    }

    try {
      await addProposal(data.suggestion);
      console.log("Proposition soumise :", data);
      setFormSuccess("Votre proposition a été soumise avec succès !");
      setFormError(null);
      reset();
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire :", error);
      setFormError("Une erreur est survenue lors de la soumission. Veuillez réessayer plus tard.");
      setFormSuccess(null);
    }
  };

  return (
    <>
      <Notification createNotificationFunction={createNotificationFunction} />
      <section>
        <p className='centered'>
          Vous souhaitez proposer à notre équipe technique d’ajouter, de modifier ou de créer de nouvelles
          fonctionnalités ? C’est ici.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset className='borderless-fieldset full-page-form'>
            <TextArea
              label='Votre suggestion'
              name='suggestion'
              register={register}
              error={errors.suggestion?.message}
              placeholder='Votre suggestion...'
              validation={{ required: true }}
            />
            {formSuccess && <span className='success-message'>{formSuccess}</span>}
            {formError && <span className='error-message'>{formError}</span>}
            <div className='centered mt-sm'>
              <FilledButton type='submit'>Soumettre</FilledButton>
            </div>
          </fieldset>
        </form>
      </section>
    </>
  );
}

export default NewsProposal;
