import axiosInstance from "./AxiosConfig";

export const updateEstimationAPI = async estimation => axiosInstance.post("/estimation", estimation);

export const fetchEstimationApi = async (estimationId, rapportData) =>
  axiosInstance.get(`/estimation/${estimationId}${rapportData ? "?rapport-data" : ""}`);

export const fetchUserEstimationsApi = async (uuid, getSecondaryYears) =>
  axiosInstance.get(`/estimation/user/${uuid}${getSecondaryYears ? "?related" : ""}`, { withCredentials: true });

export const addSecondaryYearApi = async (estimationId, year) =>
  axiosInstance.post(`/estimation/add-year`, { year: year, estimation_id: estimationId });

export const deleteSecondaryYearApi = async secondaryYearId => axiosInstance.delete(`/estimation/${secondaryYearId}`);

export const archiveEstimationApi = async (estimationId, shouldArchive) =>
  axiosInstance.put(`/estimation/archive`, {
    estimation_id: estimationId,
    archive: shouldArchive,
  });

export const duplicateEstimationApi = async (estimationId, enseigne) =>
  axiosInstance.post("/estimation/duplicate", { estimation_id: estimationId, enseigne });

export const fetchEstimationWithSecondaryYearsApi = async estimationId =>
  axiosInstance.get(`/estimation/get-years/${estimationId}`);

export const validateEstimationApi = async estimationId =>
  axiosInstance.put(`/estimation/validate`, { estimation_id: estimationId });

export const updateEstimationImagesApi = async (estimationId, images) =>
  axiosInstance.post(`/estimation/images`, { estimation_id: estimationId, images });

export const deleteEstimationImageApi = async (estimationId, imageName) =>
  axiosInstance.delete(`/estimation/images/${estimationId}?image-name=${imageName}`);

//Generate & Download PDF from API
export const generatePdfApi = async (uuid, estimationId, content, fileName) =>
  axiosInstance.post(`/estimation/generate-pdf`, {
    user_id: uuid,
    estimation_id: estimationId,
    content,
    file_name: fileName,
  });
