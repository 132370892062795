import React, { createContext, useContext, useState } from "react";

const CartContext = createContext();

export function useCart() {
  return useContext(CartContext);
}

export function CartProvider({ children }) {
  const [cart, setCart] = useState([]);

  function addToCart(tarif) {
    setCart(currentCart => [...currentCart, tarif]);
  }

  function removeFromCart(index) {
    setCart(currentCart => currentCart.filter((_, i) => i !== index));
  }

  return <CartContext.Provider value={{ cart, addToCart, removeFromCart }}>{children}</CartContext.Provider>;
}
