import "./HomepageTextImg.css";
import React, { useRef } from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import { useWindowSize } from "@uidotdev/usehooks";

function HomepageTextImg({ text1, text2, img, imgAlt }) {
  const ref = useRef(null);
  const { width } = useWindowSize();
  const { scrollYProgress } = useScroll({ target: ref });
  const yImage = useParallax(scrollYProgress, 300);

  function useParallax(value, distance) {
    const inputRange = [0, 1];
    const outputRange = [-distance, distance];
    const transformedValue = useTransform(value, inputRange, outputRange);
    const spring = useSpring(transformedValue, {
      stiffness: 100,
      damping: 30,
    });
    return spring;
  }

  return (
    <div ref={ref} className='homepage-text-img-container'>
      {width > 1200 ? (
        <>
          <p className='text-orange'>{text1}</p>
          <p>{text2}</p>
          <motion.img src={img} alt={imgAlt} style={{ y: yImage }} />
        </>
      ) : (
        <>
          <p className='text-orange'>{text1}</p>
          <p>{text2}</p>
          <img src={img} alt={imgAlt} />
        </>
      )}
    </div>
  );
}

export default HomepageTextImg;
