import React from "react";
import "./UnfilledButton.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function UnfilledButton({
  children,
  bgColor = "var(--blue)",
  hoverColor = "var(--white)",
  fontSize = "1rem",
  padding = "5px 20px",
  onClick,
  to,
  type = "button",
  className = "",
  target = "_self",
  rel = "",
  borderRadius = "10px",
  download = false,
}) {
  return (
    <>
      {to ? (
        to.includes("#") ? (
          <HashLink
            smooth
            className={"button unfilled-button " + className}
            style={{
              "--color": bgColor,
              "--hover-color": hoverColor,
              "--font-size": fontSize,
              "--padding": padding,
              "--border-radius": borderRadius,
            }}
            onClick={e => {
              onClick && onClick(e);
            }}
            rel={rel}
            to={to}>
            {children}
          </HashLink>
        ) : (
          <Link
            className={"button unfilled-button " + className}
            style={{
              "--color": bgColor,
              "--font-size": fontSize,
              "--hover-color": hoverColor,
              "--padding": padding,
              "--border-radius": borderRadius,
            }}
            onClick={e => {
              onClick && onClick(e);
            }}
            download={download}
            to={to}
            target={target}>
            {children}
          </Link>
        )
      ) : (
        <button
          type={type}
          className={"button unfilled-button " + className}
          style={{
            "--color": bgColor,
            "--font-size": fontSize,
            "--hover-color": hoverColor,
            "--padding": padding,
            "--border-radius": borderRadius,
          }}
          onClick={e => {
            onClick && onClick(e);
          }}>
          {children}
        </button>
      )}
    </>
  );
}

export default UnfilledButton;
