import React from "react";
import { ReactComponent as Icon } from "../../../../../../assets/homepage/icons/guideline/stopwatch.svg";

function Stopwatch({ width = "40px", color = "#311948", className }) {
  return (
    <Icon style={{ "--color": color, "fill": "var(--color)" }} width={width} height={width} className={className} />
  );
}

export default Stopwatch;
