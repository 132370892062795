import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { updateNewsApi, fetchNewsApi, deleteNewsApi, deletePictureNewsApi } from "../../../api/NewsApi";
import Card from "../../molecules/cards/card/Card";
import Bracket from "../../atoms/icons/general/bracket/Bracket";
import Banner from "../../../components/molecules/banner/Banner";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Input from "../../../components/molecules/formComponents/input/Input";
import NumberInput from "../../molecules/formComponents/numberInput/NumberInput";
import TextArea from "../../molecules/formComponents/textarea/Textarea";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import { useAuth } from "../../../context/AuthContext";
import "./NewsUpdateForm.css";

function getFileNameWithoutExtension(fileName) {
  return fileName.split(".").slice(0, -1).join(".");
}

function NewsUpdateForm({ onClose }) {
  const { newsId } = useParams();
  const navigate = useNavigate();
  const { canAccess } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [pictures, setPictures] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [isDeletingPicture, setIsDeletingPicture] = useState(false);

  useEffect(() => {
    if (!canAccess("admin") && !canAccess("dev")) {
      navigate("/news");
    }
  }, [canAccess, navigate]);

  useEffect(() => {
    async function fetchNewsData() {
      try {
        const response = await fetchNewsApi(newsId);
        const data = response.data;
        setValue("news_name", data.news_name || "");
        setValue("news_date", data.news_date || "");
        setValue("news_category", data.news_category?.news_category || "");
        setValue("news_tag", data.news_tag || "");
        setValue("news_description", data.news_description || "");
        setValue("news_link", data.news_link || "");
        setValue("news_tuto_link", data.news_tuto_link || "");
        setValue("total_likes_number", data.total_likes_number || 0);
        setValue("total_dislikes_number", data.total_dislikes_number || 0);

        const updatedPictures = data.news_pictures.map(picture => ({
          id: picture.news_picture_id,
          data: picture.news_picture,
          name: getFileNameWithoutExtension(picture.news_picture),
        }));

        setPictures(updatedPictures || []);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    }

    if (newsId && (canAccess("admin") || canAccess("dev"))) {
      fetchNewsData();
    }
  }, [newsId, setValue, canAccess]);

  async function onSubmit(data) {
    const imagesToSend = pictures
      .map(picture => {
        if (picture.id === null && picture.data.startsWith("data:image/")) {
          return {
            ...picture,
            id: null,
          };
        }
        if (picture.id && picture.data.startsWith("data:image/")) {
          return picture;
        }

        return null;
      })
      .filter(Boolean);

    const payload = {
      ...data,
      news_picture: imagesToSend,
    };

    try {
      await updateNewsApi(payload, newsId);
      setConfirmationMessage("Mise à jour effectuée");
      setTimeout(() => setConfirmationMessage(""), 5000);
    } catch (error) {
      console.error("Erreur lors de la mise à jour des news :", error);
    }
  }

  function handleFileChange(e, pictureId) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPictures(prevPictures =>
          prevPictures.map(picture => {
            if (picture.id === pictureId) {
              return {
                ...picture,
                data: reader.result,
                name: getFileNameWithoutExtension(file.name),
              };
            }

            return picture;
          }),
        );
      };
      reader.readAsDataURL(file);
    }
  }

  function handleAddFiles(e) {
    const files = Array.from(e.target.files);
    const newPictures = [];

    files.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileName = getFileNameWithoutExtension(file.name);
        const isDuplicate = pictures.some(picture => picture.name === fileName);

        if (!isDuplicate) {
          newPictures.push({
            data: reader.result,
            name: fileName,
            id: null,
          });

          if (newPictures.length === files.length) {
            setPictures(prevPictures => [...prevPictures, ...newPictures]);
          }
        }
      };
      reader.readAsDataURL(file);
    });
  }

  async function handleDelete() {
    try {
      await deleteNewsApi(newsId);
      navigate("/news");
    } catch (error) {
      console.error("Erreur lors de la suppression des news :", error);
    }
  }

  async function handleDeletePicture(pictureId) {
    if (!pictureId) {
      console.error("ID de l'image requis pour la suppression");
      return;
    }

    setIsDeletingPicture(true);

    try {
      await deletePictureNewsApi(pictureId);
      setPictures(prevPictures => prevPictures.filter(picture => picture.id !== pictureId));
    } catch (error) {
      console.error("Erreur lors de la suppression de l'image :", error);
    } finally {
      setIsDeletingPicture(false);
    }
  }

  return (
    <>
      <section className='container'>
        <Breadcrumbs
          routes={[
            { to: "/", name: "Accueil" },
            { to: "/news", name: "Nouveautés" },
            { to: `/news/update/${newsId}`, name: "Mettre à jour une nouveauté" },
          ]}
        />
        <Banner title='Admin' />
        <Breadcrumbs
          routes={[
            {
              to: "/news",
              name: "Retour aux nouveautés",
            },
          ]}
        />
        <Card className='full-page-form'>
          <form onSubmit={handleSubmit(onSubmit)} className='update-news-form'>
            <h1 className='title-form centered'>Mise à jour de la nouveauté {newsId}</h1>
            <Input label='Titre' name='news_name' register={register} validation={{ required: false }} />
            <Input label='Date' name='news_date' type='date' register={register} validation={{ required: false }} />
            <Input label='Catégorie' name='news_category' register={register} validation={{ required: false }} />
            <select
              id='news_tag'
              {...register("news_tag")}
              className='styled-dropdown'
              validation={{ required: false }}>
              <option value='add'>Nouveau</option>
              <option value='update'>Mise à jour</option>
              <option value='fix'>Amélioration</option>
            </select>
            <TextArea
              label='Description'
              name='news_description'
              type='textarea'
              register={register}
              validation={{ required: false }}
            />
            <Input label='Lien de la nouveauté' name='news_link' register={register} validation={{ required: false }} />
            <Input label='Lien du tuto' name='news_tuto_link' register={register} validation={{ required: false }} />
            <NumberInput
              label='Total de likes'
              name='total_likes_number'
              register={register}
              validation={{ required: false }}
              useForm={{ register, watch, setValue }}
            />
            <NumberInput
              label='Total de dislikes'
              name='total_dislikes_number'
              register={register}
              validation={{ required: false }}
              useForm={{ register, watch, setValue }}
            />

            <div className='centered'>
              <FilledButton
                type='button'
                color='var(--blue)'
                className='add-button'
                onClick={() => document.getElementById("file-input-add").click()}>
                Ajouter des images
              </FilledButton>
              <input id='file-input-add' type='file' style={{ display: "none" }} multiple onChange={handleAddFiles} />
            </div>

            {pictures.length > 0 && (
              <div className='image-container'>
                <div className='preview-images'>
                  {pictures.map(picture => (
                    <div key={picture.id || picture.name} className='image-preview'>
                      <div className='buttons'>
                        <FilledButton
                          type='button'
                          onClick={() => document.getElementById(`file-input-${picture.id || picture.name}`).click()}>
                          Modifier
                        </FilledButton>
                        <FilledButton
                          color='var(--white)'
                          hoverColor='var(--white)'
                          bgColor='var(--red)'
                          type='button'
                          onClick={() => handleDeletePicture(picture.id)}
                          disabled={isDeletingPicture}>
                          Supprimer
                        </FilledButton>
                      </div>
                      <input
                        id={`file-input-${picture.id || picture.name}`}
                        type='file'
                        style={{ display: "none" }}
                        onChange={e => handleFileChange(e, picture.id)}
                      />
                      <img src={picture.data} alt={`preview-${picture.name}`} className='preview-image' />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {confirmationMessage && <div className='confirmation-message'>{confirmationMessage}</div>}
            <div className='buttons'>
              <FilledButton color='var(--white)' hoverColor='var(--gold)' bgColor='var(--gold)' type='submit'>
                Mettre à jour
              </FilledButton>
              <FilledButton
                color='var(--white)'
                hoverColor='var(--red)'
                bgColor='var(--red)'
                onClick={handleDelete}
                type='button'>
                Supprimer
              </FilledButton>
            </div>
          </form>
        </Card>
      </section>
    </>
  );
}

export default NewsUpdateForm;
