import { useWindowSize } from "@uidotdev/usehooks";
import "./RapportsRow.css";
import React from "react";
import ComponentsRow from "../componentsRow/ComponentsRow";
import Download from "../../atoms/icons/general/download/Download";
import Usine from "../../atoms/icons/general/usine/Usine";
import Commerce from "../../atoms/icons/general/commerce/Commerce";
import Immeuble from "../../atoms/icons/general/immeuble/Immeuble";
import RapportFDCPicture from "../../../assets/reportExamples/exemple_rapport_fdc.webp";
import RapportMCPicture from "../../../assets/reportExamples/exemple_rapport_mc.webp";
import RapportTDSPicture from "../../../assets/reportExamples/exemple_rapport_tds.webp";
import RapportFDC from "../../../assets/reportExamples/exemple_rapport_fdc.pdf";
import RapportMC from "../../../assets/reportExamples/exemple_rapport_mc.pdf";
import RapportTDS from "../../../assets/reportExamples/exemple_rapport_tds.pdf";

function RapportsRow({ className = "" }) {
  const { width } = useWindowSize();

  const TarifExample = ({ Icon, title, img, file }) => {
    return (
      <div>
        <div className='centered'>
          <Icon width='50px' />
        </div>
        <p>{title}</p>
        <div className='centered'>
          <a target='_blank' href={file}>
            <img src={img} className='rapport-example' />
          </a>
          <a
            className='button filled-button default-button-settings'
            style={{ "--padding": "10px 15px" }}
            download={file}
            href={file}>
            <Download width='30px' />
          </a>
        </div>
      </div>
    );
  };

  return (
    <>
      {width && width > 576 ? (
        <div className={"container " + className}>
          <div className='exemples-rapports-container row-500'>
            <div>
              <div className='centered mb-sm'>
                <Immeuble width='50px' />
              </div>
              <p>Murs commerciaux</p>
              <div className='centered'>
                <a target='_blank' href={RapportMC} className={width > 1200 ? "hover-exemples-rapports-immeuble" : ""}>
                  <img src={RapportMCPicture} className='rapport-example' />
                </a>
                <a
                  className='button filled-button default-button-settings'
                  style={{ "--padding": "10px 15px" }}
                  download='exemple_rapport_mc.pdf'
                  href={RapportMC}>
                  <Download width='30px' />
                </a>
              </div>
            </div>
            <div>
              <div className='centered mb-sm'>
                <Commerce width='50px' />
              </div>
              <p>Fonds de commerce</p>
              <div className='centered'>
                <a target='_blank' href={RapportFDC} className={width > 1200 ? "hover-exemples-rapports-commerce" : ""}>
                  <img src={RapportFDCPicture} className='rapport-example' />
                </a>
                <a
                  className='button filled-button default-button-settings'
                  style={{ "--padding": "10px 15px" }}
                  download='exemple_rapport_fdc.pdf'
                  href={RapportFDC}>
                  <Download width='30px' />
                </a>
              </div>
            </div>
            <div>
              <div className=' mb-sm centered'>
                <Usine width='50px' />
              </div>
              <p>Titres de société</p>
              <div className='centered'>
                <a target='_blank' href={RapportTDS} className={width > 1200 ? "hover-exemples-rapports-usine" : ""}>
                  <img src={RapportTDSPicture} className='rapport-example' />
                </a>
                <a
                  className='button filled-button default-button-settings'
                  style={{ "--padding": "10px 15px" }}
                  download='exemple_rapport_tds.pdf'
                  href={RapportTDS}>
                  <Download width='30px' />
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ComponentsRow
          contentType='rapportsExamples'
          className='exemples-rapports-container'
          Component={TarifExample}
          componentsProps={[
            {
              Icon: Immeuble,
              title: "Murs commerciaux",
              img: RapportMCPicture,
              file: RapportMC,
            },
            {
              Icon: Commerce,
              title: "Fonds de commerce",
              img: RapportFDCPicture,
              file: RapportFDC,
            },
            {
              Icon: Usine,
              title: "Titres de société",
              img: RapportTDSPicture,
              file: RapportTDS,
            },
          ]}
        />
      )}
    </>
  );
}

export default RapportsRow;
