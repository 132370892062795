import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/share/twitter.svg";

function Twitter({ width = "20px", color = "var(--white)", className }) {
  return (
    <Icon style={{ "--color": color, "fill": "var(--color)" }} width={width} height={width} className={className} />
  );
}

export default Twitter;
