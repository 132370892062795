import React, { useContext, useEffect, useState } from "react";
import "./FolderEstimations.css";
import { useNavigate } from "react-router-dom";
import ImportEstimationsFolderModal from "../../../../../../../components/forms/modals/account/importEstimationsFolderModal/ImportEstimationsFolderModal";
import AppContext from "../../../../../../../context/AppContext";
import { deleteAllEstimationsFromFolderApi, deleteEstimationFromFolderApi } from "../../../../../../../api/FolderApi";
import { useWindowSize } from "@uidotdev/usehooks";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import Download from "../../../../../../../components/atoms/icons/general/download/Download";
import Bracket from "../../../../../../../components/atoms/icons/general/bracket/Bracket";
import Pen from "../../../../../../../components/atoms/icons/account/pen/Pen";
import Trash from "../../../../../../../components/atoms/icons/general/trash/Trash";
import UnfilledButton from "../../../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import Tuto from "../../../../../../../components/atoms/icons/general/tuto/Tuto";

function FolderEstimations({ folder, updateFolderInState, getUuid, showTooltip = () => {} }) {
  const { setModalContent, setModalVisible, createNotification } = useContext(AppContext);
  const [openedEstimationIndexes, setOpenedEstimationIndexes] = useState({});
  const [localFolder, setLocalFolder] = useState(folder);
  const navigate = useNavigate();
  const { width } = useWindowSize();

  useEffect(() => {
    setLocalFolder(folder);
  }, [folder]);

  function openImportEstimationModal() {
    setModalContent({
      title: "Importer des estimations",
      content: (
        <ImportEstimationsFolderModal
          folderId={folder.folder_id}
          getUuid={getUuid}
          folder={localFolder}
          onEstimationAdded={handleNewEstimations}
        />
      ),
    });
    setModalVisible(true);
  }

  function handleNewEstimations(newEstimations) {
    const updatedFolder = {
      ...localFolder,
      estimations: [...localFolder.estimations, ...newEstimations],
    };

    setLocalFolder(updatedFolder);

    updateFolderInState(updatedFolder);
  }

  function setOpenedEstimationIndex(index, visible = true) {
    setOpenedEstimationIndexes(indexes => {
      return { ...indexes, [index]: visible };
    });
  }

  function handleModifyEstimations(estimationId) {
    navigate(`/synthese/${estimationId}`);
  }

  function handleDeleteAllEstimations() {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir supprimer{" "}
            {localFolder.estimations.length > 1 ? "toutes les estimations" : "l'estimation"} ?<br /> Cette action est
            irréversible et supprimera également les estimations des livrables associés.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteAllEstimations();
              }}>
              Supprimer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteAllEstimations() {
    try {
      await deleteAllEstimationsFromFolderApi(folder.folder_id, true);
      const updatedFolder = { ...localFolder, estimations: [] };
      setLocalFolder(updatedFolder);
      updateFolderInState(updatedFolder);
      createNotification(
        <>Toutes les estimations ont été supprimées avec succès</>,
        "var(--green)",
        "var(--dark-blue)",
      );
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression des estimations</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function handleDeleteSingleEstimation(estimationId) {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir supprimer cette estimation de votre dossier ?<br />
            Cette action est irréversible et supprimera également l'estimation des livrables associés.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteSingleEstimation(estimationId);
              }}>
              Supprimer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteSingleEstimation(estimationId) {
    try {
      await deleteEstimationFromFolderApi(folder.folder_id, estimationId, true);
      const updatedEstimations = localFolder.estimations.filter(est => est.id !== estimationId);
      const updatedFolder = { ...localFolder, estimations: updatedEstimations };
      setLocalFolder(updatedFolder);
      updateFolderInState(updatedFolder);
      createNotification(<>L'estimation a été supprimée avec succès</>, "var(--green)", "var(--dark-blue)");
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression de l'estimation</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <div>
      <div className='tab-content-header'>
        <h2>
          Estimations
          <span className='tuto-icon'>
            <Tuto onClick={() => showTooltip("")} />
          </span>
        </h2>
        <p>Retrouvez ici toutes les estimations liées à votre dossier.</p>
      </div>
      <div className='folder-estimations-buttons'>
        <FilledButton to='/debut-parcours' className='mid-page-button estimations-button'>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer une estimation
        </FilledButton>
        <FilledButton onClick={openImportEstimationModal} className='mid-page-button estimations-button'>
          <Download width={width > 576 ? "30px" : "20px"} /> Importer une estimation
        </FilledButton>
      </div>
      <div className='folder-table-estimations'>
        <div className='folder-table-estimations-header'>
          <p>Nom de l'estimation</p>
          <p>Actions</p>
        </div>
        <div className='folder-table-estimations-body'>
          {localFolder.estimations.length ? (
            localFolder.estimations.map((estimation, index) => (
              <div key={estimation?.id} className='estimation-item'>
                <p>{estimation?.infos?.nom || estimation?.infos?.enseigne}</p>
                <div
                  className='estimation-dropdown'
                  onClick={() => setOpenedEstimationIndex(index, !openedEstimationIndexes[index])}>
                  <Bracket
                    rotation={openedEstimationIndexes[index] ? "0" : "180deg"}
                    width='15px'
                    color='var(--white)'
                    className='dropdown-icon-estimations'
                  />
                  <ul className={`dropdown-menu ${openedEstimationIndexes[index] ? "show" : ""}`}>
                    <li>
                      <button onClick={() => handleModifyEstimations(estimation?.id)}>
                        <Pen width='22px' /> Modifier
                      </button>
                    </li>
                    <li>
                      <button onClick={() => handleDeleteSingleEstimation(estimation?.id)}>
                        <Trash />
                        Supprimer du dossier
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            ))
          ) : (
            <div className='info-block-estimations'>
              <p>Aucune estimation dans ce dossier</p>
            </div>
          )}
        </div>
        <div className='folder-table-estimations-footer mt-sm'>
          {localFolder.estimations.length > 0 && (
            <UnfilledButton bgColor='var(--dark-red)' onClick={handleDeleteAllEstimations}>
              {localFolder.estimations.length > 1 ? "Supprimer toutes les estimations" : "Supprimer l'estimation"}
            </UnfilledButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default FolderEstimations;
