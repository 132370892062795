import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../context/AppContext";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const { setModalVisible } = useContext(AppContext);

  useEffect(() => {
    document.getElementsByTagName("html")[0].style["scroll-behavior"] = "auto";
    setModalVisible(false);
    setTimeout(() => {
      window.scrollTo(0, 0);
      setTimeout(() => {
        document.getElementsByTagName("html")[0].style["scroll-behavior"] = "smooth";
      }, 100);
    }, 50);
  }, [pathname]);

  return null;
}
