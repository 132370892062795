import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/general/trash.svg";

function Trash({ width = "22px", onClick, color = "var(--dark-blue)", className = "" }) {
  return (
    <div>
      <Icon
        width={width}
        height={width}
        stroke={color}
        className={className + (onClick ? " clickable-icon" : "")}
        onClick={onClick}
      />
    </div>
  );
}

export default Trash;
