import axiosInstance from "./AxiosConfig";

export const fetchVisitingCardApi = async visitingCardId => axiosInstance.get(`carte-visite/id/${visitingCardId}`);

export const fetchVisitingCardsByUuidApi = async uuid => axiosInstance.get(`carte-visite/uuid/${uuid}`);

export const fetchDefaultVisitingCardApi = async uuid => axiosInstance.get(`carte-visite/uuid/${uuid}?default`);

export const createVisitingCardApi = async (card_name, card_type = "created_card") =>
  axiosInstance.post(`/carte-visite`, { card_name, card_type });

export const updateVisitingCardApi = async (visitindCardId, values) =>
  axiosInstance.put(`/carte-visite/update/${visitindCardId}`, values);

export const deleteVisitingCardApi = async visitingCardId =>
  axiosInstance.delete(`/carte-visite/delete/${visitingCardId}`);
