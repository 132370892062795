import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/header/fingerSnap.svg";

function FingerSnap({ width = "30px", color = "var(--dark-blue)", className }) {
  return (
    <Icon style={{ "--color": color, "stroke": "var(--color)" }} width={width} height={width} className={className} />
  );
}

export default FingerSnap;
