import React, { useContext, useState, useEffect } from "react";
import "./FolderDeliverables.css";
import Tuto from "../../../../../../../components/atoms/icons/general/tuto/Tuto";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import { useWindowSize } from "@uidotdev/usehooks";
import AppContext from "../../../../../../../context/AppContext";
import CreateLivrableModal from "../../../../../../../components/forms/modals/account/createLivrableModal/CreateLivrableModal";
import {
  deleteAllEstimationsFromDeliverableApi,
  deleteDeliverableApi,
  deleteEstimationFromDeliverableApi,
  duplicateDeliverableApi,
  fetchDeliverablesByFolderIdApi,
} from "../../../../../../../api/FolderApi";
import Card from "../../../../../../../components/molecules/cards/card/Card";
import Hr from "../../../../../../../components/molecules/hr/Hr";
import Bracket from "../../../../../../../components/atoms/icons/general/bracket/Bracket";
import Pen from "../../../../../../../components/atoms/icons/account/pen/Pen";
import Trash from "../../../../../../../components/atoms/icons/general/trash/Trash";
import UnfilledButton from "../../../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import { useNavigate } from "react-router-dom";
import AddEstimationToDeliverableModal from "../../../../../../../components/forms/modals/account/addEstimationToDeliverableModal/AddEstimationToDeliverableModal";
import UpdateDeliverableModal from "../../../../../../../components/forms/modals/account/updateDeliverableModal/UpdateDeliverableModal";

function FolderDeliverables({ folder, updateFolderInState, showTooltip = () => {} }) {
  const { setModalContent, setModalVisible, createNotification } = useContext(AppContext);
  const { width } = useWindowSize();
  const [livrables, setLivrables] = useState([]);
  const [openedEstimationIndexes, setOpenedEstimationIndexes] = useState({});
  const [openedLivrables, setOpenedLivrables] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchLivrables() {
      try {
        const res = (await fetchDeliverablesByFolderIdApi(folder.folder_id)).data;

        const extractedLivrables = res.map(item => ({
          id: item.deliverable.data.deliverable_id,
          name: item.deliverable.data.deliverable_name,
          estimations: [
            ...item.deliverable.estimations.fonds_de_commerce,
            ...item.deliverable.estimations.titres_de_societe,
            ...item.deliverable.estimations.murs_commerciaux,
          ],
        }));
        setLivrables(extractedLivrables || []);
      } catch (error) {
        setLivrables([]);
      }
    }

    if (folder?.folder_id) {
      fetchLivrables();
    }
  }, [folder.folder_id]);

  function openCreateLivrableModal() {
    setModalContent({
      title: "Créer un livrable",
      content: (
        <CreateLivrableModal folder={folder} folderId={folder.folder_id} onLivrableCreated={handleNewLivrable} />
      ),
    });
    setModalVisible(true);
  }

  function handleNewLivrable(newLivrable) {
    const extractedLivrable = {
      id: newLivrable.deliverable.data.deliverable_id,
      name: newLivrable.deliverable.data.deliverable_name,
      estimations: [
        ...newLivrable.deliverable.estimations.fonds_de_commerce,
        ...newLivrable.deliverable.estimations.titres_de_societe,
        ...newLivrable.deliverable.estimations.murs_commerciaux,
      ],
    };

    const updatedLivrables = [...livrables, extractedLivrable];

    setLivrables(updatedLivrables);
    updateFolderInState({ ...folder, livrables: updatedLivrables });
  }

  function setOpenedEstimationIndex(key, visible = true) {
    setOpenedEstimationIndexes(indexes => {
      return { ...indexes, [key]: visible };
    });
  }

  function toggleLivrable(id) {
    setOpenedLivrables(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }

  function handleModifyDeliverable(deliverable) {
    setModalContent({
      title: "Modifier le livrable",
      content: (
        <UpdateDeliverableModal
          deliverable={deliverable}
          onUpdateSuccess={newName => handleDeliverableUpdated(deliverable.id, newName)}
        />
      ),
    });
    setModalVisible(true);
  }

  function handleDeliverableUpdated(deliverableId, newName) {
    const updatedLivrables = livrables.map(livrable => {
      if (livrable.id === deliverableId) {
        return { ...livrable, name: newName };
      }
      return livrable;
    });
    setLivrables(updatedLivrables);
    updateFolderInState({ ...folder, livrables: updatedLivrables });
  }

  function handleModifyEstimation(estimationId) {
    navigate(`/synthese/${estimationId}`);
  }

  function handleAddEstimationToDeliverable(deliverableId) {
    const livrable = livrables.find(livrable => livrable.id === deliverableId);
    setModalContent({
      title: "Ajouter une/des estimation(s) au livrable : " + livrable.name,
      content: (
        <AddEstimationToDeliverableModal
          folder={folder}
          deliverableId={deliverableId}
          onEstimationsAdded={addedEstimations => handleEstimationsAddedToDeliverable(deliverableId, addedEstimations)}
        />
      ),
    });
    setModalVisible(true);
  }

  function handleEstimationsAddedToDeliverable(deliverableId, addedEstimations) {
    const updatedLivrables = livrables.map(livrable => {
      if (livrable.id === deliverableId) {
        return { ...livrable, estimations: [...livrable.estimations, ...addedEstimations] };
      }
      return livrable;
    });
    setLivrables(updatedLivrables);
    updateFolderInState({ ...folder, livrables: updatedLivrables });
  }

  async function handleDeleteEstimation(deliverableId, estimationId) {
    try {
      await deleteEstimationFromDeliverableApi(deliverableId, estimationId);
      const updatedLivrables = livrables.map(livrable => {
        if (livrable.id === deliverableId) {
          const updatedEstimations = livrable.estimations.filter(estimation => estimation.id !== estimationId);
          return { ...livrable, estimations: updatedEstimations };
        }
        return livrable;
      });

      setLivrables(updatedLivrables);
      updateFolderInState({ ...folder, livrables: updatedLivrables });
      createNotification(<>L'estimation a été supprimée avec succès</>, "var(--green)", "var(--dark-blue)");
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression de l'estimation</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function handleDeleteAllEstimationDeliverable(deliverableId) {
    try {
      await deleteAllEstimationsFromDeliverableApi(deliverableId);
      const updatedLivrables = livrables.map(livrable => {
        if (livrable.id === deliverableId) {
          return { ...livrable, estimations: [] };
        }
        return livrable;
      });

      setLivrables(updatedLivrables);
      updateFolderInState({ ...folder, livrables: updatedLivrables });
      createNotification(
        <>Toutes les estimations ont été supprimées avec succès</>,
        "var(--green)",
        "var(--dark-blue)",
      );
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression des estimations</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function handleDeleteLivrable(deliverableId) {
    try {
      await deleteDeliverableApi(deliverableId);
      const updatedLivrables = livrables.filter(livrable => livrable.id !== deliverableId);
      setLivrables(updatedLivrables);
      updateFolderInState({ ...folder, livrables: updatedLivrables });

      createNotification(<>Le livrable a été supprimé avec succès</>, "var(--green)", "var(--dark-blue)");
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression du livrable</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function handleDuplicateLivrable(deliverableId, deliverableName) {
    try {
      const res = await duplicateDeliverableApi(deliverableId, `${deliverableName} (Duplicata)`);
      const duplicatedDeliverable = res.data;

      const updatedLivrables = [
        ...livrables,
        {
          id: duplicatedDeliverable.deliverable.data.deliverable_id,
          name: duplicatedDeliverable.deliverable.data.deliverable_name,
          estimations: [
            ...duplicatedDeliverable.deliverable.estimations.fonds_de_commerce,
            ...duplicatedDeliverable.deliverable.estimations.titres_de_societe,
            ...duplicatedDeliverable.deliverable.estimations.murs_commerciaux,
          ],
        },
      ];
      setLivrables(updatedLivrables);
      updateFolderInState({ ...folder, livrables: updatedLivrables });
    } catch (error) {
      console.error("Error duplicating deliverable:", error);
    }
  }

  return (
    <div>
      <div className='tab-content-header'>
        <h2>
          Livrables
          <span className='tuto-icon'>
            <Tuto onClick={() => showTooltip("")} />
          </span>
        </h2>
        <p>
          Constituez votre ou vos livrables pour ce dossier. Vous pouvez télécharger un livrable seul ou tout le
          dossier.
        </p>
      </div>
      <div className='folder-deliverable-buttons'>
        <FilledButton onClick={openCreateLivrableModal} className='mid-page-button estimations-button'>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer un livrable
        </FilledButton>
      </div>
      <div className='deliverables-grid mt-md'>
        {livrables?.length > 0 ? (
          livrables.map(livrable => (
            <Card key={livrable?.id} className='deliverable-item'>
              <div className='deliverable-item-head'>
                <div className='deliverable-name'>
                  <h3>{livrable.name}</h3>
                  <Bracket
                    rotation={openedLivrables[livrable?.id] ? "180deg" : "90deg"}
                    width='20px'
                    color='var(--dark-blue)'
                    className='bracket-toggle ml-sm mt-xs'
                    onClick={() => toggleLivrable(livrable?.id)}
                  />
                </div>
                <div className='deliverable-actions-buttons'>
                  <FilledButton
                    bgColor='#E6CC83'
                    hoverColor='#E6CC83'
                    onClick={() => handleModifyDeliverable(livrable)}>
                    Modifier
                  </FilledButton>
                  <FilledButton bgColor='#B8C7FF' hoverColor='#B8C7FF'>
                    Télécharger
                  </FilledButton>
                  <FilledButton
                    bgColor='#E3E3E3'
                    hoverColor='#E3E3E3'
                    onClick={() => handleDuplicateLivrable(livrable?.id, livrable?.name)}>
                    Dupliquer
                  </FilledButton>
                  <FilledButton
                    bgColor='#FDB7A9'
                    hoverColor='#FDB7A9'
                    onClick={() => handleDeleteLivrable(livrable?.id)}>
                    Supprimer
                  </FilledButton>
                </div>
              </div>
              {openedLivrables[livrable?.id] && (
                <>
                  <div className='w-100'>
                    <Hr margin='20px 0' />
                  </div>
                  <div className='deliverable-content-container'>
                    <div className='deliverable-estimations-container'>
                      <h4 className='deliverable-content-header'>Estimation(s) du livrable :</h4>
                      <ul>
                        {Array.isArray(livrable?.estimations) && livrable.estimations.length > 0 ? (
                          livrable.estimations.map(estimation => {
                            const estimationKey = `${livrable.id}-${estimation.id}`;
                            return (
                              <div key={estimationKey} className='estimation-item'>
                                <p>{estimation?.infos?.nom || estimation?.infos?.enseigne}</p>
                                <div
                                  className='estimation-dropdown'
                                  onClick={() =>
                                    setOpenedEstimationIndex(estimationKey, !openedEstimationIndexes[estimationKey])
                                  }>
                                  <Bracket
                                    rotation={openedEstimationIndexes[estimationKey] ? "0" : "180deg"}
                                    width='15px'
                                    color='var(--white)'
                                    className='dropdown-icon-estimations'
                                  />
                                  <ul
                                    className={`dropdown-menu ${openedEstimationIndexes[estimationKey] ? "show" : ""}`}>
                                    <li>
                                      <button onClick={() => handleModifyEstimation(estimation?.id)}>
                                        <Pen width='22px' /> Modifier
                                      </button>
                                    </li>
                                    <li>
                                      <button onClick={() => handleDeleteEstimation(livrable?.id, estimation?.id)}>
                                        <Trash />
                                        Supprimer
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <li>Aucune estimation associée</li>
                        )}
                      </ul>
                      <div className='deliverable-estimations-actions-button'>
                        <UnfilledButton
                          className='add-estimation-button'
                          onClick={() => handleAddEstimationToDeliverable(livrable?.id)}>
                          Ajouter une estimation
                        </UnfilledButton>
                        {livrable.estimations.length > 0 && (
                          <UnfilledButton
                            className='add-estimation-button'
                            bgColor='var(--dark-red)'
                            onClick={() => handleDeleteAllEstimationDeliverable(livrable?.id)}>
                            {livrable.estimations.length > 1
                              ? "Supprimer toutes les estimations"
                              : "Supprimer l'estimation"}
                          </UnfilledButton>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Card>
          ))
        ) : (
          <div className='info-block-deliverable'>
            <p>Aucun livrable dans ce dossier</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default FolderDeliverables;
