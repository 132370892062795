import Banner from "../../../components/molecules/banner/Banner";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import FiltersGroup from "../../../components/organisms/filtersGroup/FiltersGroup";
import React, { useEffect, useRef, useState } from "react";
import AccountEstimations from "./tabs/accountEstimations/AccountEstimations";
import AccountSubscriptionBills from "./tabs/accountSubscriptionBills/AccountSubscriptionBills";
import AccountProfile from "./tabs/accountProfile/AccountProfile";
import AccountFormations from "./tabs/accountFormations/AccountFormations";
import AccountTools from "./tabs/accountTools/AccountTools";
import AccountFolders from "./tabs/accountFolders/AccountFolders";
import { useNavigate, useParams } from "react-router-dom";

function MonCompte() {
  const [currentTab, setCurrentTab] = useState(0);
  const [pageTitle, setPageTitle] = useState("");
  const [activeTabIndexChanger, setActiveTabIndexChanger] = useState(undefined);
  const navbarRef = useRef();
  const navigate = useNavigate();
  const { tabParam } = useParams();

  useEffect(() => {
    let tab = 0;
    switch (tabParam) {
      case "mes-dossiers":
        tab = 0;
        setPageTitle("Mes dossiers");
        break;
      case "mes-estimations":
        tab = 1;
        setPageTitle("Mes estimations");
        break;
      case "mes-outils":
        tab = 2;
        setPageTitle("Mes outils");
        break;
      case "mes-formations":
        tab = 3;
        setPageTitle("Mes formations");
        break;
      case "mon-profil":
        tab = 4;
        setPageTitle("Mon profil");
        break;
      case "mes-abonnements-factures":
        tab = 5;
        setPageTitle("Mes abonnements & factures");
        break;
    }

    setCurrentTab(tab);
    setActiveTabIndexChanger(tab);
  }, [tabParam]);

  function changeTab(tabId, url) {
    setCurrentTab(tabId);
    navigate(`/mon-compte/${url}`);
  }

  return (
    <div className='page-container'>
      <section className='container'>
        <Breadcrumbs
          routes={[
            { name: "Accueil", to: "/" },
            { name: pageTitle, to: `/mon-compte/${tabParam}` },
          ]}
        />
        <Banner title={pageTitle} subtitle='Rapports, factures, offres... Retrouvez toutes vos informations' />
      </section>
      <div className='sticky-container' ref={navbarRef}>
        <FiltersGroup
          changeActiveTabIndex={activeTabIndexChanger}
          data={[
            {
              name: "Mes dossiers",
              onClick: () => changeTab(0, "mes-dossiers"),
              params: [0],
            },
            {
              name: "Mes estimations",
              onClick: () => changeTab(1, "mes-estimations"),
              params: [1],
            },
            {
              name: "Mes outils",
              onClick: () => changeTab(2, "mes-outils"),
              params: [2],
            },
            {
              name: "Mes formations",
              onClick: () => changeTab(3, "mes-formations"),
              params: [3],
            },
            {
              name: "Mon profil",
              onClick: () => changeTab(4, "mon-profil"),
              params: [4],
            },
            {
              name: "Mes abonnements & factures",
              onClick: () => changeTab(5, "mes-abonnements-factures"),
              params: [5],
            },
          ]}
        />
      </div>
      {currentTab === 0 && <AccountFolders />}
      {currentTab === 1 && <AccountEstimations />}
      {currentTab === 2 && <AccountTools />}
      {currentTab === 3 && <AccountFormations />}
      {currentTab === 4 && <AccountProfile navbarRef={navbarRef} />}
      {currentTab === 5 && <AccountSubscriptionBills />}
    </div>
  );
}

export default MonCompte;
