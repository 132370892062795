import axiosInstance from "./AxiosConfig";

//FAQ
export const fetchFaqQuestionsApi = async () => axiosInstance.get("/outils-faq/content");

//Lexique commercial
export const fetchLexiqueCommercialApi = async () => axiosInstance.get("/lexique-commercial/content");

//Outils pratiques
export const fetchAllOutilsPratiquesApi = async () => axiosInstance.get("/outils-pratiques/content");

export const fetchOutilPratiqueByUrlApi = async url =>
  axiosInstance.get("/outils-pratiques/content?article_url=" + url);

// Conseils de pros
export const fetchAllConseilsProsApi = async (offset = "0", limit = "30", tag, title, isPublishedOnly = true) =>
  axiosInstance.get(
    `/conseils-pro?limit=${limit}&offset=${offset}${title ? "&title=" + title : ""}${tag ? "&tag=" + tag : ""}${isPublishedOnly ? "&is-published=" + isPublishedOnly : ""}`,
  );

export const fetchConseilProByUrlApi = async url => axiosInstance.get("/conseils-pro/details?article-url=" + url);

export const fetchAllConseilsProsTagsApi = async () => axiosInstance.get("/conseils-pro/categories");

// Guides pour agents immos
export const fetchAllGuideAgentsImmosApi = async () => axiosInstance.get("/guides-agents-immo");

export const fetchGuideAgentsImmosByUrlApi = async url =>
  axiosInstance.get("/guides-agents-immo?newsletter_url=" + url);

// Liste des activités
export const fetchAllActivitiesApi = async () => axiosInstance.get("/liste-activite");

// Ratios de cotisations tns / salarié
export const fetchCotisationsTnsRatiosApi = async () => axiosInstance.get("/calcul-cotisation/calctns");
export const fetchCotisationSalarieRatiosApi = async () => axiosInstance.get("/calcul-cotisation/calcsalarie");

export const fetchDownloadableFileApi = async url =>
  axios.get(url, {
    responseType: "blob",
  });
