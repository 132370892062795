import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/method/icons/dottedPath.svg";

function DottedPath({ height = "80px", color = "var(--dark-blue)", className, rotation = "0deg" }) {
  return (
    <Icon
      style={{ "--color": color, "stroke": "var(--color)", "transform": "rotateX(" + rotation + ")" }}
      height={height}
      className={className}
    />
  );
}

export default DottedPath;
