import html2canvas from "html2canvas";
import { Fragment } from "react";
import Check from "../components/atoms/icons/general/check/Check";

export function toggleCollapseElement(element, visible, bonusHeight = 0) {
  if (!visible) {
    element.style.overflowY = "hidden";
    setTimeout(() => {
      element.style.overflowY = "hidden";
    }, 400);
    element.style.maxHeight = 0;
  } else {
    setTimeout(() => {
      element.style.overflowY = "unset";
    }, 400);
    element.style.maxHeight = element.scrollHeight + bonusHeight + "px";
  }
}

export function toggleCollapseInput(element, visible, bonusHeight = 0) {
  if (!visible) {
    element.style.overflowY = "hidden";
    element.style.maxHeight = 0;
  } else {
    let clone = element.cloneNode(true);
    clone.style.maxHeight = "none";
    clone.style.maxWidth = element.getBoundingClientRect().width + "px";
    clone.style.position = "absolute";
    clone.style.top = "-9999px";
    clone.style.left = "-9999px";
    document.body.appendChild(clone);
    element.style.maxHeight = clone.offsetHeight + bonusHeight + "px";
    document.body.removeChild(clone);
  }
}

export function getScrollbarWidth() {
  const test = document.createElement("div");
  test.style.visibility = "hidden";
  test.style.width = "100vw";
  document.body.appendChild(test);

  const result = test.offsetWidth - document.body.offsetWidth;

  test.parentNode.removeChild(test);

  return result;
}

export function toInt(str) {
  return str ? Number(str) : 0;
}

export function formatNumberWithSpaces(str, allowZero = false) {
  if (str == 0 && allowZero) return "0";
  if (!str) return "";

  str = str + "";
  str = str.replace(/\s/g, "");
  str = str.replace(/[^0-9.,-]/g, "");
  str = str.replace(/\./g, ",");
  str = str[0] + str.slice(1).replace(/-/g, "");
  if (str === "undefined") return "";

  const parts = str.split(",");
  let integerPart = parts[0] ? parts[0] : "";
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const decimalPart = parts.length > 1 ? "," + parts[1] : "";
  if (integerPart === "" && decimalPart) integerPart = "0";
  while (integerPart[0] === "0" && integerPart[1] !== "," && integerPart.length > 1) integerPart = integerPart.slice(1);

  return integerPart + decimalPart;
}

export function removeSpaces(str, allowNull = false) {
  if (str === undefined || str === null) return allowNull ? null : "";
  str = str + "";
  return Number(str.replace(/\s/g, "").replace(",", "."));
}

export function getSelectValue(value) {
  return value === "_null" ? null : value;
}

export function removeSpacesFromEvent(e) {
  if (!e.target) return "";
  e.target.value = e.target.value.replace(/\s/g, "").replace(",", ".");
  return e;
}

export function getRandomString(length) {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

// return file as { name: String, data: base64 } without compression
export function getImageInfos(event, files) {
  if (!files) files = event.target.files;
  if (!files.length) return { data: null };

  return new Promise((resolve, reject) => {
    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const lastDotIndex = file.name.lastIndexOf(".");

      resolve({
        name: lastDotIndex !== -1 && lastDotIndex !== 0 ? file.name.substring(0, lastDotIndex) : file.name,
        data: reader.result,
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      reject(new Error("No file selected"));
    }
  });
}

// compress image with html2canvas then return as { name: String, data: base64 }
export async function getCompressedImage(file) {
  const img = document.createElement("img");
  img.setAttribute("src", URL.createObjectURL(file));
  img.style.maxWidth = "1000px";
  img.style.maxHeight = "1000px";
  img.style.objectFit = "contain";
  const div = document.createElement("div");
  div.style.position = "absolute";

  div.appendChild(img);
  document.getElementsByTagName("body")[0].appendChild(div);

  const canvas = await html2canvas(img);
  document.getElementsByTagName("body")[0].removeChild(div);

  const lastDotIndex = file.name.lastIndexOf(".");

  return {
    name: lastDotIndex !== -1 && lastDotIndex !== 0 ? file.name.substring(0, lastDotIndex) : file.name,
    data: canvas.toDataURL("image/jpeg"),
  };
}

export async function checkIfSvgIsValid(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = e => {
      const svgContent = e.target.result;
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgContent, "image/svg+xml");
      const svgElement = doc.querySelector("svg");

      const width = svgElement.getAttribute("width");
      const height = svgElement.getAttribute("height");
      const viewBox = svgElement.getAttribute("viewBox")?.split(" ");

      if (!width && viewBox[2]) svgElement.setAttribute("width", viewBox[2]);
      if (!height && viewBox[3]) svgElement.setAttribute("height", viewBox[3]);

      const serializer = new XMLSerializer();
      const updatedSvgContent = serializer.serializeToString(doc);

      resolve(updatedSvgContent);
    };

    reader.onerror = error => {
      reject("Erreur de lecture du fichier : " + error);
    };

    reader.readAsText(file);
  });
}

export function checkImage(file, maxSize = 10) {
  if (!file) return;

  let fileSize = file.size / 1048576;
  let errorMsg;

  if (
    file.type !== "image/jpeg" &&
    file.type !== "image/png" &&
    file.type !== "image/jpg" &&
    file.type !== "image/svg" &&
    file.type !== "image/svg+xml" &&
    file.type !== "image/webp"
  )
    errorMsg = "Mauvais format d'image";
  else if (fileSize > maxSize) errorMsg = "Image trop lourde (" + fileSize.toFixed(2) + "/" + maxSize + " Mb)";

  return errorMsg;
}

export function isStickyElementPinned(elementRef, topTreshold) {
  if (elementRef.current) {
    const rect = elementRef.current.getBoundingClientRect();
    if (rect.top <= topTreshold) return true;
    else return false;
  }
  return false;
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getPrettyText(text) {
  return text?.replaceAll("&nbsp;", "\u00A0");
}

export function isFormattedDate(string) {
  return string.length === 10 && string.split("/").length === 3;
}
export function isDate(string) {
  return string.length === 10 && string.split("-").length === 3;
}

export function getFormattedDate(date) {
  if (!date) return "";
  date = date.split("-");

  return date[2] + "/" + date[1] + "/" + date[0];
}

export function formatDate(date) {
  if (!date) return "";
  date = date.split("/");

  return date[2] + "-" + date[1] + "-" + date[0];
}

export function getObfuscedEmail(email) {
  if (!email) return "";

  const [username, domain] = email.split("@");
  const obfuscatedUsername = username.substring(0, 3) + "*".repeat(username.length - 3);
  return obfuscatedUsername + "@" + domain;
}

export function DateToString(date) {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function getPasswordErrorMessage(password) {
  if (password === undefined) return;

  const passwordErrorMessage = [
    { content: "Votre mot de passe doit contenir au moins :", visible: true },
    { content: "• 8 caractères", visible: true },
    { content: "• 1 majuscule", visible: true },
    { content: "• 1 minuscule", visible: true },
    { content: "• 1 chiffre", visible: true },
    { content: '• 1 caractère spécial (@, !, ", %)', visible: true },
  ];

  passwordErrorMessage[1].visible = password.length < 8;
  passwordErrorMessage[2].visible = password.toLowerCase() === password || !password.length;
  passwordErrorMessage[3].visible = password.toUpperCase() === password || !password.length;
  passwordErrorMessage[4].visible = !/.*[0-9].*/.test(password);
  passwordErrorMessage[5].visible = !/.*[^\w\s].*/.test(password);

  return (
    <div className='password-tooltip-content'>
      {passwordErrorMessage.map((row, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && <br />}
            <>
              {row.content}
              {!row.visible && index > 0 && <Check color='green' />}{" "}
            </>
          </Fragment>
        );
      })}
    </div>
  );
}

// take an object and return it with all formatted numbers as true numbers
export function formatObjectForPosting(object) {
  const tmp = {};

  for (const [key, value] of Object.entries(object)) {
    if (typeof value === "object" && value !== null) {
      tmp[key] = formatObjectForPosting(value);
    } else {
      tmp[key] = parseFormattedValue(value); // Le 2e argument doit être un nouvel objet vide
    }
  }

  return tmp;
}

export function getTotalFromValues(values) {
  return formatNumberWithSpaces(
    values.reduce((a, b) => removeSpaces(a, true) + removeSpaces(b, true), 0),
    true,
  );
}

export function normalizeString(str) {
  return (
    str
      ?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase() ?? ""
  );
}

export function formatTextWithNbsp(text) {
  return text?.replaceAll(/[\s\u00A0](?=[\?:!])/g, "&nbsp;") ?? "";
}

export function uploadImage(div, token, route, extraData, callback, imageName) {
  try {
    html2canvas(div).then(e => {
      axios
        .post(
          process.env.REACT_APP_API_URL + route,
          {
            image: e.toDataURL("image/jpeg", 0.9),
            image_name: imageName + "_" + getRandomString(10) + ".jpeg",
            ...extraData,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(e => {
          callback();
        });
    });
  } catch (e) {}
}

export function formatEuroAmount(amount, isPercentage = false) {
  if (amount === null || amount === undefined) return "-";
  let result = String(amount).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  result += isPercentage ? " %" : " €";

  return result;
}

// takes a string as param and return it formatted for backend (remove spaces between numbers, replace _null by null in selects, format dates)
export function parseFormattedValue(string) {
  if (typeof string === "number") return string;

  if (string === "_null" || !string) return null;

  if (isDate(string)) return getFormattedDate(string);

  const formattedNumberPattern = /^-?\d{1,3}(?:[\s]?\d{3})*(?:[.,]\d+)?$/;

  if (formattedNumberPattern.test(string)) return parseFloat(string.replace(/[\s]/g, "").replace(",", "."));

  return string;
}

// take an object and return it with all values as null
export function resetObjectValues(object) {
  const tmp = {};

  for (const [key, value] of Object.entries(object)) {
    if (typeof value === "object" && value !== null) tmp[key] = resetObjectValues(value);
    else tmp[key] = null;
  }

  return tmp;
}
