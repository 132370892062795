import React from "react";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import "./CookiesSetup.css";
import CookiesSetupForm from "../../../components/forms/cookies/cookiesSetupForm/CookiesSetupForm";

function CookiesSetup() {
  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            {
              name: "Accueil",
              to: "/",
            },
            {
              name: "Cookies: Gérer les cookies",
              to: "/parametrage-cookies",
            },
          ]}
        />
        <Banner
          title='Gestion de vos préférences sur les cookies 🍪'
          subtitle={"Les cookies permettent d'améliorer votre expérience de navigation"}
        />
        <CookiesSetupForm />
      </section>
    </>
  );
}

export default CookiesSetup;
