import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import EmcLogo from "../../atoms/logos/emcLogo/EmcLogo";
import Calculator from "../../atoms/icons/header/calculator/Calculator";
import Chart from "../../atoms/icons/header/chart/Chart";
import Rapport from "../../atoms/icons/header/rapport/Rapport";
import FingerSnap from "../../atoms/icons/header/fingerSnap/FingerSnap";
import Gear from "../../atoms/icons/header/gear/Gear";
import LightBulb from "../../atoms/icons/header/lightBulb/LightBulb";
import Alphabet from "../../atoms/icons/header/alphabet/Alphabet";
import Megaphone from "../../atoms/icons/header/megaphone/Megaphone";
import InterrogationMark from "../../atoms/icons/header/interrogationMark/InterrogationMark";
import Shop from "../../atoms/icons/header/shop/Shop";
import Advice from "../../atoms/icons/header/advice/Advice";
import Factory from "../../atoms/icons/header/factory/Factory";
import Building from "../../atoms/icons/header/building/Building";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../molecules/buttons/unfilledButton/UnfilledButton";
import Cart from "../../atoms/icons/header/cart/Cart";
import ArrowSm from "../../atoms/icons/general/arrowSm/ArrowSm";
import { toggleCollapseElement } from "../../../utils/Utils";
import Bracket from "../../atoms/icons/general/bracket/Bracket";
import { useWindowSize } from "@uidotdev/usehooks";
import RoundedDiv from "../../atoms/icons/general/roundedDiv/RoundedDiv";
import Directions from "../../atoms/icons/header/directions/Directions";
import Emc from "../../atoms/icons/header/emc/Emc";
import { useCart } from "../../../context/CartContext";
import User from "../../atoms/icons/general/user/User";
import { logoutUserApi } from "../../../api/AccountApi";
import AppContext from "../../../context/AppContext";
import { useAuth } from "../../../context/AuthContext";
import DemoForm from "../../forms/account/demoForm/DemoForm";

function Header({ className = "" }) {
  const [openedIndexes, _setOpenedIndexes] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });
  const [openedAccountNav, setOpenedAccountNav] = useState(false);
  const [alreadyOpenedIndexes, setAlreadyOpenedIndexes] = useState(openedIndexes);
  const [headerOpened, setHeaderOpened] = useState(false);
  const [mobileOpenedIndex, setMobileOpenedIndex] = useState(-1);
  const [userInitials, setUserInitials] = useState("");
  const navLinksRef = useRef(null);
  const accountNavRef = useRef(null);
  const accountNavContainerRef = useRef(null);

  const { createNotification, setModalVisible, setModalContent } = useContext(AppContext);
  const { getUuid, userData, canAccess, setUuid, accesses } = useAuth();
  const { width } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();

  const { cart } = useCart();
  const isCartEmpty = !cart || Object.keys(cart).length === 0;

  const [hasNewNews, setHasNewNews] = useState(false);

  useEffect(() => {
    if (width > 1200 && navLinksRef.current) {
      for (const key of Object.keys(openedIndexes)) {
        if (openedIndexes[key] !== alreadyOpenedIndexes[key]) {
          toggleCollapseElement(navLinksRef.current.children[key].children[1], openedIndexes[key]);
          navLinksRef.current.children[key].children[1].classList.toggle("opened-header-section", openedIndexes[key]);
        }
      }
      setAlreadyOpenedIndexes(openedIndexes);
    }
  }, [openedIndexes]);

  useEffect(() => {
    toggleCollapseElement(accountNavRef.current, openedAccountNav);
    accountNavRef.current.classList.toggle("opened-header-section", openedAccountNav);
    toggleMobileMenu(mobileOpenedIndex);
  }, [openedAccountNav]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = headerOpened ? "hidden" : "auto";
  }, [headerOpened]);

  useEffect(() => {
    function closeHeaderDropdown(event) {
      if (accountNavContainerRef.current && !accountNavContainerRef.current.contains(event.target)) {
        setOpenedAccountNav(false);
      }
    }

    document.addEventListener("mousedown", closeHeaderDropdown);
    return () => {
      document.removeEventListener("mousedown", closeHeaderDropdown);
    };
  }, [mobileOpenedIndex]);

  useEffect(() => {
    setHeaderOpened(false);
    toggleMobileMenu(mobileOpenedIndex);
  }, [location]);

  useEffect(() => {
    getUserInitials(userData);
  }, [location, userData]);

  useEffect(() => {
    const visitedNews = localStorage.getItem("visitedNews");
    if (!visitedNews) {
      setHasNewNews(true);
    }
  }, []);

  function toggleMobileMenu(index) {
    if (width <= 1200 && navLinksRef.current && index > -1) {
      switch (mobileOpenedIndex) {
        case index:
          toggleCollapseElement(navLinksRef.current.children[mobileOpenedIndex].children[1], false);
          setMobileOpenedIndex(-1);
          break;
        case -1:
          toggleCollapseElement(navLinksRef.current.children[index].children[1], true);
          setMobileOpenedIndex(index);
          break;
        default:
          toggleCollapseElement(navLinksRef.current.children[mobileOpenedIndex].children[1], false);
          toggleCollapseElement(navLinksRef.current.children[index].children[1], true);
          setMobileOpenedIndex(index);
      }
    }
  }

  function setOpenedIndex(index, visible = true) {
    _setOpenedIndexes(indexes => {
      return { ...indexes, [index]: visible };
    });
  }

  function toggleHeader() {
    setHeaderOpened(val => !val);
    toggleMobileMenu(mobileOpenedIndex);
  }

  function isLogoDark() {
    return (width > 1200 && location.pathname !== "/") || (width <= 1200 && headerOpened && location.pathname !== "/");
  }

  async function logout() {
    try {
      await logoutUserApi();
      navigate("/");
      setUuid(null);
      setModalVisible(false);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la déconnexion, veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function showLogoutModal() {
    setModalContent({
      title: "Déconnexion",
      content: (
        <>
          <p className='mb-lg'>Êtes-vous sûr de vouloir vous déconnecter ?</p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              onClick={logout}
              padding='10px 25px'
              bgColor='var(--dark-red)'
              color='var(--white)'
              hoverColor='var(--dark-red)'>
              Déconnexion
            </FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  function showDemoModal() {
    setModalContent({
      title: "Demander une démo",
      content: <DemoForm />,
    });
    setModalVisible(true);
  }

  async function getUserInitials(userData) {
    if (userData && userData.first_name && userData.last_name)
      setUserInitials(userData.first_name.charAt(0).toUpperCase() + userData.last_name.charAt(0).toUpperCase());
    else setUserInitials("");
  }

  function handleNewsClick() {
    localStorage.setItem("visitedNews", "true");
    setHasNewNews(false);
  }

  return (
    <>
      <div
        className={
          "header-background " +
          className +
          (headerOpened ? " header-background-opened " : "") +
          (location.pathname === "/" ? "homepage-header" : "")
        }>
        <section className={"container header-container " + (headerOpened ? "opened-header" : "closed-header")}>
          <nav className='flex s-between relative'>
            <NavLink to='/' className='logo-container'>
              <EmcLogo
                dark={isLogoDark()}
                typeLogo={width > 992 || headerOpened ? 1 : 2}
                height={width > 576 ? "60px" : "40px"}
              />
            </NavLink>
            <div className='mobile-header-buttons'>
              <UnfilledButton
                className='relative'
                padding='4px'
                to='/panier'
                bgColor='var(--white)'
                hoverColor='var(--dark-blue)'>
                <Cart color='var(--white)' />
                {!isCartEmpty && <span className='mobile-cart-badge cart-badge'></span>}
              </UnfilledButton>
              <div className='burger-menu' onClick={toggleHeader}>
                <div />
                <div />
                <div />
              </div>
            </div>
            <ul className='navbar-container' ref={navLinksRef}>
              <li onMouseLeave={() => setOpenedIndex(0, false)} onMouseEnter={() => setOpenedIndex(0)}>
                <p
                  custom-title='Estimer'
                  className={"navbar-header " + (mobileOpenedIndex === 0 ? "mobile-opened-index" : "")}
                  onClick={() => toggleMobileMenu(0)}>
                  Estimer
                  <Bracket color='var(--dark-blue)' rotation='180deg' width='20px' className='header-bracket' />
                </p>
                <ul className='navbar-content '>
                  <NavLink to='/detail-parcours/fonds-de-commerce'>
                    <Shop />
                    Fonds de commerce
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                  <NavLink to='/detail-parcours/titres-de-societe'>
                    <Factory />
                    Titres de société
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                  <NavLink to='/detail-parcours/murs-commerciaux'>
                    <Building />
                    Murs commerciaux
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                  <NavLink to='/methode'>
                    <Gear />
                    Méthode & philosophie
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                  <NavLink to='/exemples-rapports'>
                    <Emc />
                    Exemples de rapports
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                  <NavLink to='/liste-activites'>
                    <Directions />
                    Activités estimables
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                </ul>
              </li>
              <li onMouseLeave={() => setOpenedIndex(1, false)} onMouseEnter={() => setOpenedIndex(1)}>
                <p
                  custom-title='Outils'
                  className={"navbar-header " + (mobileOpenedIndex === 1 ? "mobile-opened-index" : "")}
                  onClick={() => toggleMobileMenu(1)}>
                  Outils
                  <Bracket color='var(--dark-blue)' rotation='180deg' width='20px' className='header-bracket' />
                </p>
                <ul className='navbar-content '>
                  <NavLink to='/outils-pratiques'>
                    <LightBulb />
                    Outils pratiques à télécharger
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                  <NavLink to='/lexique-commercial'>
                    <Alphabet />
                    Lexique commercial
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                  {/* <NavLink to='/annonces'>
                    <Megaphone />
                    Les annonces
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink> */}
                  <NavLink to='/faq'>
                    <InterrogationMark />
                    F.A.Q
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                </ul>
              </li>
              <li onMouseLeave={() => setOpenedIndex(2, false)} onMouseEnter={() => setOpenedIndex(2)}>
                <p
                  custom-title='Conseils'
                  className={"navbar-header " + (mobileOpenedIndex === 2 ? "mobile-opened-index" : "")}
                  onClick={() => toggleMobileMenu(2)}>
                  Conseils
                  <Bracket color='var(--dark-blue)' rotation='180deg' width='20px' className='header-bracket' />
                </p>
                <ul className='navbar-content '>
                  <NavLink to='/conseils-pros'>
                    <Advice />
                    Conseils de pros
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                  <NavLink to='/guides-agents-immos'>
                    <FingerSnap />
                    Nos guides pour agents immos
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                </ul>
              </li>
              <li onMouseLeave={() => setOpenedIndex(3, false)} onMouseEnter={() => setOpenedIndex(3)}>
                <p
                  custom-title='Simulateurs'
                  className={"navbar-header " + (mobileOpenedIndex === 3 ? "mobile-opened-index" : "")}
                  onClick={() => toggleMobileMenu(3)}>
                  Simulateurs
                  <Bracket color='var(--dark-blue)' rotation='180deg' width='20px' className='header-bracket' />
                </p>
                <ul className='navbar-content '>
                  <NavLink to='/simulateur-credit'>
                    <Chart />
                    Simulateur de crédit
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                  <NavLink to='/plan-financement'>
                    <Rapport />
                    Plan de financement
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                  <NavLink to='/calculateur-cotisations'>
                    <Calculator />
                    Calculateur de cotisations
                    <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                  </NavLink>
                </ul>
              </li>
              <li>
                <p custom-title='Tarifs'>
                  <NavLink to='/tarifs'>Tarifs</NavLink>
                </p>
              </li>
            </ul>
            <div className='header-buttons-container' ref={accountNavContainerRef}>
              <div className='demo-button' onClick={showDemoModal}>
                Demander une démo
              </div>
              <div className='account-button-container' onClick={() => setOpenedAccountNav(!openedAccountNav)}>
                <UnfilledButton className='account-button' padding='4px'>
                  {userInitials ? userInitials : <User className='header-user-icon' width='22px' />}
                  {!isCartEmpty && (
                    <span className={"cart-badge " + (headerOpened && width < 1200 ? "mobile-cart-badge" : "")}></span>
                  )}
                </UnfilledButton>
                <Bracket
                  rotation={openedAccountNav ? "0" : "180deg"}
                  width='10px'
                  color={location.pathname === "/" ? "var(--white)" : "var(--dark-blue)"}
                />
                <ul className='navbar-content account-nav' ref={accountNavRef}>
                  {getUuid() ? (
                    <>
                      <NavLink to='/mon-compte/mes-estimations'>
                        Mes estimations
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                      <NavLink to='/mon-compte/mes-outils'>
                        Mes outils
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                      <NavLink to='/mon-compte/mon-profil'>
                        Mon profil
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                      {canAccess("article_editor_access", accesses) && (
                        <NavLink to='/admin/editeur-articles'>
                          Editeur d'articles
                          <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                        </NavLink>
                      )}
                      <NavLink to='/panier' className='panier-navlink'>
                        Mon panier
                        {!isCartEmpty && (
                          <span className={"cart-badge " + (headerOpened && width < 1200 ? "mobile-cart-badge" : "")}>
                            1
                          </span>
                        )}
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                      <NavLink to='/news'>
                        Nouveautés
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                      <NavLink onClick={showLogoutModal} className='header-deconnexion-link'>
                        Déconnexion
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                    </>
                  ) : (
                    <>
                      <NavLink to='/connexion'>
                        Connexion
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                      <NavLink to='/inscription'>
                        Créer un compte
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                      <NavLink to='/news'>
                        Nouveautés
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </section>
      </div>
      {width <= 1200 && !headerOpened && location.pathname !== "/" && (
        <RoundedDiv rotation='180deg' height='3vw' radius='1600vw' className='header-rounded-div mb-sm' />
      )}
    </>
  );
}

export default Header;
