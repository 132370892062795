import React from "react";
import Input from "../../../molecules/formComponents/input/Input";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import { useForm } from "react-hook-form";
import { defaultValidation, emailValidation, phoneValidation } from "../../../../utils/formValidation/FormValidation";
import Textarea from "../../../molecules/formComponents/textarea/Textarea";

function DemoForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onSubmit(values) {
    // TODO
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='row-1000'>
        <Input useForm={{ register: register }} error={errors?.lastname?.message} name='lastname' label='Nom *' />
        <Input useForm={{ register: register }} error={errors?.firstname?.message} name='firstname' label='Prénom *' />
      </div>
      <div className='row-1000'>
        <Input
          useForm={{ register: register }}
          validation={emailValidation}
          error={errors?.email?.message}
          name='email'
          label='Adresse mail *'
        />
        <Input
          useForm={{ register: register }}
          validation={phoneValidation}
          error={errors?.phone?.message}
          name='phone'
          label='Téléphone *'
        />
      </div>
      <Textarea
        useForm={{ register: register }}
        error={errors?.message?.message}
        validation={defaultValidation}
        name='message'
        label='Décrivez-nous vos besoins *'
        defaultValue={`Bonjour,\n\nJe souhaite bénéficier d’une démonstration en ligne de votre outil d’estimation.\n\nMerci de me contacter dès que possible.`}
        className=' textarea-demo-form'
      />
      <div className='form-legend'>* Obligatoire</div>
      <div className='centered mb-sm'>
        <FilledButton type='submit' padding='10px 25px'>
          Valider
        </FilledButton>
      </div>
    </form>
  );
}

export default DemoForm;
