import axiosInstance from "./AxiosConfig";

//Folders :

export const createFolderApi = async (folderName, descriptionFolder, teamId, userId) =>
  axiosInstance.post("/folders/create", {
    folder_name: folderName,
    description: descriptionFolder,
    team_id: teamId,
    user_id: userId,
  });

export const fetchUserFoldersApi = async uuid =>
  axiosInstance.get("/folders", {
    params: {
      method: "user",
      id: uuid,
    },
  });

export const fetchFolderByIdApi = async folderId =>
  axiosInstance.get("/folders", {
    params: {
      method: "folder",
      id: folderId,
    },
  });

export const duplicateFolderApi = async (folderId, folderName) =>
  axiosInstance.post("/folders/duplicate", {
    folder_id: folderId,
    folder_name: folderName,
  });

export const archiveFolderApi = async (folderId, shouldArchive) =>
  axiosInstance.post(`/folders/archive`, {
    folder_id: folderId,
    archive: shouldArchive,
  });

export const updateFolderApi = async (folderId, teamId, folderName, descriptionFolder) =>
  axiosInstance.put(`/folders/edit/${folderId}`, {
    team_id: teamId,
    folder_name: folderName,
    description: descriptionFolder,
  });

export const deleteFolderApi = async folderId => axiosInstance.delete(`/folders/delete/${folderId}`);

export const deleteEstimationFromFolderApi = async (folderId, estimationId, userConsent = false) =>
  axiosInstance.delete(`/folders/delete/${folderId}/estimation/${estimationId}`, {
    params: {
      "user-consent": userConsent,
    },
  });

export const deleteAllEstimationsFromFolderApi = async (folderId, userConsent = false) =>
  axiosInstance.delete(`/folders/delete/${folderId}/estimation/all`, {
    params: {
      "user-consent": userConsent,
    },
  });

export const addEstimationToFolderApi = async (folderId, estimationId) =>
  axiosInstance.post("/folders/add/estimation", {
    folder_id: folderId,
    estimation_id: estimationId,
  });

//Deliverables :

export const createDeliverableApi = async (deliverableName, folderId, estimationsId) =>
  axiosInstance.post("/folders/deliverable/create", {
    deliverable_name: deliverableName,
    folder_id: folderId,
    estimations: estimationsId,
  });

export const addEstimationToDeliverableApi = async (deliverableId, estimationId) =>
  axiosInstance.post("/folders/deliverable/add/estimation", {
    deliverable_id: deliverableId,
    estimation_id: estimationId,
  });

export const duplicateDeliverableApi = async (deliverableId, deliverableName) =>
  axiosInstance.post("/folders/deliverable/duplicate", {
    deliverable_id: deliverableId,
    deliverable_name: deliverableName,
  });

export const fetchDeliverableByIdApi = async deliverableId =>
  axiosInstance.get("/folders/deliverable", {
    params: {
      method: "deliverable",
      id: deliverableId,
    },
  });

export const fetchDeliverablesByFolderIdApi = async folderId =>
  axiosInstance.get("/folders/deliverable", {
    params: {
      method: "folder",
      id: folderId,
    },
  });

export const updateDeliverableApi = async (deliverableId, deliverableName) =>
  axiosInstance.put(`/folders/deliverable/edit/${deliverableId}`, {
    deliverable_name: deliverableName,
  });

export const deleteDeliverableApi = async deliverableId =>
  axiosInstance.delete(`/folders/deliverable/delete/${deliverableId}`);

export const deleteEstimationFromDeliverableApi = async (deliverableId, estimationId) =>
  axiosInstance.delete(`/folders/deliverable/delete/${deliverableId}/estimation/${estimationId}`);

export const deleteAllEstimationsFromDeliverableApi = async deliverableId =>
  axiosInstance.delete(`/folders/deliverable/delete/${deliverableId}/estimation/all`);
