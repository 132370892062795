import "./GuidelineTitle.css";
import React from "react";

function GuidelineTitle({ number, title, style = {}, color = "var(--darker-blue)" }) {
  return (
    <div className='timeline-title-container' style={{ "--color": color, ...style }}>
      <div className='timeline-title'>
        {number && <div>{number}</div>}
        <p>{title}</p>
      </div>
    </div>
  );
}

export default GuidelineTitle;
