import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import TextArea from "../../molecules/formComponents/textarea/Textarea";
import Input from "../../molecules/formComponents/input/Input";
import NumberInput from "../../molecules/formComponents/numberInput/NumberInput";
import { addNewsApi } from "../../../api/NewsApi";
import { useAuth } from "../../../context/AuthContext";
import Bracket from "../../atoms/icons/general/bracket/Bracket";
import Card from "../../molecules/cards/card/Card";
import Banner from "../../../components/molecules/banner/Banner";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import "./NewsAddForm.css";

function getFileNameWithoutExtension(fileName) {
  return fileName.split(".").slice(0, -1).join(".");
}

function NewsAddForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const [pictures, setPictures] = useState([]);
  const navigate = useNavigate();
  const { canAccess } = useAuth();

  const fileToBase64 = file => {
    return new Promise((resolve, reject) => {
      if (!(file instanceof File)) {
        return reject(new TypeError("Le paramètre doit être une instance de File"));
      }

      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const onSubmit = async data => {
    try {
      const image = pictures.length > 0 ? pictures[0].file : null;

      const payload = {
        news_name: data.news_name,
        news_date: data.news_date,
        news_category: data.news_category || "",
        news_tag: data.news_tag || "",
        news_description: data.news_description,
        news_link: data.news_link || "",
        news_tuto_link: data.news_tuto_link || "",
        total_likes_number: data.total_likes_number || 0,
        total_dislikes_number: data.total_dislikes_number || 0,
        news_picture: image
          ? {
              data: await fileToBase64(image),
              name: getFileNameWithoutExtension(image.name),
            }
          : null,
      };

      console.log("Payload envoyé à l'API :", payload);

      const response = await addNewsApi(payload);

      if (response.status === 200) {
        setFormSuccess("Votre actualité a été enregistrée avec succès !");
        setFormError(null);
        reset();
        navigate("/news");
      } else {
        throw new Error(`Erreur HTTP ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'actualité :", error.response?.data || error.message);
      setFormError("Une erreur est survenue lors de l'enregistrement de l'actualité. Veuillez réessayer plus tard.");
    }
  };

  function handleAddFiles(e) {
    const files = Array.from(e.target.files);
    const newPictures = [];

    files.forEach(file => {
      if (file instanceof File) {
        const fileName = file.name;
        const isDuplicate = pictures.some(picture => picture.file.name === file.name);

        if (!isDuplicate) {
          newPictures.push({
            file,
            preview: URL.createObjectURL(file),
          });
        }
      }
    });

    setPictures(prevPictures => [...prevPictures, ...newPictures]);
  }

  useEffect(() => {
    return () => {
      pictures.forEach(picture => {
        URL.revokeObjectURL(picture.preview);
      });
    };
  }, [pictures]);

  return (
    <>
      <section className='container'>
        <Breadcrumbs
          routes={[
            { to: "/", name: "Accueil" },
            { to: "/news", name: "Nouveautés" },
            { to: "/news-add", name: "Ajouter une nouveauté" },
          ]}
        />
        <Banner title='Admin' />
        <Breadcrumbs
          routes={[
            {
              to: "/news",
              name: "Retour aux nouveautés",
            },
          ]}
        />
        <Card className='full-page-form'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset className='borderless-fieldset full-page-form'>
              <h1 className='title-form centered'>Ajouter une nouveauté</h1>
              <Input
                label='Titre'
                name='news_name'
                register={register}
                error={errors.news_name?.message}
                validation={{ required: true }}
              />
              <Input
                label='Date'
                name='news_date'
                type='date'
                register={register}
                error={errors.news_date?.message}
                placeholder="Date de l'actualité"
                validation={{ required: true }}
              />
              <Input label='Catégorie' name='news_category' register={register} validation={{ required: false }} />
              <select
                id='news_tag'
                {...register("news_tag")}
                className='styled-dropdown'
                validation={{ required: false }}>
                <option value='add'>Nouveau</option>
                <option value='update'>Mise à jour</option>
                <option value='fix'>Amélioration</option>
              </select>
              <TextArea
                label='Description'
                name='news_description'
                register={register}
                error={errors.news_description?.message}
                placeholder="Description de l'actualité"
                validation={{ required: true }}
              />
              <Input
                label='Lien de la nouveauté'
                name='news_link'
                register={register}
                validation={{ required: false }}
              />
              <Input label='Lien du tuto' name='news_tuto_link' register={register} validation={{ required: false }} />
              <NumberInput
                label='Total de likes'
                name='total_likes_number'
                register={register}
                validation={{ required: false }}
                useForm={{ register }}
              />
              <NumberInput
                label='Total de dislikes'
                name='total_dislikes_number'
                register={register}
                validation={{ required: false }}
                useForm={{ register }}
              />

              <div className='centered'>
                <FilledButton
                  type='button'
                  color='var(--blue)'
                  className='add-button'
                  onClick={() => document.getElementById("file-input-add").click()}>
                  Ajouter des images
                </FilledButton>
                <input id='file-input-add' type='file' style={{ display: "none" }} multiple onChange={handleAddFiles} />
              </div>
              {pictures.length > 0 && (
                <div className='image-container'>
                  <div className='preview-images'>
                    {pictures.map((picture, index) => (
                      <div key={index} className='image-preview'>
                        <img src={picture.preview} alt={`preview-${index}`} className='preview-image' />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {formSuccess && <span className='success-message'>{formSuccess}</span>}
              {formError && <span className='error-message'>{formError}</span>}
              <div className='centered mt-sm'>
                <FilledButton color='var(--white)' hoverColor='var(--gold)' bgColor='var(--gold)' type='submit'>
                  Enregistrer
                </FilledButton>
              </div>
            </fieldset>
          </form>
        </Card>
      </section>
    </>
  );
}

export default NewsAddForm;
