import { useWindowSize } from "@uidotdev/usehooks";
import "./MethodeRowTextImg.css";
import React from "react";

function MethodeRowTextImg({
  img,
  title,
  children,
  imgRight,
  alt,
  maxWidth = "400px",
  textMargin = "auto 0",
  textAlign = "center",
  className = "",
  imgVisibleTreshold = 0,
  textMaxWidth = "40%",
}) {
  const { width } = useWindowSize();

  return (
    <div
      className={"mt-xxl methode-row-text-img methode-row-text-img" + (imgRight ? "-right " : " ") + className}
      style={{
        "--max-width": maxWidth,
        "--text-align": textAlign,
        "--text-margin": textMargin,
        "--text-max-width": textMaxWidth,
      }}>
      {!imgRight && width > imgVisibleTreshold && <img src={img} alt={alt} />}
      <div>
        <p className={"outfit-bold " + (width > 1200 ? "text-xl mb-lg" : "text-lg mb-sm")}>{title}</p>
        {children}
      </div>
      {imgRight && width > imgVisibleTreshold && <img src={img} alt={alt} />}
    </div>
  );
}

export default MethodeRowTextImg;
