import React, { useContext, useEffect } from "react";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import starterParcours from "../../../../../assets/parcours/starter_parcours.svg";

function StarterTdsFdc({ index }) {
  const { moveToNextStep, submitFormsFdc, getStepToGo, setGoToStep, setStep, step } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsFdc.current[index] = startParcours;
  }, []);

  async function startParcours() {
    setTimeout(() => {
      if (getStepToGo() != 0) {
        setStep(getStepToGo());
        setGoToStep(0);
      } else moveToNextStep(index);
    }, 50);
  }
  return (
    <form>
      <fieldset className='borderless-fieldset' style={{ height: step == index ? "auto" : "0" }}>
        <div className='starter-parcours-container'>
          <img src={starterParcours} />
        </div>
      </fieldset>
    </form>
  );
}

export default StarterTdsFdc;
