import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { Navigate } from "react-router-dom";
import AppContext from "../context/AppContext";

// No need to set needLogin if allowedAccess is set
function ProtectedPage({ children, allowedAccess, needLogin, redirection = "/403" }) {
  const { canAccess, accesses } = useAuth();
  const { setAppLoaderVisible } = useContext(AppContext);
  const [hasAccess, setHasAccess] = useState(0);

  useEffect(() => {
    checkAccess();
  }, [accesses]);

  async function checkAccess() {
    if (needLogin) {
      if (localStorage.getItem("uuid")) setHasAccess(1);
      else setHasAccess(-1);

      return;
    }
    setAppLoaderVisible(true);
    const access = canAccess(allowedAccess, accesses);

    if (access === -1) setHasAccess(0);

    setHasAccess(access ? 1 : -1);
    setAppLoaderVisible(false);
  }

  return hasAccess === 0 ? (
    <>
      <div className='page-container' />
    </>
  ) : hasAccess === -1 ? (
    <Navigate to={redirection} replace />
  ) : (
    children
  );
}

export default ProtectedPage;
