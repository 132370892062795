import "./Tarifs.css";
import React from "react";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import Crown from "../../../components/atoms/icons/tarifs/crown/Crown";
import { useState } from "react";
import { useEffect } from "react";
import FilledButton from "../../../components/molecules/buttons/filledButton/FilledButton";
import Check from "../../../components/atoms/icons/general/check/Check";
import { useWindowSize } from "@uidotdev/usehooks";
import RapportsRow from "../../../components/organisms/rapportsRow/RapportsRow";
import { useCart } from "../../../context/CartContext";
import { useNavigate } from "react-router-dom";

function Tarifs() {
  const [discount, setDiscount] = useState({});
  const [normalPrices, setNormalPrices] = useState({});

  const { width } = useWindowSize();

  const { addToCart } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPrices();
    fetchDiscounts();
  }, []);

  async function fetchPrices() {
    setNormalPrices({
      liberty: "99€",
      maestro: "79€",
      optimum: "69€",
    });
  }

  async function fetchDiscounts() {
    //  setDiscount({
    //    liberty: {
    //       discountRate: '-40%',
    //       finalPrice: '59€',
    //    },
    //    maestro: {
    //       discountRate: '-35%',
    //       finalPrice: '52€',
    //    },
    //    optimum: {
    //       discountRate: '-35%',
    //       finalPrice: '52€',
    //    },
    // });
  }

  function pickTarif(tarifName) {
    addToCart({ name: tarifName, price: normalPrices[tarifName], discount: discount[tarifName]?.finalPrice || null });
    navigate("/panier");
  }

  const Tarif = ({ name, engagment, color }) => {
    return (
      <div className={name + "-tarif tarif-container"} style={{ "--color": color }}>
        {name === "maestro" && <Crown className='maestro-crown' />}
        <div className='tarif-title'>
          {name}
          {discount[name] && <div className='tarif-discount'>{discount[name]?.discountRate}</div>}
        </div>
        <div className='tarif-content'>
          <div className='price-row'>
            {discount[name] ? (
              <>
                <p className='tarif-price tarif-with-discount'>
                  {name === "maestro" ? (
                    <>
                      <span className='maestro-price'>{discount[name].finalPrice}</span>
                      <span className='normal-price-crossed'>{normalPrices[name]}</span>
                    </>
                  ) : (
                    <>
                      <span className='normal-price-crossed'>{normalPrices[name]}</span>
                      {discount[name].finalPrice}
                    </>
                  )}
                </p>
                <p className='tarif-duration with-promo'>ht/mois</p>
              </>
            ) : (
              <>
                <p className='tarif-price'>
                  {normalPrices[name]}
                  {name === "maestro" ? <br /> : <>&nbsp;</>}
                  <span className='tarif-duration'>ht/mois</span>
                </p>
              </>
            )}
          </div>
          <p className='tarif-engagment'>
            {engagment[0]}
            <br />
            <span>{engagment[1]}</span>
            <br />
            {engagment[2]}
          </p>
          <div className='centered tarif-activate-button'>
            <FilledButton
              hoverColor='var(--dark-blue)'
              fontSize={width > 1200 ? "1.3rem" : "1.3rem"}
              color={name === "maestro" ? "var(--white)" : "var(--dark-blue)"}
              padding='5px 15px'
              bgColor={color}
              onClick={() => pickTarif(name)}
              disabled='true'>
              Activer
            </FilledButton>
          </div>
          <div className='centered'>
            <p className='beta-tarif'>* Indisponible dans la version Beta</p>
            <a href='https://estimermoncommerce.fr/offres' className='tarif-v1'>
              Accéder à la version originale
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            {
              to: "/",
              name: "Accueil",
            },
            {
              to: "/tarifs",
              name: "Tarifs",
            },
          ]}
        />
        <Banner title='Nos offres' />
        <div className='tarifs-page-container'>
          <div className='relative'>
            <div className='tarifs-row'>
              <Tarif name='maestro' engagment={["Engagement", "12 MOIS", "Paiement mensuel"]} color='#180047' />
            </div>
            <div className='tarifs-row'>
              <Tarif name='liberty' engagment={["", "MENSUEL", "Sans engagement"]} color='#D9E1FF' />
              {width > 992 && <div className='empty-tarif-container' />}
              <Tarif
                name='optimum'
                engagment={["Engagement", "12 MOIS", "Paiement annuel en 1 fois"]}
                color='var(--gold)'
              />
            </div>
          </div>
          <div className='tarifs-detail'>
            <p className='tarif-detail-title'>Toutes nos offres incluent :</p>
            <div className='row-1000'>
              <div className='row-500'>
                <div className='centered'>
                  <Check width='25px' />
                  <p>Nombre illimité d'estimations</p>
                </div>
                <div className='centered'>
                  <Check width='25px' />
                  <p>Estimations modifiables à volonté</p>
                </div>
              </div>
              <div className='row-500'>
                <div className='centered'>
                  <Check width='25px' />
                  <p>Personnalisation de vos rapports</p>
                </div>
                <div className='centered'>
                  <Check width='25px' />
                  <p>Accès illimité à tous nos outils</p>
                </div>
              </div>
            </div>
          </div>
          <p className='mt-lg text-center poppins-medium text-xs'>
            Votre offre se renouvelle automatiquement à sa date d'échéance (mois ou année), sauf si vous choisissez de
            la résilier avant.
          </p>
          <p className='text-center my-xl outfit-bold text-xxl'>Exemples de rapports</p>
        </div>
      </section>
      <RapportsRow className='tarifs-rapports-row' />
    </>
  );
}

export default Tarifs;
