import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import GuidesAgentsImmos from "../pages/articles/guidesAgentsImmos/GuidesAgentsImmos";
import { HelmetProvider } from "react-helmet-async";
import "../stylesheets/main.css";
import Home from "../pages/autres/home/Home";
import ConseilsPros from "../pages/articles/conseilsPros/ConseilsPros";
import ConseilsProsArticle from "../pages/articles/conseilsProsArticle/ConseilsProsArticle";
import OutilsPratiques from "../pages/tools/outilsPratiques/OutilsPratiques";
import { getScrollbarWidth } from "../utils/Utils";
import Faq from "../pages/tools/faq/Faq";
import AppContext from "../context/AppContext";
import { CartProvider } from "../context/CartContext";
import { AuthProvider } from "../context/AuthContext";
import Modal from "../components/organisms/modal/Modal";
import GuidesAgentsImmosArticle from "../pages/articles/guidesAgentsImmosArticle/GuidesAgentsImmosArticle";
import OutilPratiqueDetail from "../pages/tools/outilPratiqueDetail/OutilPratiqueDetail";
import ScrollToTop from "../utils/ScrollToTop";
import Tarifs from "../pages/autres/tarifs/Tarifs";
import PlanFinancement from "../pages/calculators/planFinancement/PlanFinancement";
import Notification from "../components/organisms/notification/Notification";
import Test from "../pages/test/Test";
import DevelopmentPage from "../pages/errors/developmentPage/DevelopmentPage";
import PolitiqueConfidentialite from "../pages/legal/politiqueConfidentialite/PolitiqueConfidentialite";
import MentionsLegales from "../pages/legal/mentionsLegales/MentionsLegales";
import Cgu from "../pages/legal/cgu/Cgu";
import Cgv from "../pages/legal/cgv/Cgv";
import Loader from "../components/organisms/loader/Loader";
import Header from "../components/organisms/header/Header";
import Footer from "../components/organisms/footer/Footer";
import ErrorPage from "../pages/errors/errorPage/ErrorPage";
import { useWindowSize } from "@uidotdev/usehooks";
import DetailParcours from "../pages/estimations/parcours/detailParcours/DetailParcours";
import ParcoursStarter from "../pages/estimations/parcours/parcoursStarter/ParcoursStarter";
import Overview from "../pages/estimations/parcours/overview/Overview";
import { CookiesProvider } from "react-cookie";
import CookieConsentModal from "../components/forms/cookies/cookiesModal/CookieConsentModal";
import ArticlesEditor from "../pages/admin/articlesEditor/ArticlesEditor";
import Access from "../pages/autres/access/Access";
import RequireCode from "../pages/autres/access/RequireCode";
import News from "../pages/autres/news/News";
import ProtectedPage from "../utils/ProtectedPage";
import Method from "../pages/estimations/method/Method";
import ReportExamples from "../pages/estimations/reportExamples/ReportExamples";
import ActivityList from "../pages/estimations/activityList/ActivityList";
import Glossary from "../pages/tools/glossary/Glossary";
import CreditSimulator from "../pages/calculators/creditSimulator/CreditSimulator";
import ContributionCalculator from "../pages/calculators/contributionCalculator/ContributionCalculator";
import Account from "../pages/account/account/Account";
import Register from "../pages/account/register/Register";
import Cart from "../pages/cart/cart/Cart";
import Order from "../pages/cart/order/Order";
import CookiesSetup from "../pages/legal/cookiesSetup/CookiesSetup";
import Login from "../pages/account/login/Login";
import NewsUpdateForm from "../components/forms/newsForm/NewsUpdateForm";
import NewsAdd from "../components/forms/newsForm/NewsAddForm";

function App() {
  const [modalVisible, _setModalVisible] = useState(false);
  const [canHideOnClickOutside, setCanHideOnClickOutside] = useState(true);
  const [modalContent, setModalContent] = useState({ title: "", content: <></>, buttons: <></> });
  const [appLoaderVisible, setAppLoaderVisible] = useState(false);
  const [mustAppLoaderShow, setMustAppLoaderShow] = useState(false);
  const createNotificationFunction = useRef(null);
  const { width } = useWindowSize();

  useEffect(() => {
    if (appLoaderVisible || (!appLoaderVisible && !mustAppLoaderShow)) togglePageScroll();
  }, [appLoaderVisible, mustAppLoaderShow]);

  useEffect(() => {
    document.body.style.setProperty("--scrollbar-width", getScrollbarWidth() + "px");
  }, [width]);

  useEffect(() => {
    if (appLoaderVisible) {
      setMustAppLoaderShow(true);
      setTimeout(() => {
        setMustAppLoaderShow(false);
      }, 800);
    }
  }, [appLoaderVisible]);

  useEffect(() => {
    if (!modalVisible) {
      setCanHideOnClickOutside(true);
    }
  }, [modalVisible]);

  function setModalVisible(visible, canClickOutside = true) {
    setCanHideOnClickOutside(canClickOutside);
    _setModalVisible(visible);
    if (!visible) setModalContent({ title: "", content: <></>, buttons: <></> });
  }

  function togglePageScroll() {
    if (appLoaderVisible) {
      document.body.classList.add("loading-page");
    } else {
      document.body.classList.remove("loading-page");
    }
  }

  function toggleBodyScroll(enable) {
    if (enable) document.body.classList.remove("non-scrollable-body");
    else document.body.classList.add("non-scrollable-body");
  }

  function createNotification(content, bgColor = "var(--blue)", color = "var(--white)", visibleTime = 5000) {
    if (createNotificationFunction.current) createNotificationFunction.current(content, bgColor, color, visibleTime);
  }

  return (
    <CookiesProvider>
      <AppContext.Provider
        value={{
          setModalContent,
          setModalVisible,
          createNotification,
          setAppLoaderVisible,
          toggleBodyScroll,
        }}>
        <Modal
          visible={modalVisible}
          setVisible={setModalVisible}
          title={modalContent.title}
          canHideOnClickOutside={canHideOnClickOutside}>
          {modalContent.content}
          {modalContent.buttons && <div className='modal-buttons-row'>{modalContent.buttons}</div>}
        </Modal>
        <Notification createNotificationFunction={createNotificationFunction} />
        <Loader visible={appLoaderVisible || mustAppLoaderShow} className='app-loader' />
        <HelmetProvider>
          <Router>
            <CookieConsentModal />
            <CartProvider>
              <AuthProvider>
                <ScrollToTop />
                <Routes>
                  {/* Autres */}
                  <Route path='/access' element={<Access />} />
                  <Route
                    path='/*'
                    element={
                      <RequireCode>
                        <Header />
                        <Routes>
                          <Route path='/' element={<Home />} />

                          {/* Liens légaux */}
                          <Route path='/politique-confidentialite' element={<PolitiqueConfidentialite />} />
                          <Route path='/mentions-legales' element={<MentionsLegales />} />
                          <Route path='/parametrage-cookies' element={<CookiesSetup />} />
                          <Route path='/cgu' element={<Cgu />} />
                          <Route path='/cgv' element={<Cgv />} />

                          {/* Estimations */}
                          <Route path='/debut-parcours' element={<ParcoursStarter />} />
                          <Route path='/synthese/:estimationIdParam' element={<Overview />} />
                          <Route
                            path='/detail-parcours/:parcoursTypeParam/:estimationIdParam'
                            element={<DetailParcours />}
                          />
                          <Route path='/detail-parcours/:parcoursTypeParam/' element={<DetailParcours />} />
                          <Route path='/methode' element={<Method />} />
                          <Route path='/exemples-rapports' element={<ReportExamples />} />
                          <Route path='/liste-activites' element={<ActivityList />} />

                          {/* Outils */}
                          <Route path='/outils-pratiques' element={<OutilsPratiques />} />
                          <Route path='/outils-pratiques/:outilUrl' element={<OutilPratiqueDetail />} />
                          <Route path='/lexique-commercial' element={<Glossary />} />
                          <Route path='/annonces' element={<DevelopmentPage />} />
                          <Route path='/faq' element={<Faq />} />

                          {/* Conseils */}
                          <Route path='/conseils-pros' element={<ConseilsPros />} />
                          <Route path='/conseils-pros/:articleUrl' element={<ConseilsProsArticle />} />
                          <Route path='/guides-agents-immos' element={<GuidesAgentsImmos />} />
                          <Route path='/guides-agents-immos/:articleUrl' element={<GuidesAgentsImmosArticle />} />

                          {/* Simulateurs */}
                          <Route path='/simulateur-credit' element={<CreditSimulator />} />
                          <Route path='/plan-financement' element={<PlanFinancement />} />
                          <Route path='/calculateur-cotisations' element={<ContributionCalculator />} />

                          {/* Compte */}
                          <Route
                            path='/mon-compte/:tabParam'
                            element={
                              <ProtectedPage needLogin redirection='/connexion'>
                                <Account />
                              </ProtectedPage>
                            }
                          />
                          <Route path='/connexion' element={<Login />} />
                          <Route path='/inscription' element={<Register />} />
                          <Route
                            path='/panier'
                            element={
                              <ProtectedPage needLogin redirection='/connexion'>
                                <Cart />
                              </ProtectedPage>
                            }
                          />
                          <Route
                            path='/commande'
                            element={
                              <ProtectedPage needLogin redirection='/connexion'>
                                <Order />
                              </ProtectedPage>
                            }
                          />
                          <Route path='/tarifs' element={<Tarifs />} />

                          {/* Nouveautés */}
                          <Route path='/news' element={<News />} />
                          <Route path='/news/update/:newsId' element={<NewsUpdateForm />} />
                          <Route path='/news-add' element={<NewsAdd />} />

                          {/* Errors */}
                          <Route path='/301' element={<ErrorPage code={301} />} />
                          <Route path='/302' element={<ErrorPage code={302} />} />
                          <Route path='/401' element={<ErrorPage code={401} />} />
                          <Route path='/402' element={<ErrorPage code={402} />} />
                          <Route path='/403' element={<ErrorPage code={403} />} />
                          <Route path='/404' element={<ErrorPage code={404} />} />
                          <Route path='/500' element={<ErrorPage code={500} />} />
                          <Route path='/503' element={<ErrorPage code={503} />} />
                          <Route path='/504' element={<ErrorPage code={504} />} />

                          {/* Admin */}

                          <Route
                            path='/admin/editeur-articles'
                            element={
                              <ProtectedPage allowedAccess='article_editor_access'>
                                <ArticlesEditor />
                              </ProtectedPage>
                            }
                          />
                          <Route path='/test' element={<Test />} />

                          <Route path='*' element={<Navigate to='/404' replace />} />
                        </Routes>
                        <Footer />
                      </RequireCode>
                    }
                  />
                </Routes>
              </AuthProvider>
            </CartProvider>
          </Router>
        </HelmetProvider>
      </AppContext.Provider>
    </CookiesProvider>
  );
}

export default App;
