import React from "react";
import "./FolderPlanFinancement.css";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import Download from "../../../../../../../components/atoms/icons/general/download/Download";
import { useWindowSize } from "@uidotdev/usehooks";
import Tuto from "../../../../../../../components/atoms/icons/general/tuto/Tuto";

function FolderPlanFinancement({ showTooltip = () => {} }) {
  const { width } = useWindowSize();

  return (
    <div>
      <div className='tab-content-header'>
        <h2>
          Plan de financement
          <span className='tuto-icon'>
            <Tuto onClick={() => showTooltip("")} />
          </span>
        </h2>
        <p>Retrouvez ici tous les plans de financement liés à votre dossier.</p>
      </div>
      <div className='folder-plan-buttons'>
        <FilledButton to='/plan-financement' className='mid-page-button plans-button'>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer un plan de financement
        </FilledButton>
        <FilledButton onClick={() => {}} className='mid-page-button plans-button'>
          <Download width={width > 576 ? "30px" : "20px"} /> Importer un plan de financement
        </FilledButton>
      </div>
      <div className='folder-table-plan'>
        <div className='folder-table-plan-header'>
          <p>Nom du plan de financement</p>
          <p>Actions</p>
        </div>
        <div className='folder-table-plan-body'>
          <div className='info-block-plan'>
            <p>Aucun plan de financement dans ce dossier</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FolderPlanFinancement;
