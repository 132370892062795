import { useWindowScroll, useWindowSize } from "@uidotdev/usehooks";
import Banner from "../../../components/molecules/banner/Banner";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import FilledButton from "../../../components/molecules/buttons/filledButton/FilledButton";
import TextHr from "../../../components/molecules/textHr/TextHr";
import ExpendableGroup from "../../../components/organisms/expendableGroup/ExpendableGroup";
import FiltersGroup from "../../../components/organisms/filtersGroup/FiltersGroup";
import "./Faq.css";
import React, { useContext, useEffect, useState } from "react";
import StickySearchbar from "../../../components/molecules/stickySearchbar/StickySearchbar";
import { fetchFaqQuestionsApi } from "../../../api/ToolsApi";
import AppContext from "../../../context/AppContext";
import { getPrettyText } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";

function Faq() {
  const [questions, setQuestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [displayedQuestions, setDisplayedQuestions] = useState([]);
  const [resetSearchValueTrigger, setResetSearchValueTrigger] = useState(false);
  const [emptyResult, setEmptyResult] = useState(false);

  const [windowScroll] = useWindowScroll();
  const { setAppLoaderVisible } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    setAppLoaderVisible(true);
    fetchQuestions();
  }, []);

  useEffect(() => {
    setCategoriesFromQuestions(questions);
    checkIfEmptyResult();
  }, [displayedQuestions]);

  async function fetchQuestions() {
    try {
      let data = (await fetchFaqQuestionsApi()).data;

      data = data.map(question => {
        question.title = getPrettyText(question.title);
        question.name = getPrettyText(question.question);
        question.content = <p dangerouslySetInnerHTML={{ __html: question.answer }}></p>;
        return question;
      });

      setCategoriesFromQuestions(data);
      setQuestions(data);
      setDisplayedQuestions(data);

      setAppLoaderVisible(false);
    } catch (error) {
      navigate("/404");
    }
  }

  function setCategoriesFromQuestions(questions) {
    let tmp = [];
    questions.forEach(question => {
      question.name = question.name;
      question.title = question.title;
      if (!tmp.includes(question.title)) tmp.push(question.title);
    });

    tmp.map(
      (category, index) =>
        (tmp[index] = {
          name: category,
          id: index,
        }),
    );

    setCategories(tmp);
  }

  function handleSearchbarChange(value) {
    if (value.length < 1) setDisplayedQuestions(questions);
    else setDisplayedQuestions(questions.filter(question => question.name.toLowerCase().includes(value.toLowerCase())));

    if (windowScroll.y > 500) window.scrollTo(0, 0);
  }

  function checkIfEmptyResult() {
    let tmp = true;

    categories.map(category =>
      displayedQuestions.filter(question => question.title === category.name).length ? (tmp = false) : null,
    );

    setEmptyResult(tmp);
  }

  function getQuestionsByCategory(category) {
    return displayedQuestions
      .filter(question => question.title === category)
      .map(question => {
        return {
          title: question.name,
          content: question.content,
        };
      });
  }

  return (
    <div className='page-container'>
      <section className='container'>
        <Breadcrumbs
          routes={[
            { name: "Accueil", to: "/" },
            { name: "Outils: Foire aux questions", to: "/faq" },
          ]}
        />
        <Banner title='Foire aux questions' subtitle='Vous souhaitez en savoir plus ? Voici vos questions fréquentes' />
      </section>
      <div className='sticky-container'>
        {categories.length > 0 && (
          <FiltersGroup
            data={categories.map(category => {
              return {
                name: category.name,
                to: "#" + category.id,
                onClick: () => {
                  setResetSearchValueTrigger(value => !value);
                },
              };
            })}
          />
        )}
      </div>
      <StickySearchbar resetValueTrigger={resetSearchValueTrigger} onChange={handleSearchbarChange} />
      <section className='container'>
        {categories.map((category, key) => {
          return displayedQuestions.filter(question => question.title === category.name).length ? (
            <React.Fragment key={key}>
              <TextHr className={"faq-hr " + (key === 0 ? "mt-0" : "")} id={category.id}>
                {category.name}
              </TextHr>
              <ExpendableGroup data={getQuestionsByCategory(category.name)} />
            </React.Fragment>
          ) : null;
        })}
        {emptyResult && (
          <p className='text-xl text-center outfit-semibold'>Aucun résultat ne correspond à votre recherche</p>
        )}
        <FilledButton to='debut-parcours' className='mid-page-button'>
          Démarrer le parcours d'estimation
        </FilledButton>
      </section>
    </div>
  );
}

export default Faq;
