import React, { useContext, useState, useEffect } from "react";
import "./CreateLivrableModal.css";
import AppContext from "../../../../../context/AppContext";
import { useForm } from "react-hook-form";
import Input from "../../../../molecules/formComponents/input/Input";
import { defaultValidation } from "../../../../../utils/formValidation/FormValidation";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import { createDeliverableApi } from "../../../../../api/FolderApi";

function CreateLivrableModal({ folder, folderId, onLivrableCreated = () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);
  const [selectedEstimations, setSelectedEstimations] = useState([]);
  const [folderEstimations, setFolderEstimations] = useState([]);

  useEffect(() => {
    if (folder?.estimations) {
      setFolderEstimations(folder.estimations);
    }
  }, [folder.estimations]);

  function toggleEstimationSelection(estimation) {
    if (selectedEstimations.includes(estimation)) {
      setSelectedEstimations(selectedEstimations.filter(e => e.id !== estimation.id));
    } else {
      setSelectedEstimations([...selectedEstimations, estimation]);
    }
  }

  async function onSubmit(data) {
    const estimationIds = selectedEstimations.map(estimation => ({ id: estimation.id }));

    try {
      const res = await createDeliverableApi(data.deliverableName, folderId, estimationIds);
      createNotification(<>Livrable créé avec succès</>, "var(--green)", "var(--dark-blue)");
      onLivrableCreated(res.data);
      setModalVisible(false);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création du livrable</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='info-create-livrable'>
        <p>
          Créer un livrable pour n'importe quelle partie de l'affaire (Acquéreur, vendeur...). Vous pouvez créer autant
          de livrables que vous le souhaitez.
        </p>
      </div>
      <Input
        validation={defaultValidation}
        useForm={{ register, setValue }}
        error={errors.deliverableName?.message}
        name='deliverableName'
        label='Nom du livrable'
        placeholder='Acquéreur'
      />
      <div className='checkbox-list-container'>
        <h3>Estimation(s) dans le dossier</h3>
        <div className='import-estimations-to-deliverable'>
          {folderEstimations.map(estimation => (
            <Checkbox
              key={estimation.id}
              name={`estimation-${estimation.id}`}
              label={estimation.infos.nom || estimation.infos.enseigne}
              onChange={() => toggleEstimationSelection(estimation)}
              checked={selectedEstimations.includes(estimation)}
            />
          ))}
        </div>
      </div>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Créer le livrable
        </FilledButton>
      </div>
    </form>
  );
}

export default CreateLivrableModal;
