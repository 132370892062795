import React, { useState, useEffect, useContext } from "react";
import SituationLocative from "../../forms/parcours/mursCommerciaux/step3/SituationLocative";
import BienAEvaluer from "../../forms/parcours/mursCommerciaux/step1/BienAEvaluer";
import SituationGeographique from "../../forms/parcours/mursCommerciaux/step2/SituationGeographique";
import CaOccupant from "../../forms/parcours/mursCommerciaux/step4/CaOccupant";
import ActiviteExercee from "../../forms/parcours/mursCommerciaux/step5/ActiviteExercee";
import LoyerCharges from "../../forms/parcours/mursCommerciaux/step6/LoyerCharges";
import Surface from "../../forms/parcours/mursCommerciaux/step7/Surface";
import CaracteristiquesLocalMc from "../../forms/parcours/mursCommerciaux/step8/CaracteristiquesLocalMc";
import ParcoursCarousel from "../../organisms/parcoursCarousel/ParcoursCarousel";
import InfosComplementaires from "../../forms/parcours/mursCommerciaux/step9/InfosComplementaires";
import "./ParcoursMc.css";
import { ParcoursContext } from "../../../context/ParcoursContext";

function ParcoursMc() {
  const { setStepsSummary, step, setInputsNames } = useContext(ParcoursContext);

  useEffect(() => {
    initParcours();
  }, []);

  function setMCInputsNames() {
    setInputsNames([
      "infos.nom",
      "situation_geographique.zone_influence.id0",
      "situation_locative.occupation_local.id0",
      "ca_dernier_occupant",
      "activite",
      "loyer_charges.loyer_annuel",
      "surface.surface_plain_pied",
      "caracteristiques_local.local_angle.id0",
      "infos_complementaires.accessibilite_pmr.id",
    ]);
  }

  function initParcours() {
    setMCInputsNames();
    setStepsSummary([
      {
        name: "Local",
        steps: [1, 2],
      },
      {
        name: "Situation",
        steps: [3, 4],
      },
      {
        name: "Activité",
        steps: [5],
      },
      {
        name: "Chiffres",
        steps: [6],
      },
      {
        name: "Informations",
        steps: [7, 8, 9],
      },
    ]);
  }

  return (
    <>
      <ParcoursCarousel step={step}>
        <BienAEvaluer index={1} />
        <SituationGeographique index={2} />
        <SituationLocative index={3} />
        <CaOccupant index={4} />
        <ActiviteExercee index={5} />
        <LoyerCharges index={6} />
        <Surface index={7} />
        <CaracteristiquesLocalMc index={8} />
        <InfosComplementaires index={9} />
      </ParcoursCarousel>
    </>
  );
}

export default ParcoursMc;
