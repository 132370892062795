import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Access.css";

function Access() {
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const requiredCode = process.env.REACT_APP_REQUIRED_ACCESS_CODE;

  function handleCodeSubmit() {
    if (code === requiredCode) {
      localStorage.setItem("accessCode", code);
      navigate("/");
    } else {
      alert("Code incorrect");
    }
  }

  return (
    <div className='access-page'>
      <h1>Entrer le code d'accès</h1>
      <input type='text' value={code} onChange={e => setCode(e.target.value)} placeholder='Entrez le code' />
      <button onClick={handleCodeSubmit}>Valider</button>
    </div>
  );
}

export default Access;
