import React, { useEffect, useContext } from "react";
import { useCookies } from "react-cookie";
import AppContext from "../../../../context/AppContext";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../molecules/buttons/unfilledButton/UnfilledButton";
import { useNavigate } from "react-router-dom";

function CookieConsentModal() {
  const [cookies, setCookie] = useCookies(["user-consent"]);
  const { setModalContent, setModalVisible } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    let timer;

    if (!cookies["user-consent"]) {
      timer = setTimeout(() => {
        setModalContent({
          title: "Choisissez vos cookies 🍪",
          content: (
            <>
              <p className='mb-lg'>
                <span>
                  Nous utilisons des cookies, car ils sont essentiels pour vous offrir une qualité de navigation
                  optimale et personnalisée, mesurer l'audience de notre site et vous proposer un contenu toujours plus
                  en adéquation avec vos attentes. Mais c’est vous qui gardez le contrôle sur les données que nous
                  collectons et utilisons, bien sûr. En cliquant sur "accepter", vous acceptez le dépôt de cookies et
                  leur utilisation dans les conditions prévues par notre{" "}
                  <a className='link' href='/politique-confidentialite'>
                    Politique relative aux cookies.
                  </a>
                  Vous pourrez par la suite modifier vos préférences cookies à tout moment en cliquant sur "Gérer mes
                  cookies" en bas de page.
                </span>
              </p>
              <div className='modal-buttons-row mb-sm'>
                <FilledButton onClick={() => handleParamCookie()} type='button' padding='10px 25px'>
                  Paramétrer mes choix
                </FilledButton>
              </div>
              <div className='modal-buttons-row'>
                <UnfilledButton onClick={() => handleDenied()} type='submit' padding='10px 25px'>
                  Tout refuser
                </UnfilledButton>
                <FilledButton onClick={() => handleAccept()} type='submit' padding='10px 25px'>
                  Tout accepter
                </FilledButton>
              </div>
            </>
          ),
        });
        setModalVisible(true, false);
      }, 600);
    }

    return () => clearTimeout(timer);
  }, [cookies]);

  function handleAccept() {
    setCookie("user-consent", "accepted", {
      path: "/",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      secure: true,
      sameSite: "None",
    });
    setModalVisible(false);
  }

  function handleDenied() {
    setCookie("user-consent", "denied", {
      path: "/",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      secure: true,
      sameSite: "None",
    });
    setModalVisible(false);
  }

  function handleParamCookie() {
    setModalVisible(false);
    navigate("/parametrage-cookies");
  }

  return null;
}

export default CookieConsentModal;
