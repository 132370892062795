import "./AccountProfile.css";
import React, { useContext, useEffect, useState } from "react";
import Card from "../../../../../components/molecules/cards/card/Card";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import ExpendableGroup from "../../../../../components/organisms/expendableGroup/ExpendableGroup";
import VisitingCardForm from "../../../../../components/forms/account/visitingCardForm/VisitingCardForm";
import FileInput from "../../../../../components/molecules/formComponents/fileInput/FileInput";
import RapportLogo from "../../../../../components/atoms/icons/account/rapportLogo/RapportLogo";
import Lock from "../../../../../components/atoms/icons/account/lock/Lock";
import { Link, useNavigate } from "react-router-dom";
import ArrowSm from "../../../../../components/atoms/icons/general/arrowSm/ArrowSm";
import ProfileForm from "../../../../../components/forms/account/profileForm/ProfileForm";
import { useWindowSize } from "@uidotdev/usehooks";
import AppContext from "../../../../../context/AppContext";
import ForgotPasswordModal from "../../../../../components/forms/modals/account/forgotPasswordModal/ForgotPasswordModal";
import { useAuth } from "../../../../../context/AuthContext";
import { createVisitingCardApi, fetchVisitingCardsByUuidApi } from "../../../../../api/VisitingCardApi";
import { deactivateAccountApi, deleteAccountApi, logoutUserApi } from "../../../../../api/AccountApi";
import UnfilledButton from "../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";

function MonProfil({ navbarRef }) {
  const [visitingCards, setVisitingCards] = useState([]);
  const [importCardError, setImportCardError] = useState("");

  const { width } = useWindowSize();
  const { createNotification, setModalContent, setModalVisible } = useContext(AppContext);
  const { getUuid, userData, setUuid } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCartesVisites();
  }, []);

  async function fetchCartesVisites() {
    try {
      const response = (await fetchVisitingCardsByUuidApi(getUuid())).data;
      setVisitingCards(response);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de vos cartes de visite</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function generateCardName(visitingCards, imported) {
    const genericNamesCount = visitingCards.filter(card => card.card_name.match(/carte\s\d+/)).length;
    return `carte ${genericNamesCount + 1} ${imported ? "(importée)" : ""}`;
  }

  async function importVisitingCard(compressedFile) {
    try {
      const card = (await createVisitingCardApi(generateCardName(visitingCards, true), "imported_card")).data;

      const response = (
        await updateVisitingCardApi(card.id, {
          ...card,
          images: { card: compressedFile },
        })
      ).data;

      setVisitingCards(values => [...values, response]);
      createNotification(<>Votre carte de visite a été importée avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'importation de votre carte de visite</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function createVisitingCard() {
    try {
      const newCard = (await createVisitingCardApi(generateCardName(visitingCards))).data;
      setVisitingCards(values => [...values, newCard]);
      createNotification(<>Votre carte de visite a été créée avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création de votre estimation</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function refreshCardData(cardId) {
    try {
      let tmp = [...visitingCards];
      if (cardId) {
        const response = (await fetchVisitingCardApi(cardId)).data;

        tmp.forEach(card => {
          if (card.id === cardId) {
            card.card_name = response.card_name;
          }
        });
      } else tmp = (await fetchVisitingCardsByUuidApi(getUuid())).data;

      setVisitingCards(tmp);
    } catch (error) {}
  }

  function showForgotPasswordModal() {
    setModalContent({ title: "Mot de passe oublié", content: <ForgotPasswordModal email={userData.email} /> });
    setModalVisible(true);
  }

  function showDeactivateAccountModal() {
    setModalContent({
      title: "Désactiver mon compte",
      content: (
        <>
          <p>
            Êtes-vous sûr de vouloir désactiver votre compte ?
            <br />
            <br />
            En cliquant sur "Valider", EstimerMonCommerce.fr n'utilisera plus vos données.
            <br />
            <br />
            Vous pourrez cependant réactiver votre compte à tout moment par une simple connexion.
          </p>
          <div className='modal-buttons-row mt-md'>
            <UnfilledButton onClick={() => setModalVisible(false)}>Annuler</UnfilledButton>
            <FilledButton onClick={deactivateAccount}>Valider</FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  async function deactivateAccount() {
    try {
      setModalVisible();
      await deactivateAccountApi(getUuid());
      await logoutUserApi();
      setUuid(null);

      navigate("/");
      createNotification(<>Votre compte a été désactivé avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la désactivation de votre compte. Veuillez réessayer plus tard.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function showDeleteAccountModal() {
    setModalContent({
      title: "Supprimer mon compte",
      content: (
        <>
          <p>
            Êtes-vous sûr de vouloir supprimer votre compte ?
            <br />
            <br />
            En cliquant sur "Valider", EstimerMonCommerce.fr détruira vos données définitivement.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton onClick={() => setModalVisible(false)}>Annuler</UnfilledButton>
            <FilledButton onClick={deleteAccount}>Valider</FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  async function deleteAccount() {
    try {
      setModalVisible();
      await deleteAccountApi(getUuid());
      await logoutUserApi();
      setUuid(null);

      navigate("/");
      createNotification(<>Votre compte a été supprimé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression de votre compte. Veuillez réessayer plus tard.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <section className='container'>
      <div className='mon-profil-container'>
        <div>
          <Card>
            <h2>MES CARTES DE VISITE ET MENTIONS LÉGALES</h2>
            <div className='visiting-card-buttons'>
              <FileInput
                onChange={importVisitingCard}
                error={importCardError}
                name='import-visiting-card'
                removeButton={false}
                padding='10px 20px'
                bgColor='var(--sky-blue)'
                buttonText='Importer une carte de visite'
              />
              <FilledButton onClick={createVisitingCard} padding='10px 20px'>
                Créer&nbsp;une&nbsp;carte
              </FilledButton>
            </div>
            <ExpendableGroup
              refreshData={refreshCardData}
              id='visiting-cards-expendable-group'
              data={visitingCards.map((card, key) => {
                return {
                  id: card.id,
                  title: card.card_name,
                  is_default: card.is_default,
                  content: (
                    <VisitingCardForm
                      refreshData={refreshCardData}
                      navbarRef={navbarRef}
                      visitingCardData={card}
                      id={key}
                    />
                  ),
                };
              })}
            />
          </Card>
          {width <= 992 && (
            <div className='mon-profil-responsive-buttons'>
              <FilledButton bgColor='var(--light-blue)' padding='20px' color='var(--dark-blue)'>
                <RapportLogo />
                Modifier mon logo sur l'en-tête de mes rapports d'estimation
              </FilledButton>
              <FilledButton bgColor='var(--light-blue)' padding='20px' color='var(--dark-blue)'>
                <RapportLogo rotate='180deg' />
                Modifier mon logo en pieds de pages de mes rapports d'estimation
              </FilledButton>
            </div>
          )}
          <Card className='mt-lg mon-profil' bgColor='var(--light-blue)'>
            <h2 className='mb-lg'>MON PROFIL</h2>
            <ProfileForm userData={userData} />
          </Card>
          {width <= 992 && (
            <>
              <div className='mon-profil-responsive-buttons'>
                <FilledButton
                  bgColor='var(--light-gold)'
                  padding='20px'
                  color='var(--dark-blue)'
                  onClick={showForgotPasswordModal}>
                  <Lock />
                  Modifier mon mot de passe
                </FilledButton>
              </div>
              <div className='mon-profil-links'>
                <div className='text-grey link-with-arrow' onClick={showDeactivateAccountModal}>
                  Désactiver mon compte
                  <ArrowSm color='var(--grey)' rotation='180deg' />
                </div>
                <div className='text-grey link-with-arrow' onClick={showDeleteAccountModal}>
                  Supprimer mon compte
                  <ArrowSm color='var(--grey)' rotation='180deg' />
                </div>
              </div>
            </>
          )}
        </div>
        {width > 992 && (
          <div className='flex f-column gap-lg'>
            <FilledButton bgColor='var(--light-blue)' padding='20px' color='var(--dark-blue)'>
              <RapportLogo />
              Modifier mon logo sur l'en-tête de mes rapports d'estimation
            </FilledButton>
            <FilledButton bgColor='var(--light-blue)' padding='20px' color='var(--dark-blue)'>
              <RapportLogo rotate='180deg' />
              Modifier mon logo en pieds de pages de mes rapports d'estimation
            </FilledButton>
            <FilledButton
              bgColor='var(--light-gold)'
              padding='20px'
              color='var(--dark-blue)'
              onClick={showForgotPasswordModal}>
              <Lock />
              Modifier mon mot de passe
            </FilledButton>
            <div className='mon-profil-links'>
              <div className='text-grey link-with-arrow' onClick={showDeactivateAccountModal}>
                Désactiver mon compte
                <ArrowSm color='var(--grey)' rotation='180deg' />
              </div>
              <div className='text-grey link-with-arrow' onClick={showDeleteAccountModal}>
                Supprimer mon compte
                <ArrowSm color='var(--grey)' rotation='180deg' />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default MonProfil;
