import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import Radio from "../../../../molecules/formComponents/radio/Radio";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import Select from "../../../../molecules/formComponents/select/Select";
import Input from "../../../../molecules/formComponents/input/Input";
import { max2250Validation, numberValidation } from "../../../../../utils/formValidation/FormValidation";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea";
import { formatObjectForPosting, resetObjectValues } from "../../../../../utils/Utils";
import { fetchDropdownValuesApi } from "../../../../../api/ParcoursApi";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto";

function LoyerBail({ id, index }) {
  const {
    register,
    handleSubmit,
    unregister,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const {
    showTooltip,
    handleTabs,
    moveToNextStep,
    step,
    setStep,
    getStepToGo,
    setGoToStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fetchDropdownValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);
  const soumissionBail = watch("soumission_bail_checkbox");
  const infosComplementaires = watch("infos_complementaires_checkbox");
  const [dropdownsValues, setDropdownsValues] = useState([]);
  const inputForm = { register, unregister, setValue, watch };

  useEffect(() => {
    fillDropdownsValues();
  }, []);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    if (estimationValues?.step >= index && !estimationValues?.loyer_bail?.montant_loyer_annuel)
      return setValue("soumission_bail_checkbox", true);

    fillInputsFromEstimationValues(["loyer_bail"], setValue, estimationValues);
    fillInputsFromEstimationValues(["infos_complementaires"], setValue, estimationValues.loyer_bail, [], "loyer_bail.");
    setValue("notes.note_step_10", estimationValues?.notes?.note_step_10);

    if (estimationValues?.loyer_bail?.infos_complementaires?.bailleur)
      return setValue("infos_complementaires_checkbox", true);

    fillInputsFromEstimationValues(["infos_complementaires"], setValue, estimationValues.loyer_bail);
  }, [estimationValues, dropdownsValues]);

  async function formSubmit(values) {
    if (values.soumission_bail_checkbox) values = resetObjectValues(values);
    if (!values.infos_complementaires_checkbox)
      values.loyer_bail.infos_complementaires = resetObjectValues(values.loyer_bail.infos_complementaires);

    delete values.soumission_bail_checkbox;
    delete values.infos_complementaires_checkbox;

    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    setDropdownsValues(
      await fetchDropdownValues("destination_bail_commercial", "duree_bail", "revision_sur_les_loyers"),
    );
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <p className='text-sky-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2>loyer & bail</h2>
        <Checkbox // visible
          useForm={inputForm}
          name='soumission_bail_checkbox'
          label="l'activité n’est pas soumise à un bail commercial, ou l'exploitant détient les murs et ne se verse pas de loyer"
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey && e.target.checked) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
        <div className='row-1000'>
          <NumberInput // visible if !soumission_bail
            useForm={inputForm}
            visible={!soumissionBail}
            type='number'
            label={
              <>
                <div>
                  loyer <span className='outfit-black'>annuel</span> (hors charges)
                </div>
                <Tuto onClick={() => showTooltip("step_10_montant_loyer_annuel.webp")} />
              </>
            }
            name='loyer_bail.montant_loyer_annuel'
            error={errors?.loyer_bail?.montant_loyer_annuel?.message}
            icon='euro'
          />
          <NumberInput
            useForm={inputForm}
            visible={!soumissionBail}
            type='number'
            label={
              <>
                <div>
                  charges locatives <span className='outfit-black'>annuelles</span>
                </div>
                <Tuto onClick={() => showTooltip("step_10_montant_charges_locatives_annuelles.webp")} />
              </>
            }
            name='loyer_bail.montant_charges_locatives_annuelles'
            error={errors?.loyer_bail?.montant_charges_locatives_annuelles?.message}
            icon='euro'
          />
          <NumberInput
            useForm={inputForm}
            visible={!soumissionBail}
            type='number'
            label={
              <>
                <div>taxe foncière</div>
                <Tuto onClick={() => showTooltip("step_10_montant_taxe_fonciere.webp")} />
              </>
            }
            name='loyer_bail.montant_taxe_fonciere'
            error={errors?.loyer_bail?.montant_taxe_fonciere?.message}
            icon='euro'
          />
        </div>
        <Input
          setValue={setValue}
          visible={!soumissionBail}
          type='date'
          label={
            <>
              Date d’échéance du bail commercial
              <Tuto onClick={() => showTooltip("step_10_date_echeance_bail_commercial.webp")} />
            </>
          }
          name='loyer_bail.date_echeance_bail_commercial'
          error={errors?.loyer_bail?.date_echeance_bail_commercial?.message}
          useForm={inputForm}
        />
        <Select
          visible={!soumissionBail}
          label={
            <>
              destination du bail commercial
              <Tuto onClick={() => showTooltip("step_10_destination_bail_commercial.webp")} />
            </>
          }
          useForm={inputForm}
          error={errors?.loyer_bail?.destination_bail_commercial?.id?.message}
          name='loyer_bail.destination_bail_commercial.id'>
          {dropdownsValues?.destination_bail_commercial?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <div className='row-1000'>
          <Radio
            visible={!soumissionBail}
            label={
              <>
                un logement habitable est-il inclus dans le bail commercial ?
                <Tuto onClick={() => showTooltip("step_10_logement_inclus.webp")} />
              </>
            }
            name='loyer_bail.logement_inclus.id'
            useForm={inputForm}
            error={errors?.loyer_bail?.logement_inclus?.id?.message}
            content={[
              { value: 2, label: "Non" },
              { value: 1, label: "Oui" },
            ]}
          />
          <Radio
            visible={!soumissionBail}
            label="l'activité est-elle soumise à plusieurs baux commerciaux ?"
            name='loyer_bail.soumission_baux_multiples.id'
            useForm={inputForm}
            error={errors?.loyer_bail?.soumission_baux_multiples?.id?.message}
            content={[
              { value: 2, label: "Non" },
              { value: 1, label: "Oui" },
            ]}
          />
        </div>
        <Checkbox
          visible={!soumissionBail}
          label='je souhaite renseigner des informations complémentaires sur le bail commercial'
          name='infos_complementaires_checkbox'
          useForm={inputForm}
        />
        <div className='row-1000'>
          <Input
            visible={infosComplementaires && !soumissionBail}
            label='Identité du bailleur'
            name='loyer_bail.infos_complementaires.bailleur'
            error={errors?.loyer_bail?.infos_complementaires.bailleur?.message}
            useForm={inputForm}
          />
          <Select
            shouldStickBottom={false}
            visible={infosComplementaires && !soumissionBail}
            label='Durée du bail'
            useForm={inputForm}
            error={errors?.loyer_bail?.infos_complementaires.duree_bail?.id?.message}
            name='loyer_bail.infos_complementaires.duree_bail.id'>
            {dropdownsValues?.duree_bail?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
        </div>
        <div className='row-1000'>
          <Input
            visible={infosComplementaires && !soumissionBail}
            label='Désignation des locaux'
            name='loyer_bail.infos_complementaires.designation_locaux'
            error={errors?.loyer_bail?.infos_complementaires.designation_locaux?.message}
            useForm={inputForm}
          />
          <Input
            visible={infosComplementaires && !soumissionBail}
            label='Destination des locaux'
            name='loyer_bail.infos_complementaires.destination_lieux'
            error={errors?.loyer_bail?.infos_complementaires.destination_lieux?.message}
            useForm={inputForm}
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            icon='euro'
            visible={infosComplementaires && !soumissionBail}
            error={errors?.loyer_bail?.infos_complementaires.depot_garantie?.message}
            label='Dépôt de garantie'
            name='loyer_bail.infos_complementaires.depot_garantie'
            useForm={inputForm}
          />
          <Select
            shouldStickBottom={false}
            visible={infosComplementaires && !soumissionBail}
            label='Révision du loyer'
            useForm={inputForm}
            error={errors?.loyer_bail?.infos_complementaires.revision_sur_les_loyers?.id?.message}
            name='loyer_bail.infos_complementaires.revision_sur_les_loyers.id'>
            {dropdownsValues?.revision_sur_les_loyers?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
        </div>
        <Textarea
          visible={!soumissionBail}
          validation={max2250Validation}
          useForm={inputForm}
          error={errors?.notes?.note_step_10?.message}
          name='notes.note_step_10'
          label='notes et remarques'
          className={`${!soumissionBail ? "mt-md" : ""}`}
          {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
        />
      </fieldset>
    </form>
  );
}

export default LoyerBail;
