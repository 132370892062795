import axiosInstance from "./AxiosConfig";

export const fetchAllArticlesApi = onlyPublished =>
  axiosInstance.get(`/conseils-pro${onlyPublished ? "?is-published=true" : ""}`);

export const fetchArticleByUrlApi = url => axiosInstance.get(`/conseils-pro/details?article-url=${url}`);

export const fetchArticlesTagsApi = () => axiosInstance.get("/conseils-pro/categories");

export const createArticleApi = articleUrl => axiosInstance.post("/conseils-pro/article", { create_url: articleUrl });

export const updateArticleApi = (article, articleId) =>
  axiosInstance.put(`/conseils-pro/article/${articleId}`, article);

export const fetchArticlesLeadMagnetsApi = () => axiosInstance.get("/conseils-pro/outils-pratiques");

export const uploadArticleImageApi = (articleId, articleUrl, images) =>
  axiosInstance.post("/conseils-pro/article/images/add", { article_id: articleId, url: articleUrl, images });

export const deleteUnuploadedImagesApi = (relevant_path, images) =>
  axiosInstance.post("/conseils-pro/article/images/delete", { relevant_path, images });

export const publishArticleApi = (articleId, isPublished) =>
  axiosInstance.put(`/conseils-pro/article/published/${articleId}`, { published: isPublished });
