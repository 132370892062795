import Banner from "../../../components/molecules/banner/Banner";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import TextHr from "../../../components/molecules/textHr/TextHr";
import ComponentsRow from "../../../components/organisms/componentsRow/ComponentsRow";
import ExpendableGroup from "../../../components/organisms/expendableGroup/ExpendableGroup";
import "./Glossary.css";
import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import { HashLink } from "react-router-hash-link";
import FilledButton from "../../../components/molecules/buttons/filledButton/FilledButton";
import { useWindowScroll, useWindowSize } from "@uidotdev/usehooks";
import StickySearchbar from "../../../components/molecules/stickySearchbar/StickySearchbar";
import { fetchLexiqueCommercialApi } from "../../../api/ToolsApi";
import AppContext from "../../../context/AppContext";
import { getPrettyText } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";

function Glossary() {
  const [definitions, setDefinitions] = useState([]);
  const [displayedDefinitions, setDisplayedDefinitions] = useState([]);
  const [indexes, setIndexes] = useState([]);
  const [indexesHeight, setIndexesHeight] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [resetSearchValueTrigger, setResetSearchValueTrigger] = useState(false);
  const [emptyResult, setEmptyResult] = useState(false);

  const { width } = useWindowSize();
  const [windowScroll] = useWindowScroll();
  const { setAppLoaderVisible } = useContext(AppContext);
  const navigate = useNavigate();
  const lexiqueContainerRef = useRef(null);

  useEffect(() => {
    setAppLoaderVisible(true);
    fetchDefinitions();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [indexesHeight]);

  useEffect(() => {
    setIndexes(getIndexesFromDefinitions());
  }, [displayedDefinitions]);

  useEffect(() => {
    setIndexHeight();
    checkIfEmptyResult();
  }, [indexes]);

  async function fetchDefinitions() {
    try {
      let definitions = (await fetchLexiqueCommercialApi()).data;
      definitions = definitions.map(definition => {
        definition.name = getPrettyText(definition.name);
        definition.content = <p dangerouslySetInnerHTML={{ __html: definition.content }}></p>;
        return definition;
      });
      definitions = sortDefinitions(definitions);

      setDefinitions(definitions);
      setDisplayedDefinitions(definitions);

      setAppLoaderVisible(false);
    } catch (error) {
      navigate("/404");
    }
  }

  function checkIfEmptyResult() {
    let tmp = true;
    indexes.map(index => (getDefinitionsByIndex(index.letter).length ? (tmp = false) : null));

    setEmptyResult(tmp);
  }

  function sortDefinitions(definitions) {
    definitions.sort((a, b) => {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();

      if (a > b) return 1;
      if (a === b) return 0;
      if (a < b) return -1;
    });

    return definitions;
  }

  function handleScroll() {
    const tmp = indexesHeight.indexOf(
      indexesHeight.filter(index => {
        return index.top - 60 <= window.scrollY && index.bottom - 60 >= window.scrollY;
      })[0],
    );

    if (tmp !== activeIndex) setActiveIndex(tmp);
  }

  function handleSearchbarChange(value) {
    if (value.length < 1) setDisplayedDefinitions(definitions);
    else
      setDisplayedDefinitions(
        definitions.filter(definition => definition.name.toLowerCase().includes(value.toLowerCase())),
      );

    if (windowScroll.y > 500) window.scrollTo(0, 0);
  }

  function setIndexHeight() {
    const children = Array.from(lexiqueContainerRef.current.children);
    children.pop();
    let tmp = [];

    for (let i = 0; i < children.length; i++) {
      if (children[i].localName === "div") {
        tmp.push({
          top: children[i].offsetTop - 90,
        });
      }
      for (let i = 0; i < tmp.length; i++) {
        tmp[i].bottom = tmp[i + 1]
          ? tmp[i + 1].top - 1
          : children[children.length - 1].offsetTop + children[children.length - 1].offsetHeight;
      }
    }
    if (tmp !== activeIndex) setIndexesHeight(tmp);
  }

  function getIndexesFromDefinitions() {
    let data = [];

    definitions.forEach(definition => {
      const letter = definition.name.charAt(0);

      if (!data.includes(letter)) {
        data.push(letter);
      }
    });

    data.map((letter, index) => {
      data[index] = {
        letter: letter,
        id: index,
        index: index,
      };
    });

    return data;
  }

  function getDefinitionsByIndex(index) {
    return displayedDefinitions
      .filter(definition => definition.name.charAt(0) == index)
      .map(def => {
        return { title: def.name, content: def.content };
      });
  }

  const LetterComponent = ({ letter, id, index }) => {
    return (
      <p>
        <HashLink
          smooth
          onClick={() => setResetSearchValueTrigger(value => !value)}
          to={"#" + id}
          className={"letter-component " + index}>
          {letter}
        </HashLink>
      </p>
    );
  };

  return (
    <div className='page-container'>
      <section className='container'>
        <Breadcrumbs
          routes={[
            {
              name: "Accueil",
              to: "/",
            },
            {
              name: "Outils: Lexique commercial",
              to: "/lexique-commercial",
            },
          ]}
        />
        <Banner
          title='Lexique commercial'
          subtitle="Notre petit lexique des mots barbares à l'usage des (non)initiés"
        />
      </section>
      <div>
        <ComponentsRow
          margin='0 auto'
          Component={LetterComponent}
          className='letter-component-container mb-sm'
          componentsProps={indexes}
          contentType='letters'
          slideLength={4}
        />
        <StickySearchbar resetValueTrigger={resetSearchValueTrigger} onChange={handleSearchbarChange} />
        <section className='container' ref={lexiqueContainerRef}>
          {indexes.map((index, key) => {
            return getDefinitionsByIndex(index.letter).length ? (
              <Fragment key={key}>
                <TextHr fontSize={width > 576 ? "3rem" : "2.5rem"} className='lexique-hr' id={index.id}>
                  {index.letter}
                </TextHr>
                <ExpendableGroup data={getDefinitionsByIndex(index.letter)} />
              </Fragment>
            ) : null;
          })}
          {emptyResult && (
            <p className='text-xl text-center outfit-semibold'>Aucun résultat ne correspond à votre recherche</p>
          )}
          <FilledButton to='/debut-parcours' className='mid-page-button'>
            Je démarre mon estimation
          </FilledButton>
        </section>
      </div>
    </div>
  );
}

export default Glossary;
