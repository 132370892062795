import React, { useEffect, useContext, useState } from "react";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import { useForm } from "react-hook-form";
import { max2250Validation, numberValidation } from "../../../../../utils/formValidation/FormValidation";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea.js";
import { formatObjectForPosting, removeSpaces } from "../../../../../utils/Utils.js";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto.js";

function SituationLocative({ index }) {
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const inputForm = { register, setValue, watch, unregister };
  const {
    step,
    moveToNextStep,
    handleTabs,
    showTooltip,
    setStep,
    submitFormsMc,
    postValues,
    getStepToGo,
    setGoToStep,
    estimationValues,
  } = useContext(ParcoursContext);
  const [situationLocative, setSituationLocative] = useState(false);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    setValue("ca_dernier_occupant", estimationValues?.ca_dernier_occupant + "");
    setValue("notes.note_step_4", estimationValues?.notes?.note_step_4);

    setSituationLocative(estimationValues?.situation_locative?.occupation_local?.id === 1 ? true : false);
  }, [estimationValues]);

  async function formSubmit(values) {
    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
        <fieldset className='borderless-fieldset'>
          <h2>
            {situationLocative
              ? "chiffre d'affaires de l'occupant"
              : "chiffre d'affaires du dernier occupant (ou ca previsionnel)"}
          </h2>
          <NumberInput
            useForm={inputForm}
            tip='Cette saisie est optionnelle, si vous ne connaissez pas le CA, indiquez 0'
            label={
              <>
                veuillez renseigner le chiffre d'affaires annuel ht
                {situationLocative
                  ? " réalisé par l'exploitant"
                  : " du dernier occupant (le cas échéant un ca prévisionnel réaliste)"}
                <Tuto onClick={() => showTooltip("step_4_ca_dernier_occupant.webp")} />
              </>
            }
            name='ca_dernier_occupant'
            icon='euro'
            error={errors?.ca_dernier_occupant?.message}
          />
          <Textarea
            useForm={inputForm}
            name='notes.note_step_4'
            validation={max2250Validation}
            error={errors?.notes?.note_step_4?.message}
            className='mt-md'
            label='notes et remarques'
            {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
          />
        </fieldset>
      </form>
    </>
  );
}

export default SituationLocative;
