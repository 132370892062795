import { useWindowScroll } from "@uidotdev/usehooks";
import Searchbar from "../searchbar/Searchbar";
import "./StickySearchbar.css";
import React, { useEffect, useState } from "react";
import FilledButton from "../buttons/filledButton/FilledButton";

function StickySearchbar({
  onChange = () => {},
  placeholder = "",
  bgColor = "var(--beige)",
  margin = "0 0 10px",
  resetValueTrigger = false,
  showFilterButton = false,
  filterValues = [],
  onFilterSelect = () => {},
  selectedFilter = "",
}) {
  const [searchbarTop, setSearchbarTop] = useState(-70);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  const [windowScroll] = useWindowScroll();

  useEffect(() => {
    handleScrollChange();
  }, [windowScroll]);

  function handleScrollChange() {
    if (Math.abs(windowScroll.y - lastScrollPosition) < 15) return;

    setSearchbarTop(windowScroll.y < lastScrollPosition ? "0" : "-70px");
    setLastScrollPosition(windowScroll.y);
  }

  return (
    <div className='sticky-searchbar-container' style={{ "--top": searchbarTop, "--margin": margin }}>
      <Searchbar
        resetValueTrigger={resetValueTrigger}
        topTreshold={20}
        onChange={onChange}
        placeholder={placeholder}
        bgColor={bgColor}
      />
      <label htmlFor='category-select' className='searchbar-filter-container'>
        <div className='searchbar-filter-label'>Trier:</div>
        <select
          id='category-select'
          value={selectedFilter}
          onChange={e => onFilterSelect(e.target.value)}
          className='searchbar-filter-select'>
          <option value=''>Tous</option>
          {filterValues.map(filter => (
            <option value={filter.value} key={filter.id}>
              {filter.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
}

export default StickySearchbar;
