import React from "react";
import "./FilledButton.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { fetchDownloadableFileApi } from "../../../../api/ToolsApi";

function FilledButton({
  children,
  bgColor = "var(--blue)",
  color = "var(--white)",
  fontSize = "1rem",
  padding = "5px 20px",
  onClick,
  to,
  type = "button",
  className = "",
  target = "_self",
  rel = "",
  hoverColor = "var(--blue)",
  borderRadius = "10px",
  disabled = false,
  isLoading,
  download = false,
}) {
  async function downloadFile(e) {
    e.preventDefault();

    try {
      const response = await fetchDownloadableFileApi(to);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "CONDUIRE_UN_ENTRETIEN_AVEC_L_EXPLOITANT_D_UNE_AFFAIRE.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {}
  }

  return (
    <>
      {to && !download ? (
        to.includes("#") ? (
          <HashLink
            smooth
            className={`button filled-button ${className} ${disabled ? "disabled" : ""}`}
            style={{
              "--background-color": bgColor,
              "--color": color,
              "--hover-color": hoverColor,
              "--font-size": fontSize,
              "--padding": padding,
              "--border-radius": borderRadius,
            }}
            onClick={e => {
              if (!disabled) {
                onClick && onClick(e);
              }
            }}
            to={to}
            target={target}>
            {children}
          </HashLink>
        ) : (
          <Link
            className={`button filled-button ${className} ${disabled ? "disabled" : ""}`}
            style={{
              "--background-color": disabled ? "var(--grey)" : bgColor,
              "--font-size": fontSize,
              "--hover-color": disabled ? "var(--grey)" : hoverColor,
              "--color": color,
              "--padding": padding,
              "--border-radius": borderRadius,
            }}
            onClick={e => {
              if (!disabled) {
                onClick && onClick(e);
              }
            }}
            to={to}
            rel={rel}
            target={target}>
            {children}
          </Link>
        )
      ) : (
        <button
          type={type}
          className={`button filled-button ${className} ${disabled ? "disabled" : ""} ${isLoading ? "loading" : ""}`}
          style={{
            "--background-color": disabled ? "grey" : bgColor,
            "--font-size": fontSize,
            "--hover-color": disabled ? "grey" : hoverColor,
            "--color": color,
            "--padding": padding,
            "--border-radius": borderRadius,
          }}
          onClick={e => {
            if (!disabled) {
              onClick && !isLoading && onClick(e);
              download && downloadFile(e);
            }
          }}
          disabled={disabled}>
          {children}
          <div className={`button-loader-container ${isLoading ? "" : "hidden"}`}>
            <div className='button-loader' />
          </div>
        </button>
      )}
    </>
  );
}

export default FilledButton;
