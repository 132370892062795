import React, { useContext, useEffect } from "react";
import Input from "../../../../molecules/formComponents/input/Input";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import AppContext from "../../../../../context/AppContext";
import { defaultValidation } from "../../../../../utils/formValidation/FormValidation";
import { useForm } from "react-hook-form";
import { duplicateFolderApi } from "../../../../../api/FolderApi";

function DuplicateFolderModal({ folderId, defaultValue, fetchFoldersFunction = async () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);

  useEffect(() => {
    setValue("Nom du dossier", defaultValue);
  }, [defaultValue]);

  async function duplicateFolder(values) {
    try {
      await duplicateFolderApi(folderId, values.folder_name);
      await fetchFoldersFunction();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la duplication de votre dossier. Veuillez réessayer plus tard</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
    setModalVisible(false);
  }
  return (
    <form onSubmit={handleSubmit(duplicateFolder)}>
      <Input
        validation={defaultValidation}
        useForm={{ register, setValue }}
        error={errors?.folder_name?.message}
        name='folder_name'
        label='Nom du dossier'
      />
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Dupliquer l'estimation
        </FilledButton>
      </div>
    </form>
  );
}

export default DuplicateFolderModal;
