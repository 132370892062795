import React from "react";
import { ReactComponent as Icon } from "../../../../../../assets/homepage/icons/guideline/watcher.svg";

function Watcher({ width = "40px", color = "#7A6966", className }) {
  return (
    <Icon style={{ "--color": color, "fill": "var(--color)" }} width={width} height={width} className={className} />
  );
}

export default Watcher;
