import { useWindowSize } from "@uidotdev/usehooks";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAllNewsApi } from "../../../api/NewsApi";
import NewsAvis from "../../../components/forms/newsForm/NewsAvis";
import NewsProposal from "../../../components/forms/newsForm/NewsProposal";
import Banner from "../../../components/molecules/banner/Banner";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import FiltersGroup from "../../../components/organisms/filtersGroup/FiltersGroup";
import NewsList from "../../../components/organisms/newsList/NewsList";
import AppContext from "../../../context/AppContext";
import FilledButton from "../../../components/molecules/buttons/filledButton/FilledButton";
import { useAuth } from "../../../context/AuthContext";
import "./News.css";

function News() {
  const [news, setNews] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [currentTab, setCurrentTab] = useState(0);
  const [activeTabIndexChanger, setActiveTabIndexChanger] = useState(undefined);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { tabParam } = useParams();
  const navbarRef = useRef();
  const { setModalContent, setModalVisible, setCanHideOnClickOutside } = useContext(AppContext);
  const { canAccess, getUserId } = useAuth();

  useEffect(() => {
    async function fetchNews() {
      try {
        const response = await fetchAllNewsApi();
        setNews(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des nouveautés:", error);
      }
    }

    fetchNews();
  }, []);

  useEffect(() => {
    let tab = 0;
    switch (tabParam) {
      case "nouveautés":
        tab = 0;
        break;
      case "soumettre une proposition":
        tab = 1;
        break;
    }

    setCurrentTab(tab);
    setActiveTabIndexChanger(tab);
  }, [tabParam]);

  const handleCategoryChange = event => {
    setSelectedCategory(event.target.value);
  };

  const filteredNews = selectedCategory ? news.filter(nouveaute => nouveaute.news_tag === selectedCategory) : news;

  const handleOpenModal = newsId => {
    setModalContent({
      title: "Donner un avis",
      content: <NewsAvis newsId={newsId} onSubmit={handleSubmitAvis} onClose={handleCloseModal} />,
      buttons: null,
    });
    setCanHideOnClickOutside(true);
    setModalVisible(true);
  };

  const handleCloseModal = () => setModalVisible(false);

  const handleSubmitAvis = async data => {
    try {
      setModalVisible(false);
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire :", error);
    }
  };

  return (
    <>
      <div className='page-container'>
        <section className='container'>
          <Breadcrumbs
            routes={[
              { to: "/", name: "Accueil" },
              { to: "/nouveautes", name: "Nouveautés" },
            ]}
          />
          <Banner title='Nouveautés' />
          <p>
            Découvrez les nouveautés et améliorations apportées par notre équipe et n’hésitez pas à nous donner votre
            avis.
          </p>
        </section>
        <div className='sticky-container' ref={navbarRef}>
          <FiltersGroup
            changeActiveTabIndex={activeTabIndexChanger}
            data={[
              {
                name: "Nouveautés",
                onClick: setCurrentTab,
                params: [0],
              },
              {
                name: "Soumettre une proposition",
                onClick: setCurrentTab,
                params: [1],
              },
            ]}
          />
        </div>
        <section className='container'>
          {currentTab === 0 && (
            <>
              <div className='centered'>
                <div className='dropdown'>
                  <label htmlFor='category-select' className='dropdown-label'>
                    Trier:
                  </label>
                  <select
                    id='category-select'
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    className='dropdown-select'>
                    <option value=''>Tous</option>
                    <option value='add'>Nouveau</option>
                    <option value='fix'>Amélioration</option>
                    <option value='update'>Mise à jour</option>
                  </select>
                </div>
              </div>
              {(canAccess("admin") || canAccess("dev")) && (
                <div className='centered'>
                  <FilledButton className='add-news-button' color='var(--white)' onClick={() => navigate(`/news-add`)}>
                    Ajouter une nouveauté
                  </FilledButton>
                </div>
              )}
              <div className='gold-before-section text-lg'>
                <div className='nouveautes-list'>
                  <NewsList
                    filteredNews={filteredNews}
                    visibleCount={visibleCount}
                    setVisibleCount={setVisibleCount}
                    onOpenModal={handleOpenModal}
                  />
                </div>
              </div>
            </>
          )}
          {currentTab === 1 && <NewsProposal />}
        </section>
      </div>
    </>
  );
}

export default News;
