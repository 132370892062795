import React, { useState, useEffect, useContext } from "react";
import "./FolderDetail.css";
import { archiveFolderApi } from "../../../../../api/FolderApi";
import AppContext from "../../../../../context/AppContext";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import FolderInfo from "./tabs/folderInfo/FolderInfo";
import FolderEstimations from "./tabs/folderEstimations/FolderEstimations";
import FolderPlanFinancement from "./tabs/folderPlanFinancement/FolderPlanFinancement";
import FolderDeliverables from "./tabs/folderDeliverables/FolderDeliverables";

function FolderDetail({ folder, onClose, updateFolderInState, getUuid }) {
  const { createNotification } = useContext(AppContext);
  const [localFolder, setLocalFolder] = useState(folder);
  const [selectedTab, setSelectedTab] = useState("info");

  useEffect(() => {
    setLocalFolder(folder);
  }, [folder]);

  async function archiveFolder(folderId, archive) {
    try {
      await archiveFolderApi(folderId, archive);
      updateFolderInState({
        ...localFolder,
        archived: archive,
      });
      createNotification(
        <>Le dossier a été {archive ? "archivé" : "désarchivé"} avec succès</>,
        "var(--green)",
        "var(--dark-blue)",
      );
      onClose();
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors {archive ? "de l'archivage" : "du désarchivage"} du dossier. Veuillez réessayer
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function updateFolderInState(updatedFolder) {
    setLocalFolder(updatedFolder);
  }

  function renderTabContent() {
    switch (selectedTab) {
      case "info":
        return <FolderInfo folder={localFolder} updateFolderInState={updateFolderInState} />;
      case "estimations":
        return <FolderEstimations folder={localFolder} updateFolderInState={updateFolderInState} getUuid={getUuid} />;
      case "planFinanement":
        return <FolderPlanFinancement />;
      case "livrables":
        return <FolderDeliverables folder={localFolder} updateFolderInState={updateFolderInState} />;
      default:
        return null;
    }
  }

  return (
    <section className='container'>
      <div className='folder-detail-page-container'>
        <div className='folder-sidebar'>
          <div className='folder-header-sidebar'>
            <h2>{folder?.folder_name}</h2>
          </div>
          <ul className='folder-tabs'>
            <li
              className={`folder-tab ${selectedTab === "info" ? "active" : ""}`}
              onClick={() => setSelectedTab("info")}>
              Informations
            </li>
            <li
              className={`folder-tab ${selectedTab === "estimations" ? "active" : ""}`}
              onClick={() => setSelectedTab("estimations")}>
              Estimations
            </li>
            <li
              className={`folder-tab ${selectedTab === "planFinanement" ? "active" : ""}`}
              onClick={() => setSelectedTab("planFinanement")}>
              Plan de financement
            </li>
            <li
              className={`folder-tab ${selectedTab === "livrables" ? "active" : ""}`}
              onClick={() => setSelectedTab("livrables")}>
              Livrables
            </li>
          </ul>
          <div className='folder-actions'>
            <FilledButton className='action-button-folder'>Télécharger le dossier</FilledButton>
            <UnfilledButton
              className='action-button-folder'
              bgColor='var(--dark-red)'
              onClick={() => archiveFolder(localFolder.folder_id, !localFolder.archived)}>
              {localFolder.archived ? "Désarchiver le dossier" : "Archiver le dossier"}
            </UnfilledButton>
            <UnfilledButton className='action-button-folder' onClick={onClose}>
              Retour aux dossiers
            </UnfilledButton>
          </div>
        </div>
        <div className='folder-content'>{renderTabContent()}</div>
      </div>
    </section>
  );
}

export default FolderDetail;
