import React from "react";
import { ReactComponent as Icon } from "../../../../../../assets/homepage/icons/guideline/balance.svg";

function Balance({ width = "40px", color = "#7B624A", className }) {
  return (
    <Icon style={{ "--color": color, "fill": "var(--color)" }} width={width} height={width} className={className} />
  );
}

export default Balance;
