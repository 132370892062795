import React, { useContext, useEffect, useState } from "react";
import "./FolderInfo.css";
import { useForm } from "react-hook-form";
import { defaultValidation, max2250Validation } from "../../../../../../../utils/formValidation/FormValidation";
import { updateFolderApi } from "../../../../../../../api/FolderApi";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Input from "../../../../../../../components/molecules/formComponents/input/Input";
import Textarea from "../../../../../../../components/molecules/formComponents/textarea/Textarea";
import AppContext from "../../../../../../../context/AppContext";
import Tuto from "../../../../../../../components/atoms/icons/general/tuto/Tuto";

function FolderInfo({ folder, updateFolderInState, showTooltip = () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { createNotification } = useContext(AppContext);

  useEffect(() => {
    if (folder) {
      setValue("folder_name", folder.folder_name);
      setValue("description", folder.description);
    }
  }, [folder, setValue]);

  async function updateFolderInfo(data) {
    try {
      await updateFolderApi(folder.folder_id, 1, data.folder_name, data.description);

      updateFolderInState({
        ...folder,
        folder_name: data.folder_name,
        description: data.description,
      });

      createNotification(<>Le dossier a été mis à jour avec succès</>, "var(--green)", "var(--dark-blue)");
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la mise à jour du dossier</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function getFolderCreationDate(folder) {
    return folder.date_creation.substring(0, 10);
  }

  return (
    <form onSubmit={handleSubmit(updateFolderInfo)}>
      <div className='tab-content-header'>
        <h2>
          Informations
          <span className='tuto-icon'>
            <Tuto onClick={() => showTooltip("")} />
          </span>
        </h2>
        <p>Retrouvez ici toutes les informations relatives à votre dossier.</p>
      </div>
      <div>
        <Input
          label='Nom du dossier'
          validation={defaultValidation}
          useForm={{ register, setValue }}
          error={errors.folder_name?.message}
          name='folder_name'
        />
        <Textarea
          label='Description du dossier'
          validation={max2250Validation}
          useForm={{ register, setValue }}
          error={errors.description?.message}
          name='description'
        />
        <p>Créé le {getFolderCreationDate(folder)}</p>
      </div>
      <div className='folder-info-buttons'>
        <FilledButton type='submit'>Sauvegarder</FilledButton>
      </div>
    </form>
  );
}

export default FolderInfo;
