import React, { useRef, useEffect } from "react";
import { defaultValidation } from "../../../../utils/formValidation/FormValidation.js";
import "./Radio.css";
import Warning from "../../../atoms/icons/general/warning/Warning";
import { toggleCollapseElement } from "../../../../utils/Utils.js";

// direction : row / column
// useForm = {
//   register: () => {},
//   unregister: () => {},
//   setValue: () => {},
// }
function Radio({
  useForm = {},
  label,
  name,
  content,
  error,
  defaultChecked,
  visible = true,
  direction = "row",
  padding = ".5rem",
  onChange = () => {},
  onKeyDown = () => {},
  onFocus = () => {},
  validation = defaultValidation,
  className = "",
}) {
  const { register = () => {}, unregister = () => {}, setValue = () => {} } = useForm;
  const ref = useRef(null);

  useEffect(() => {
    toggleCollapseElement(ref.current, visible, 40);

    if (!visible) {
      unregister(name, { keepValue: true });
    }
  }, [visible]);

  let rows = [];
  content.map((row, key) => {
    rows.push(
      <div key={key}>
        <input
          {...(visible && register(name, validation))}
          className='input-radio'
          onClick={() => {
            setValue(name, row.value + "");
          }}
          type='radio'
          id={name + key}
          onChange={onChange}
          value={row.value}
          defaultChecked={defaultChecked !== null && key === defaultChecked ? true : false}
        />
        <label key={key} htmlFor={name + key} className='radio-container' style={{ "--padding": padding }}>
          <p className='radio-label'>{row.label}</p>
        </label>
      </div>,
    );
  });

  return (
    <div className={`w-100 ${className}`} ref={ref}>
      <div
        className={`field-container radio-field-container ${error ? "with-error" : ""}`}
        onKeyDown={onKeyDown}
        onFocus={onFocus}>
        <label className='form-label'>{label}</label>
        <span className='input-container'>
          <div className='radio-group' style={{ "--direction": direction }}>
            {rows}
          </div>
          {error && (
            <div className='form-error'>
              <Warning /> <p>{error}</p>
            </div>
          )}
        </span>
      </div>
    </div>
  );
}

export default Radio;
