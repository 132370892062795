import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/parcours/cursor.svg";

function Cursor({ width = "20px", onClick, color = "var(--white)", className = "" }) {
  return (
    <Icon
      width={width}
      height={width}
      onClick={onClick}
      style={{ "--color": color, "fill": "var(--color)" }}
      className={className + (onClick ? " clickable-icon" : "")}
    />
  );
}

export default Cursor;
