import "./DoubleInput.css";
import React, { useEffect, useRef } from "react";
import { numberValidation } from "../../../../utils/formValidation/FormValidation.js";
import "../caseInput/CaseInput.css";
import { formatNumberWithSpaces, removeSpacesFromEvent, toggleCollapseElement } from "../../../../utils/Utils.js";
import Warning from "../../../atoms/icons/general/warning/Warning.js";

// useForm = {register: register, watch: watch, setValue: setValue}
// icon : euro / percent / search
function DoubleInput({
  name,
  name1,
  placeholder1,
  defaultValue,
  validation = numberValidation,
  icon = "euro",
  visible,
  onChange,
  onChange1,
  zeroByDefault = true,
  onFocus,
  disabled,
  onKeyDown,
  useForm,
  value,
  error,
  className = "",
}) {
  const inputRegister = useForm.register && (visible || visible === undefined) ? useForm.register(name) : null;
  const containerRef = useRef(null);
  const listener = useForm.watch ? useForm.watch(name) : null;

  const inputRegister1 =
    useForm.register && (visible || visible === undefined) ? useForm.register(name1, validation) : null;

  useEffect(() => {
    if (listener) useForm.setValue(name, formatNumberWithSpaces(listener));
  }, [listener]);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseElement(containerRef.current, visible);
  }, [visible]);

  function setZero(e) {
    if (e.target.value === "" && useForm.setValue && zeroByDefault) useForm.setValue(name, 0);
  }

  function resetValue(e) {
    if (e.target.value === "0" && useForm.setValue) useForm.setValue(name, "");
  }

  return (
    <>
      <div className={`w-100 d-flex ${className}`} ref={containerRef}>
        <div className={`case-input-container double-input-container ${error ? "with-error" : ""}`}>
          <input
            maxLength={250}
            className='first-input'
            disabled={(visible !== undefined && !visible) || disabled}
            id={name1}
            {...inputRegister1}
            placeholder={placeholder1}
            onChange={e => {
              inputRegister && inputRegister.onChange(e);
              onChange1 && onChange1(e);
            }}
          />
          <span className={"before-" + icon}>
            <input
              disabled={(visible !== undefined && !visible) || disabled}
              id={name}
              value={value !== undefined ? value : undefined}
              {...inputRegister}
              onChange={e => {
                useForm.setValue && useForm.setValue(name, formatNumberWithSpaces(e.target.value));
                inputRegister && inputRegister.onChange(removeSpacesFromEvent(e));
                onChange && onChange(removeSpacesFromEvent(e));
              }}
              onFocus={e => {
                resetValue(e);
                onFocus && onFocus(e);
              }}
              defaultValue={defaultValue}
              onBlur={setZero}
              onKeyDown={onKeyDown}
            />
            {error && (
              <div className='form-error'>
                <Warning /> <p>{error}</p>
              </div>
            )}
          </span>
        </div>
      </div>
    </>
  );
}

export default DoubleInput;
