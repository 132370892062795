import { useEffect } from "react";
import ProgressionMeterSVG from "../../atoms/icons/account/progressionMeterSVG/ProgressionMeterSVG";
import "./ProgressionMeter.css";
import React from "react";
import { useState } from "react";
import { sleep } from "../../../utils/Utils";

function ProgressionMeter({ percentage = 0, className = "" }) {
  const [rotation, setRotation] = useState(0);
  const [animatedPercentage, setAnimatedPercentage] = useState(percentage);

  useEffect(() => {
    updatePercentage(percentage);
  }, [percentage]);

  useEffect(() => {
    calcRotation();
  }, [animatedPercentage]);

  async function updatePercentage() {
    const increment = percentage > animatedPercentage ? 0.25 : -0.25;
    for (let i = animatedPercentage; increment > 0 ? i <= percentage : i >= percentage; i += increment) {
      await sleep(10);
      setAnimatedPercentage(Math.floor(i));
    }
  }

  function calcRotation() {
    setRotation((animatedPercentage / 100) * 197 - 98);
  }

  return (
    <div className={"progressison-meter-container" + className}>
      <ProgressionMeterSVG rotation={rotation + "deg"} />
      <div className='progression-percentage'>{animatedPercentage}%</div>
    </div>
  );
}

export default ProgressionMeter;
