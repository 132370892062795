import React, { useEffect, useRef } from "react";
import "./CaseInput.css";
import { formatNumberWithSpaces, removeSpacesFromEvent, toggleCollapseElement } from "../../../../utils/Utils.js";
import Warning from "../../../atoms/icons/general/warning/Warning.js";

// useForm = {
//   register: () => {},
//   unregister: () => {},
//   watch: () => {},
//   setValue: () => {},
// }
// icon : euro / percent / search
function CaseInput({
  label,
  name,
  validation = "",
  icon = "euro",
  visible = true,
  onChange = () => {},
  zeroByDefault = true,
  onFocus = () => {},
  disabled,
  onKeyDown = () => {},
  useForm = {},
  value,
  color = "var(--dark-blue)",
  tip,
  error,
  className = "",
}) {
  const { register = () => {}, unregister = () => {}, setValue = () => {}, watch = () => {} } = useForm;
  const containerRef = useRef(null);
  const listener = watch ? watch(name) : null;

  useEffect(() => {
    if (listener) setValue(name, formatNumberWithSpaces(listener));
  }, [listener]);

  useEffect(() => {
    toggleCollapseElement(containerRef.current, visible, 0);

    if (!visible) {
      unregister(name, { keepValue: true });
    }
  }, [visible]);

  function setZero(e) {
    if (e.target.value === "" && setValue && zeroByDefault) setValue(name, 0);
  }

  function resetValue(e) {
    if (e.target.value === "0" && setValue) setValue(name, "");
  }

  return (
    <>
      <div className={`w-100 d-flex ${className}`} ref={containerRef}>
        <div className={`case-input-container ${error ? "with-error" : ""}`}>
          <label className='case-input-label' style={{ "--color": color }} htmlFor={name}>
            {label} <p className={`label-tip ${tip ? "" : " d-none"}`}>{tip}</p>
          </label>
          <span className={`before-${icon}`}>
            <input
              {...(visible && register(name, validation))}
              disabled={(visible !== undefined && !visible) || disabled}
              id={name}
              value={value !== undefined ? value : undefined}
              onChange={e => {
                setValue && setValue(name, formatNumberWithSpaces(e.target.value));
                onChange && onChange(removeSpacesFromEvent(e));
              }}
              onFocus={e => {
                resetValue(e);
                onFocus && onFocus(e);
              }}
              onBlur={setZero}
              onKeyDown={onKeyDown}
            />
            {error && (
              <div className='form-error'>
                <Warning /> <p>{error}</p>
              </div>
            )}
          </span>
        </div>
      </div>
    </>
  );
}

export default CaseInput;
