import React from "react";

const LikeButton = ({ isLiked, onClick, disabled, likeImage, unlikeImage }) => {
  return (
    <div className={`like-button ${disabled ? "disabled" : ""}`} onClick={disabled ? undefined : onClick}>
      {isLiked ? likeImage : unlikeImage}
    </div>
  );
};

export default LikeButton;
