import "./PageTitle.css";
import React from "react";

function PageTitle({ title, className = "", margin = "20px auto 0" }) {
  return (
    <div className={"page-title-container " + className} style={{ "--margin": margin }}>
      <h1 className='outfit-bold'>{title}</h1>
    </div>
  );
}

export default PageTitle;
