import "./VisitingCard.css";
import React, { useEffect } from "react";
import Phone from "../../atoms/icons/general/phone/Phone";
import Email from "../../atoms/icons/general/email/Email";
import Location from "../../atoms/icons/general/location/Location";

function VisitingCard({
  card_type = "created_card",
  avatar_path = "",
  first_name = "",
  position = "",
  slogan = "",
  mobile_phone = "",
  fixed_phone = "",
  email = "",
  primary_address = "",
  secondary_address = "",
  zip_code = "",
  city = "",
  company = "",
  website = "",
  rsac = "",
  siret = "",
  logo_path = "",
  last_name = "",
  id = "",
  card_path = "",
  className = "",
}) {
  useEffect(() => {}, [card_path, logo_path, avatar_path]);
  return (
    <>
      {card_type === "created_card" ? (
        <div className={`visiting-card-container ${className}`}>
          <div className='card-top'>
            {avatar_path && (
              <img
                src={avatar_path}
                alt='avatar_path'
                id={"avatar_path-img-" + id}
                className={"card-img " + (avatar_path === "" ? "d-none" : "")}
              />
            )}
            <div>
              <p className='card-name'>
                <span>{first_name}</span>&nbsp;
                <span>{last_name}</span>
              </p>
              <p className='card-position'>{position}&nbsp;</p>
              <p className='card-slogan'>{slogan}&nbsp;</p>
            </div>
          </div>
          <div className='card-mid'>
            <div className='card-mid-content'>
              {(mobile_phone || fixed_phone) && <Phone />}
              <p>
                <span>{mobile_phone}</span>
                {mobile_phone && fixed_phone && " - "}
                <span>{fixed_phone}</span>&nbsp;
              </p>
            </div>
            <div className='card-mid-content'>
              {email && <Email />}
              <p>{email}&nbsp;</p>
            </div>

            <div className='card-mid-content'>
              {(primary_address || secondary_address || zip_code || city) && <Location />}
              <div>
                <p>{primary_address}&nbsp;</p>
                <p>{secondary_address}&nbsp;</p>
                <p>
                  {zip_code && <span>{zip_code}&nbsp;</span>}
                  <span>{city}</span>&nbsp;
                </p>
              </div>
            </div>
          </div>
          <div className='card-bottom'>
            <div>
              <p className='card-company'>{company}&nbsp;</p>
              <p>{website}&nbsp;</p>
              <p>{rsac ? "RSAC : " + rsac : siret ? "Siret : " + siret : ""}&nbsp;</p>
            </div>
            {logo_path && (
              <img
                src={logo_path}
                alt='Logo'
                id={"logo-img-" + id}
                className={"card-img " + (logo_path === "" ? "d-none" : "")}
              />
            )}
          </div>
        </div>
      ) : (
        card_path && <img className={`imported-card-container ${className}`} alt='carte importée' src={card_path} />
      )}
    </>
  );
}

export default VisitingCard;
