import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/general/commerce.svg";

function Commerce({ width = "20px", color = "var(--gold)", className = "", onClick }) {
  return (
    <Icon
      style={{
        "--color": color,
      }}
      fill='var(--color)'
      width={width}
      height={width}
      className={className + (onClick ? " clickable-icon" : "")}
      onClick={onClick}
    />
  );
}

export default Commerce;
