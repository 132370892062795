import "./ParcoursFdc.css";
import React, { useEffect, useContext } from "react";
import ParcoursCarousel from "../../organisms/parcoursCarousel/ParcoursCarousel";
import TrouverSociete from "../../forms/parcours/fondsCommerce/step2/TrouverSociete";
import DefinitionAffaire from "../../forms/parcours/fondsCommerce/step3/DefinitionAffaire";
import BilanComptable from "../../forms/parcours/fondsCommerce/step4/BilanComptable";
import ImpotsTaxes from "../../forms/parcours/fondsCommerce/step5/ImpotsTaxes";
import ChargesRedevance from "../../forms/parcours/fondsCommerce/step6/ChargesRedevances";
import Retraitements from "../../forms/parcours/fondsCommerce/step7/Retraitements";
import Emplacement from "../../forms/parcours/fondsCommerce/step8/Emplacement";
import CaracteristiquesLocalFdc from "../../forms/parcours/fondsCommerce/step9/CaracteristiquesLocalFdcTds";
import LoyerBail from "../../forms/parcours/fondsCommerce/step10/LoyerBail";
import NormesAccessibilite from "../../forms/parcours/fondsCommerce/step11/NormesAccessibilite";
import RatioClefs from "../../forms/parcours/fondsCommerce/step12/RatioClefs";
import Reputation from "../../forms/parcours/fondsCommerce/step13/Reputation";
import { ParcoursContext } from "../../../context/ParcoursContext";
import StarterFdc from "../../forms/parcours/fondsCommerce/step1/StarterFdc";

function ParcoursFdc() {
  const { estimationValues, setInputsNames, step, hasLocalDependance, parcoursType, setStepsSummary, CAInputs } =
    useContext(ParcoursContext);

  useEffect(() => {
    initParcours();
  }, []);

  useEffect(() => {
    setInputsNames(array => {
      array[2] = estimationValues?.step > 3 ? "infos.enseigne" : "activite";
      array[3] = parcoursType === 2 ? "bilan_comptable.immobilisations_financieres" : "ca." + CAInputs[0]?.input_name;
      array[10] = !hasLocalDependance(estimationValues)
        ? "materiel_agencements.etat_materiel.id"
        : "normes_accessibilite.accessibilite_pmr.id";
      return { ...array };
    });
  }, [estimationValues, CAInputs]);

  function setTdsFdcInputsNames() {
    setInputsNames([
      "", // starter
      estimationValues?.step > 3 ? "infos.enseigne" : "activite",
      "infos.activite",
      parcoursType === 2 ? "bilan_comptable.immobilisations_financieres" : "ca." + CAInputs[0]?.input_name,
      "impots_taxes.total_impots",
      "redevance.redevance",
      "retraitements.salaire_gerant_annuel",
      "emplacement.type_ville.id0",
      "qualite_local.largeur_facade",
      "soumission_bail_checkbox",
      !hasLocalDependance(estimationValues)
        ? "materiel_agencements.etat_materiel.id"
        : "normes_accessibilite.accessibilite_pmr.id",
      "ratios.salaries",
      "e_reputation.note_google",
    ]);
  }

  function initParcours() {
    setTdsFdcInputsNames();

    setStepsSummary([
      {
        name: "L'entreprise",
        steps: [1, 2, 3],
      },
      {
        name: "Les chiffres",
        steps: [4, 5, 6, 7],
      },
      {
        name: "L'emplacement",
        steps: [8, 9, 10, 11],
      },
      {
        name: "Le complément",
        steps: [12, 13],
      },
    ]);
  }

  return (
    <>
      <ParcoursCarousel addBottomMargin={step == 2} step={step}>
        <StarterFdc index={1} />
        <TrouverSociete index={2} />
        <DefinitionAffaire index={3} />
        <BilanComptable index={4} />
        <ImpotsTaxes index={5} />
        <ChargesRedevance index={6} />
        <Retraitements index={7} />
        <Emplacement index={8} />
        <CaracteristiquesLocalFdc index={9} />
        <LoyerBail index={10} />
        <NormesAccessibilite index={11} />
        <RatioClefs index={12} />
        <Reputation index={13} />
      </ParcoursCarousel>
    </>
  );
}

export default ParcoursFdc;
