import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../molecules/formComponents/input/Input";
import Select from "../../../molecules/formComponents/select/Select";
import { defaultValidation, emailValidation, phoneValidation } from "../../../../utils/formValidation/FormValidation";
import Checkbox from "../../../molecules/formComponents/checkbox/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import AppContext from "../../../../context/AppContext";
import { fetchUserDropdownApi, registerUserApi } from "../../../../api/AccountApi";
import PasswordInput from "../../../molecules/formComponents/passwordInput/PasswordInput";
import RegisterConfirmModal from "../../modals/account/registerConfirmModal/RegisterConfirmModal";
import { useAuth } from "../../../../context/AuthContext";

function RegisterForm() {
  const {
    register,
    getValues,
    watch,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();
  const inputForm = { register, setValue, unregister };
  const [usersTypes, setUsersTypes] = useState([]);
  const [formError, setformError] = useState(<></>);
  const { setUuid, getUuid } = useAuth();
  const { createNotification, setModalVisible, setModalContent } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    fillDropdown();
  }, []);

  async function onSubmit(values) {
    try {
      const res = await registerUserApi({
        user_type_id: values.user_type_id,
        email: values.email,
        password: values.password,
        first_name: values.first_name,
        last_name: values.last_name,
        street: values.street,
        city: values.city,
        zip_code: values.zip_code,
        phone: values.phone,
        company_name: values.company_name || null,
      });
      localStorage.setItem("temporary_uuid", res.data.uuid);
      showConfirmInscriptionModal(values.email);
    } catch (error) {
      let notificationMessage = <></>;

      switch (error.response.status) {
        case 400:
          notificationMessage = <>Tous les champs requis ne sont pas renseignés.</>;
          break;
        case 422:
          switch (error.response.data.infos.error_code) {
            case "01":
              notificationMessage = <>Votre email est incorrect.</>;
              break;
            case "01":
              notificationMessage = <>Votre numéro de téléphone est incorrect.</>;
              break;
            case "03":
              notificationMessage = (
                <>
                  Cet email est déjà utilisé.&nbsp;
                  <a href='/connexion' className='outfit-bold text-underline cursor-pointer'>
                    Me connecter
                  </a>
                </>
              );
              break;
            default:
              notificationMessage = (
                <>Une erreur est survenue lors de votre inscription. Veuillez réessayer plus tard.</>
              );
          }
          break;
        case 500:
        default:
          notificationMessage = <>Une erreur est survenue lors de votre inscription. Veuillez réessayer plus tard.</>;
      }

      setformError(notificationMessage);
      createNotification(notificationMessage, "var(--red)", "var(--dark-blue");
    }
  }

  function showConfirmInscriptionModal() {
    setModalContent({
      title: "Confirmer votre inscription",
      content: (
        <RegisterConfirmModal
          email={getValues("email")}
          password={getValues("password")}
          navigate={navigate}
          setUuid={setUuid}
          getUuid={getUuid}
        />
      ),
    });
    setModalVisible(true, false);
  }

  //TODO truc bizarre ici
  async function fillDropdown() {
    try {
      const types = (await fetchUserDropdownApi("type")).data;
      setUsersTypes(types);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de données importantes.&nbsp;
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => window.location.reload()}>
            Veuillez réessayer
          </span>
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className='borderless-fieldset full-page-form my-md'>
        <div className='row-1000'>
          <Input useForm={inputForm} error={errors?.last_name?.message} name='last_name' label='Nom&nbsp;*' />
          <Input useForm={inputForm} error={errors?.first_name?.message} name='first_name' label='Prénom&nbsp;*' />
        </div>
        <Input
          useForm={inputForm}
          validation={""}
          error={errors?.company_name?.message}
          name='company_name'
          label='Raison sociale'
        />
        <Input useForm={inputForm} error={errors?.street?.message} name='street' label='Numéro et voie&nbsp;*' />
        <div className='row-1000'>
          <Input useForm={inputForm} error={errors?.zip_code?.message} name='zip_code' label='Code postal&nbsp;*' />
          <Input useForm={inputForm} error={errors?.city && errors.city.message} name='city' label='Ville&nbsp;*' />
        </div>
        <Select
          useForm={inputForm}
          error={errors?.user_type_id?.message}
          name='user_type_id'
          label='Je suis&nbsp;*'
          children={usersTypes.map(type => (
            <option key={type.id} id={type.id} value={type.id}>
              {type.description}
            </option>
          ))}
        />
        <div className='row-1000'>
          <Input
            useForm={inputForm}
            validation={emailValidation}
            error={errors?.email?.message}
            name='email'
            label='Adresse mail&nbsp;*'
          />
          <Input
            useForm={inputForm}
            validation={phoneValidation}
            error={errors?.phone?.message}
            name='phone'
            label='Téléphone&nbsp;*'
          />
        </div>
        <div className='row-1000'>
          <PasswordInput
            useForm={inputForm}
            showTooltip
            error={errors?.password?.message}
            name='password'
            label='Mot de passe&nbsp;*'
            watch={watch}
          />
          <PasswordInput
            useForm={inputForm}
            isPasswordConfirm
            error={errors?.password_confirm?.message}
            name='password_confirm'
            label='Confirmez votre mot de passe&nbsp;*'
            watch={watch}
          />
        </div>
        <Checkbox
          validation={defaultValidation}
          useForm={inputForm}
          error={errors?.personal_data?.message}
          name='personal_data'
          label={
            <p>
              En soumettant ce formulaire, j'accepte que mes données personnelles saisies soient utilisées pour les
              besoins de la création de mon compte client et pour l’exécution des services associés.&nbsp;*
            </p>
          }
        />
        <Checkbox
          error={errors?.cgu_cgv?.message}
          useForm={inputForm}
          validation={defaultValidation}
          name='cgu_cgv'
          label={
            <p>
              En cochant cette case, je déclare avoir pris connaissance et accepter sans réserve les{" "}
              <Link to='/cgv' className='span-inherit gold-link'>
                Conditions Générales de Vente
              </Link>{" "}
              de la société GALAXICO, ainsi que ses{" "}
              <Link to='/cgu' className='span-inherit gold-link'>
                Conditions Générales d’Utilisation
              </Link>{" "}
              et sa{" "}
              <Link to='/politique-confidentialite' className='span-inherit gold-link'>
                Politique de confidentialité
              </Link>
              .&nbsp;*
            </p>
          }
        />
        <div className='form-error-container'>{formError}</div>
        <div className='form-legend'>* Obligatoire</div>
        <div className='centered'>
          <FilledButton type='submit' padding='10px 25px'>
            S'inscrire
          </FilledButton>
        </div>
      </fieldset>
    </form>
  );
}

export default RegisterForm;
