import React from "react";
import "./RoundedDiv.css";

function RoundedDiv({
  width = "100%",
  color = "var(--dark-blue)",
  className = "",
  rotation = "",
  height = "3.1vw",
  radius = "900vw",
}) {
  return (
    <div
      style={{
        "--color": color,
        "--bg-color": "var(--color)",
        "--rotate": rotation,
        "--width": width,
        "--height": height,
        "--radius": radius,
      }}
      className={"rounded-div " + className}
    />
  );
}

export default RoundedDiv;
