import { useWindowSize } from "@uidotdev/usehooks";
import React, { useEffect } from "react";
import Card from "../../cards/card/Card";
import Trash from "../../../atoms/icons/general/trash/Trash";
import FilledButton from "../../buttons/filledButton/FilledButton";
import Add from "../../../atoms/icons/general/add/Add";
import Input from "../input/Input";
import NumberInput from "../numberInput/NumberInput";
import Checkbox from "../checkbox/Checkbox";
import { formatDate, getFormattedDate, removeSpaces } from "../../../../utils/Utils";
import "../multiplesInputs/MultiplesInputs.css";

function RhMultiplesInputs({ className = "", values, setValues, name, unregister, Form, visible }) {
  const { width } = useWindowSize();

  useEffect(() => {
    if (!visible) {
      values.map(value =>
        unregister([
          `${name}.${value.index}.nom`,
          `${name}.${value.index}.poste`,
          `${name}.${value.index}.annee_entree`,
          `${name}.${value.index}.salaire_brut_annuel`,
          `${name}.${value.index}.type_contrat`,
          `${name}.${value.index}.is_key`,
        ]),
      );
      setValues([]);
    } else {
      if (!values.length) {
        addRow();
      }
    }
  }, [visible]);

  function deleteRow(index) {
    const tmp = [...values];
    tmp[index].visible = false;
    setValues(tmp);

    unregister([
      `${name}.${index}.nom`,
      `${name}.${index}.poste`,
      `${name}.${index}.annee_entree`,
      `${name}.${index}.salaire_brut_annuel`,
      `${name}.${index}.type_contrat`,
      `${name}.${index}.is_key`,
    ]);
  }

  function addRow() {
    const tmp = [...values];
    tmp.push({
      index: tmp.length,
      visible: true,
      error: { nom: "", poste: "", salaire_brut_annuel: "", annee_entree: "", type_contrat: "" },
    });
    setValues(tmp);

    setTimeout(() => {
      document.getElementById(`${name}.${tmp.length - 1}.nom`).focus();
    }, 200);
  }

  function calcInputError(e, index, field) {
    const tmp = [...values];
    let hasError = false;
    if (!e.target.value) {
      hasError = true;
    } else {
      tmp[index].error[field] = "";
    }
    setValues(tmp);
    return hasError;
  }

  return (
    <div className={`multiple-inputs-container ${className}`}>
      {values.map(
        value =>
          value.visible && (
            <Card key={value.index} bgColor='var(--light-grey)' padding='20px 20px 4px'>
              <div className='row-500'>
                <div className='row-1000'>
                  <Input
                    onBlur={e => calcInputError(e, value.index, "nom")}
                    onChange={e => calcInputError(e, value.index, "nom")}
                    useForm={Form}
                    error={values[value.index]?.error?.nom}
                    name={`${name}.${value.index}.nom`}
                    label='Nom'
                  />
                  <Input
                    onBlur={e => calcInputError(e, value.index, "poste")}
                    onChange={e => calcInputError(e, value.index, "poste")}
                    useForm={Form}
                    error={values[value.index]?.error?.poste}
                    name={`${name}.${value.index}.poste`}
                    label='Poste'
                  />
                  <NumberInput
                    onBlur={e => calcInputError(e, value.index, "salaire_brut_annuel")}
                    onChange={e => calcInputError(e, value.index, "salaire_brut_annuel")}
                    icon='euro'
                    useForm={Form}
                    error={values[value.index]?.error?.salaire_brut_annuel}
                    name={`${name}.${value.index}.salaire_brut_annuel`}
                    label='Salaire brut annuel'
                  />
                </div>
                <div className='row-1000'>
                  <Input
                    onBlur={e => calcInputError(e, value.index, "annee_entree")}
                    onChange={e => {
                      calcInputError(e, value.index, "annee_entree");
                    }}
                    type='date'
                    useForm={Form}
                    error={values[value.index]?.error?.annee_entree}
                    name={`${name}.${value.index}.annee_entree`}
                    label="Date d'entrée"
                  />
                  <Input
                    onBlur={e => calcInputError(e, value.index, "type_contrat")}
                    onChange={e => calcInputError(e, value.index, "type_contrat")}
                    useForm={Form}
                    error={values[value.index]?.error?.type_contrat}
                    name={`${name}.${value.index}.type_contrat`}
                    label='Type de contrat'
                  />
                  <Checkbox
                    className='h-100'
                    useForm={Form}
                    name={`${name}.${value.index}.is_key`}
                    label='Homme clef'
                  />
                </div>
                <div className='pb-md px-sm centered'>
                  <Trash onClick={() => deleteRow(value.index)} />
                </div>
              </div>
            </Card>
          ),
      )}
      {visible && (
        <FilledButton onClick={addRow}>
          <Add width={width > 576 ? "30px" : "20px"} /> Ajouter une ligne
        </FilledButton>
      )}
    </div>
  );
}

export default RhMultiplesInputs;

export function haveMultiplesInputsErrors(values) {
  if (values.some(dette => dette.error)) return true;
}

export function fillMultipleRhInputs(inputsValues, setValues, setState, name) {
  const state = [];
  const values = [];

  if (inputsValues)
    inputsValues.map((value, index) => {
      delete value.id;
      state.push({
        index,
        visible: true,
        error: { nom: "", poste: "", salaire_brut_annuel: "", annee_entree: "", type_contrat: "" },
      });
      values.push({ ...value, annee_entree: formatDate(value.annee_entree) });
    });

  setState(state);
  setValues(name, values);
}

export function removeSpacesFromRhMultiplesInputs(values) {
  if (values && values.length)
    return values
      .map(value => {
        if (value.nom)
          return {
            ...value,
            salaire_brut_annuel: removeSpaces(value.salaire_brut_annuel),
            annee_entree: getFormattedDate(value.annee_entree),
            is_key: value.is_key,
          };
      })
      .filter(value => value);

  return [];
}
