import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/general/arrowBig.svg";

function ArrowBig({ height = "120px", color = "var(--gold)", className = "", onClick }) {
  return (
    <Icon
      style={{ "--color": color, "fill": "var(--color)", "cursor": onClick ? "pointer" : "auto" }}
      height={height}
      className={className + (onClick ? " clickable-icon" : "")}
      onClick={onClick}
    />
  );
}

export default ArrowBig;
