import "./CreditSimulator.css";
import React from "react";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import CreditSimulatorForm from "../../../components/forms/creditSimulatorForm/CreditSimulatorForm";

function CreditSimulator() {
  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            {
              to: "/",
              name: "Accueil",
            },
            {
              to: "/simulateur-credit",
              name: "Simulateur de crédit",
            },
          ]}
        />
        <Banner
          title='Simulateur de crédit'
          subtitle="Tableau d'amortissement personnalisé pour votre plan de financement"
        />
        <CreditSimulatorForm />
      </section>
    </>
  );
}

export default CreditSimulator;
