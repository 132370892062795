import React, { useContext } from "react";
import AppContext from "../../../../../context/AppContext";
import "./AccountTools.css";
import Card from "../../../../../components/molecules/cards/card/Card";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import Locked from "../../../../../components/atoms/icons/general/locked/Locked";
import Chart from "../../../../../components/atoms/icons/header/chart/Chart";
import Rapport from "../../../../../components/atoms/icons/header/rapport/Rapport";
import Calculator from "../../../../../components/atoms/icons/header/calculator/Calculator";
import Outils from "../../../../../components/atoms/icons/header/lightBulb/LightBulb";
import Emc from "../../../../../components/atoms/icons/header/emc/Emc";
import ComingSoon from "../../../../../components/atoms/icons/general/comingSoon/ComingSoon";

function AccountTools() {
  const { setModalContent, setModalVisible } = useContext(AppContext);

  const iconMap = {
    "Estimer": Emc,
    "Plan de financement": Rapport,
    "Simulateur de crédit": Chart,
    "Calculateur de cotisations": Calculator,
    "Outils pratiques": Outils,
  };

  const tools = [
    {
      name: "Estimer",
      subnames: ["Fonds • Titres • Murs"],
      url: "/mon-compte/mes-estimations",
      type: "accessible",
    },
    { name: "Plan de financement", url: "/plan-financement", type: "accessible" },
    { name: "Simulateur de crédit", url: "/simulateur-credit", type: "accessible" },
    { name: "Calculateur de cotisations", url: "/calculateur-cotisations", type: "accessible" },
    { name: "Outils pratiques", url: "/outils-pratiques", type: "accessible" },
    { name: "Étude d'implantation", url: "", type: "comingSoon" },
    { name: "Annonces", url: "", type: "comingSoon" },
  ];

  /*
  { name: "Outils", url: "", type: "locked" },
  */

  function showToolsInformationModal(tool) {
    const modalTitle = tool.type === "comingSoon" ? `Prochainement : ${tool.name}` : tool.name;
    const modalContent =
      tool.type === "comingSoon"
        ? "Cette fonctionnalité sera bientôt disponible."
        : "Vous devez souscrire à un abonnement pour accéder à cet outil.";

    setModalContent({
      title: modalTitle,
      content: modalContent,
    });
    setModalVisible(true);
  }

  function renderToolTitle(tool) {
    if (tool.name === "Outils pratiques") {
      return (
        <>
          Outils
          <br />
          pratiques
        </>
      );
    }
    return tool.name;
  }

  function renderIcon(tool) {
    const IconComponent = iconMap[tool.name];
    return IconComponent ? <IconComponent width='50px' /> : null;
  }

  function renderButtonText(tool) {
    return tool.type === "comingSoon" ? "Prochainement" : "Consulter";
  }

  return (
    <section className='container'>
      <div className='my-tools-cards-grid'>
        {tools.map((tool, index) => (
          <div key={index} className='card-link-wrapper'>
            <a href={tool.url || "#"} className='card-link'>
              <Card className={`tool-card ${tool.type}`}>
                <div className='tool-card-info'>
                  {tool.type === "locked" && <Locked width='50px' />}
                  {tool.type === "comingSoon" && <ComingSoon width='50px' color='white' />}
                  {tool.type === "accessible" && renderIcon(tool)}
                  <h3 className={`tool-title ${tool.type === "comingSoon" ? "mt-sm" : ""}`}>{renderToolTitle(tool)}</h3>
                  {tool.subnames &&
                    tool.subnames.map((subname, idx) => (
                      <p key={idx} className='tool-subname'>
                        {subname}
                      </p>
                    ))}
                  {tool.type !== "accessible" && (
                    <FilledButton onClick={() => showToolsInformationModal(tool)}>
                      {renderButtonText(tool)}
                    </FilledButton>
                  )}
                </div>
              </Card>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}

export default AccountTools;
