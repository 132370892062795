import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/general/bracket.svg";

function Bracket({ width = "20px", rotation = "0", color = "var(--white)", className = "", onClick }) {
  return (
    <Icon
      style={{ "--color": color, "stroke": "var(--color)", "transform": "rotate(" + rotation + ")" }}
      width={width}
      className={className + (onClick ? " clickable-icon" : "")}
      onClick={onClick}
    />
  );
}

export default Bracket;
