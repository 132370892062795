import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/general/usine.svg";

function Usine({ width = "20px", color = "var(--gold)", className }) {
  return (
    <Icon
      style={{
        "--color": color,
      }}
      fill='var(--color)'
      width={width}
      height={width}
      className={className}
    />
  );
}

export default Usine;
