import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchUserDataApi } from "../api/AccountApi";
import AppContext from "./AppContext";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [accesses, setAccesses] = useState("not fetched");

  const { createNotification } = useContext(AppContext);

  useEffect(() => {
    fetchUserData();
  }, []);

  async function fetchUserData() {
    try {
      const uuid = getUuid();

      if (!uuid) {
        setUserData(null);
        setAccesses([]);
        return;
      }
      const res = (await fetchUserDataApi(uuid)).data;

      if (typeof res?.role_accesses?.length) setAccesses(res.role_accesses);

      delete res.uuid;
      delete res.accesses;

      setUserData(res);
      return res;
    } catch (error) {
      createNotification(
        <>Erreur lors de la récupération des informations utilisateur</>,
        "var(--red)",
        "var(--dark-blue)",
      );
      setUserData(null);
    }
  }

  function canAccess(access, accesses) {
    if (accesses === "not fetched") return -1;
    return accesses.includes(access) || accesses.includes("unrestricted");
  }

  function refreshUserData() {
    fetchUserData();
  }

  function setUuid(value) {
    if (value) {
      localStorage.setItem("uuid", value);
    } else localStorage.removeItem("uuid");
    fetchUserData();
  }

  function getUuid() {
    return localStorage.getItem("uuid");
  }

  return (
    <AuthContext.Provider value={{ setUuid, getUuid, userData, canAccess, accesses, refreshUserData }}>
      {children}
    </AuthContext.Provider>
  );
}
