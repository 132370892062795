import React, { useContext, useEffect, useRef, useState } from "react";
import "./RegisterConfirmModal.css";
import { loginUserApi, refreshAccountCodeApi, validateAccountApi } from "../../../../../api/AccountApi";
import AppContext from "../../../../../context/AppContext";
import { getObfuscedEmail } from "../../../../../utils/Utils";
import CodeInput from "../../../../molecules/formComponents/codeInput/CodeInput";

function RegisterConfirmModal({ email, password, navigate, setUuid, getUuid }) {
  const inputsRefs = useRef(new Array(6).fill(null));
  const [inputsValues, setInputsValues] = useState(["", "", "", "", "", ""]);
  const [errorInfos, setErrorInfos] = useState({
    current_attempt: 0,
    error_code: "",
    max_attempts: 3,
  });
  const { createNotification, setModalVisible, setModalContent } = useContext(AppContext);

  useEffect(() => {
    inputsRefs.current[0]?.focus();
  }, []);

  useEffect(() => {
    submitForm();
  }, [inputsValues]);

  async function submitForm() {
    const code = inputsValues.join("");
    if (code.length !== 6) return;
    try {
      await validateAccountApi(localStorage.getItem("temporary_uuid"), code);
      setModalVisible(false);
      setModalContent({ title: "", content: <></> });
      localStorage.removeItem("temporary_uuid");
      createNotification(<>Votre compte a été activé avec succès</>);
      loginAfterRegister();
    } catch (error) {
      setErrorInfos(error?.response.data.infos);
    }
  }

  function getErrorMessage(errorCode) {
    switch (errorCode) {
      case "01":
      case "04":
        return "Code expiré. Veuillez en demander un nouveau.";
      case "02":
        return "Trop de tentatives. Veuillez demander un nouveau code.";
      case "03":
        return "Code saisi invalide.";
      case "05":
        return "Votre compte est déjà validé. Veuillez vous connecter";
      default:
        return "";
    }
  }

  async function loginAfterRegister() {
    try {
      const res = (await loginUserApi({ email, password })).data;
      setUuid(res.uuid);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de votre connexion. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
    navigate("/mon-compte/mes-estimations");
  }

  return (
    <>
      <p className='centered'>
        Veuillez saisir le code à 6 chiffres que nous avons envoyé à l'adresse suivante : {getObfuscedEmail(email)}
      </p>
      {errorInfos.error_code && <p className='confirm-inscription-error'>{getErrorMessage(errorInfos.error_code)}</p>}
      <form onSubmit={submitForm}>
        <CodeInput
          inputsValues={inputsValues}
          setInputsValues={setInputsValues}
          sendCodeFunction={async () => refreshAccountCodeApi(getUuid())}
          errorInfos={errorInfos}
        />
      </form>
    </>
  );
}

export default RegisterConfirmModal;
