import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/general/like.svg";

function Like({
  width = "20px",
  height = "20px",
  rotation = "0",
  color = "var(--white)",
  fill = false,
  className = "",
  onClick,
}) {
  return (
    <Icon
      style={{
        "--color": color,
        "stroke": "var(--color)",
        "transform": "rotate(" + rotation + ")",
        "fill": fill ? "var(--color)" : "none",
      }}
      width={width}
      height={height}
      className={className + (onClick ? " clickable-icon" : "")}
      onClick={onClick}
    />
  );
}

export default Like;
