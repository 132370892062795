import "./Card.css";
import React from "react";

function Card({ bgColor, padding, children, className = "", onClick }) {
  return (
    <section
      className={"card " + className}
      style={{ "--background-color": bgColor, "--padding": padding }}
      onClick={onClick}>
      {children}
    </section>
  );
}

export default Card;
