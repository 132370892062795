import { useWindowSize } from "@uidotdev/usehooks";
import "./Thumbnail.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Bracket from "../../atoms/icons/general/bracket/Bracket";
import { set } from "react-hook-form";

function Thumbnail({
  picture,
  tag,
  title,
  link,
  linkText = "Lire l'article",
  tagColor = "var(--light-grey)",
  bgColor = "var(--beige)",
  className = "",
  margin = "30px",
  alt,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const windowSize = useWindowSize();

  return (
    <Link
      to={link}
      draggable={false}
      className={"thumbnail-container " + className}
      style={{ "--tag-color": tagColor, "--background-color": bgColor, "--margin": margin }}>
      <div className='thumbnail-img-container'>
        {picture && (
          <>
            <img
              draggable={false}
              onError={({ currentTarget }) => {
                setIsLoading(false);
                currentTarget.onerror = null;
                currentTarget.src = `${process.env.REACT_APP_API_URL}/assets/Misc/no_image.svg`;
                currentTarget.alt = "Image non disponible";
              }}
              alt={alt}
              src={picture}
              onLoad={() => setIsLoading(false)}
            />
            {isLoading && (
              <img
                draggable={false}
                alt={"Image en chargement"}
                src={`${process.env.REACT_APP_API_URL}/assets/Misc/no_image.svg`}
              />
            )}
          </>
        )}
      </div>
      {tag && <div className='thumbnail-tag'>{tag}</div>}
      {windowSize.width > 768 ? (
        <>
          <div className='thumbnail-title outfit-semibold'>{title}</div>
          <p className='thumbnail-link'>
            <span>{linkText && linkText.split(" ").slice(0, -1).join(" ")} </span>
            <span className='unbreakable'>
              {linkText && linkText.split(" ").pop()}
              <Bracket color='var(--dark-blue)' rotation='90deg' width='12px' />
            </span>
          </p>
        </>
      ) : (
        <p className='thumbnail-title outfit-semibold'>
          <span>{title && title.split(" ").slice(0, -1).join(" ")} </span>
          <span className='unbreakable'>
            {title && title.split(" ").pop()}
            <Bracket color='var(--dark-blue)' rotation='90deg' width='12px' />
          </span>
        </p>
      )}
    </Link>
  );
}

export default Thumbnail;
