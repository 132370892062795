import { useForm } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import Input from "../../../molecules/formComponents/input/Input";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import { emailValidation, phoneValidation } from "../../../../utils/formValidation/FormValidation";
import { useAuth } from "../../../../context/AuthContext";
import { fetchUserDropdownApi, updateUserDataApi } from "../../../../api/AccountApi";
import AppContext from "../../../../context/AppContext";
import Select from "../../../molecules/formComponents/select/Select";

function ProfileForm() {
  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();
  const inputForm = { register, setValue, unregister };
  const { userData, getUuid, refreshUserData } = useAuth();
  const [userTypes, setUserTypes] = useState([]);
  const { createNotification } = useContext(AppContext);

  useEffect(() => {
    fetchUserTypes();
  }, []);

  useEffect(() => {
    if (!userData) return;

    for (const [key, value] of Object.entries(userData)) setValue(key, value);
    const userTypeId = userTypes.find(userType => userType.description === userData.type)?.id;

    setValue("user_type_id", userTypeId ? userTypeId : "_null");
  }, [userData, userTypes]);

  async function fetchUserTypes() {
    try {
      const res = await fetchUserDropdownApi("type");

      setUserTypes(res.data);
    } catch (error) {
      createNotification(
        "Une erreur est survenue lors de la récupération de données. Veuillez réessayer plus tard.",
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function onSubmit(values) {
    await updateUserDataApi(values, getUuid());

    refreshUserData();
    createNotification("Vos informations personnelles ont été mises à jour avec succès.");
    try {
    } catch (error) {
      createNotification(
        "Une erreur est survenue lors de la modification de votre profil. Veuillez réessayer plus tard.",
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='row-1000'>
        <Input useForm={inputForm} error={errors?.last_name} name='last_name' label='Nom' />
        <Input useForm={inputForm} error={errors?.first_name?.message} name='first_name' label='Prénom' />
      </div>
      <Input
        validation={{}}
        useForm={inputForm}
        error={errors?.company_name?.message}
        name='company_name'
        label='Raison sociale'
      />
      <Select
        useForm={inputForm}
        error={errors?.user_type_id?.message}
        name='user_type_id'
        label='Je suis'
        children={userTypes.map(type => (
          <option key={type.id} value={type.id}>
            {type.description}
          </option>
        ))}
      />
      <Input useForm={inputForm} error={errors?.street?.message} name='street' label='Adresse de facturation' />
      <div className='row-1000'>
        <Input useForm={inputForm} error={errors?.zip_code?.message} name='zip_code' label='Code postal' />
        <Input useForm={inputForm} error={errors?.city?.message} name='city' label='Ville' />
      </div>
      <div className='row-1000'>
        <Input
          useForm={inputForm}
          error={errors?.phone?.message}
          name='phone'
          validation={phoneValidation}
          label='Téléphone'
        />
        <Input
          useForm={inputForm}
          error={errors?.email?.message}
          name='email'
          disabled
          validation={emailValidation}
          label='Adresse mail'
        />
      </div>
      <FilledButton padding='10px 40px' className='mr-sm ml-auto' type='submit'>
        Valider
      </FilledButton>
    </form>
  );
}

export default ProfileForm;
