import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import TextArea from "../../molecules/formComponents/textarea/Textarea";
import { addAdvice } from "../../../api/NewsApi";
import "./NewsAvis.css";

const NewsAvis = ({ newsId, onSubmit, onClose }) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const submitForm = async data => {
    setSuccessMessage("");
    setErrorMessage("");

    try {
      await addAdvice(newsId, data.avis);
      setSuccessMessage("Votre avis a été soumis avec succès !");
      reset();
      if (onSubmit) onSubmit();
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'avis:", error);
      setErrorMessage("Une erreur est survenue lors de la soumission de votre avis. Veuillez réessayer.");
    }
  };

  return (
    <div className='nouveautes-avis-container'>
      <form onSubmit={handleSubmit(submitForm)} className='nouveautes-avis-form'>
        {successMessage && <div className='success-message'>{successMessage}</div>}
        {errorMessage && <div className='error-message'>{errorMessage}</div>}

        <TextArea
          name='avis'
          label='Votre avis'
          register={register}
          error={errors.avis?.message}
          placeholder='Votre suggestion...'
          validation={{ required: true }}
        />
        {errors.avis && <span className='error-message'>{errors.avis.message}</span>}
        <div className='modal-buttons'>
          <FilledButton type='submit' padding='10px 25px'>
            Soumettre
          </FilledButton>
          <FilledButton
            color='var(--white)'
            bgColor='var(--gold)'
            hoverColor='var(--gold)'
            className='close-button'
            onClick={onClose}>
            Fermer
          </FilledButton>
        </div>
      </form>
    </div>
  );
};

export default NewsAvis;
