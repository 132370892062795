import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import AppContext from "../../../../../context/AppContext";
import Input from "../../../../molecules/formComponents/input/Input";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import { createArticleApi } from "../../../../../api/ArticlesEditorApi";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";

function CreateArticleModal({ selectArticle, setArticles }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);

  async function formSubmit(values) {
    setModalVisible(false);
    try {
      const res = (await createArticleApi(values.url.replaceAll("/", ""))).data;

      setArticles(articles => [...articles, { id: res.article_id, article_url: res.url }]);
      selectArticle(res.url);

      createNotification(<>L'article a été créé avec succès</>);
    } catch (error) {
      let message = <>Une erreur est survenue lors de la création de l'article</>;

      if (error.response.data.infos.error_code == "01")
        message = <>L'URL "{values.url}" est déjà utilisée. Veuillez en créer une nouvelle.</>;

      createNotification(message, "var(--red)", "var(--dark-blue)");
    }
  }

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <p className='mb-md'>
        Pour créer un article, vous devez d'abord renseigner son URL. Vous ne pourrez pas la modifier plus tard (sauf
        administrateur).
      </p>
      <Input
        name='url'
        tip='Au format suivant (sans /) : url-de-l-article'
        label='URL'
        useForm={{ register, setValue }}
        error={errors?.url?.message}
      />
      <div className='modal-buttons-row mt-lg'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible()}>
          Annuler
        </UnfilledButton>
        <FilledButton type='submit' padding='10px 25px'>
          Créer l'article
        </FilledButton>
      </div>
    </form>
  );
}

export default CreateArticleModal;
