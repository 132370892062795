import { useForm } from "react-hook-form";
import Input from "../../components/molecules/formComponents/input/Input";
import "./Test.css";
import React, { useEffect, useState } from "react";
import CaseInput from "../../components/molecules/formComponents/caseInput/CaseInput";
import Checkbox from "../../components/molecules/formComponents/checkbox/Checkbox";
import NumberInput from "../../components/molecules/formComponents/numberInput/NumberInput";
import DoubleInput from "../../components/molecules/formComponents/doubleInput/DoubleInput";
import FileInput from "../../components/molecules/formComponents/fileInput/FileInput";
import Radio from "../../components/molecules/formComponents/radio/Radio";
import Select from "../../components/molecules/formComponents/select/Select";
import Textarea from "../../components/molecules/formComponents/textarea/Textarea";
import Toggle from "../../components/molecules/formComponents/toggle/Toggle";
import { Link } from "react-router-dom";
import FilledButton from "../../components/molecules/buttons/filledButton/FilledButton";
import Info from "../../components/atoms/icons/general/info/Info";
import { defaultValidation, numberValidation } from "../../utils/formValidation/FormValidation";

function Test() {
  return <section className='page-container container'></section>;
}

export default Test;
